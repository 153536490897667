import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { OrderItem, OrderStatus } from 'src/@generated/schemas';

import { Chip } from '@mui/material';
import CustomPagination from '../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';

export const OrderFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="status" />
    <NumberInput source="price" />
  </Filter>
);

export const getTotalPrice = (orderItems: OrderItem[]) => {
  let totalPrice = 0;
  orderItems?.forEach((orderItem) => {
    totalPrice +=
      orderItem.amount *
      (orderItem.sale
        ? orderItem.price * (orderItem.sale / 100)
        : orderItem.price);

    orderItem.orderItemExtras?.forEach((orderItemExtra) => {
      totalPrice +=
        orderItemExtra.amount *
        (orderItem.sale
          ? orderItemExtra.price * (orderItem.sale / 100)
          : orderItemExtra.price);
    });
  });

  return totalPrice;
};

export const OrderList = (props: any) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<OrderFilter />}
      filter={{
        status: {
          not: {
            equals: OrderStatus.Canceled,
          },
        },
      }}
      sort={{ field: 'createdAt', order: 'desc' }}
      pagination={<CustomPagination />}>
      <Datagrid rowClick="edit">
        <FunctionField
          source="createdAt"
          render={(record) =>
            dayjs(record.createdAt).format('DD.MM. YYYY HH:mm')
          }
        />
        <FunctionField
          source="status"
          render={(record) => <Chip label={record.status} />}
        />
        <TextField source="orderDescription" />
        <FunctionField
          source="price"
          render={(record) => `${getTotalPrice(record.orderItems)},-`}
        />
        <ReferenceField source="userId" reference="User">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const Order: ResourceView = {
  resource: 'Order',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment OrderFragment on Order {
          id
          createdAt
          orderDescription
          sale
          status
          user {
            email
          }
          orderIncrement
          orderItems {
            id
            amount
            price
            createdAt
            description
            sale
            product {
              id
              name
              price
              autoIncrementAllow
              autoOpenExtras
              extras {
                id
                name
                price
                orderIndex
              }
            }
            extra {
              id
              name
              price
              orderIndex
            }
            orderItemExtras {
              id
              amount
              price
              createdAt
              extra {
                id
                name
                price
                orderIndex
              }
              sale
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment OrderFragment on Order {
          id
          createdAt
          orderDescription
          sale
          status
          user {
            email
          }
          orderIncrement
          orderItems {
            id
            amount
            price
            createdAt
            description
            sale
            product {
              id
              name
              price
              autoIncrementAllow
              autoOpenExtras
              extras {
                id
                name
                price
                orderIndex
              }
            }
            extra {
              id
              name
              price
              orderIndex
            }
            orderItemExtras {
              id
              amount
              price
              createdAt
              extra {
                id
                name
                price
                orderIndex
              }
              sale
            }
          }
        }
      `,
    },
  },
};
