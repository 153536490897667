import { AppBar } from 'react-admin';
import LocaleSwitcher from './LocaleSwitcher';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  logo: {
    flex: 1,
    maxWidth: '100px',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <img src="/logo_header.png" alt="logo" className={classes.logo} />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <LocaleSwitcher />
    </AppBar>
  );
};

export default CustomAppBar;
