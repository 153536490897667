import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SimpleForm,
  TextField,
  usePermissions,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const redirect = () => 'Attendance';

const AttendanceForm = () => {
  const { permissions } = usePermissions();
  console.log('permissions', permissions);
  return (
    <div>
      <DateTimeInput source="startAt" />
      <DateTimeInput source="endAt" />
      <ReferenceInput reference="User" source="user_id">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      {permissions.isAdmin && <BooleanInput source="paidOff" />}
    </div>
  );
};

export const AttendanceCreate = (props: any) => {
  return (
    <Create {...props} mutationMode="optimistic">
      <SimpleForm defaultValues={{ paidOff: false }}>
        <AttendanceForm />
      </SimpleForm>
    </Create>
  );
};

export const AttendanceEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <AttendanceForm />
      </SimpleForm>
    </Edit>
  );
};

export const AttendanceFilter = (props: any) => (
  <Filter {...props}>
    <DateTimeInput source="startAt_gte" label="Start from" />
    <DateTimeInput source="endAt_lte" label="End to" />
    <ReferenceInput reference="user" source="user_id">
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <BooleanInput source="paidOff" />
  </Filter>
);

export const AttendanceList = (props: any) => {
  return (
    <List
      {...props}
      filters={<AttendanceFilter />}
      pagination={<CustomPagination />}>
      <Datagrid>
        <DateField source="startAt" showTime />
        <DateField source="endAt" showTime />
        <TextField source="user.email" />
        <BooleanField source="paidOff" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment AttendanceFragment on Attendance {
    user {
      id
      email
    }
  }
`;

export const Attendance: ResourceView = {
  resource: 'Attendance',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
