import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
} from 'react-admin';

import CustomPagination from '../CustomPagination';

// import styled from 'styled-components';

const redirect = () => '/Category';

const CategoryForm = () => {
  return (
    <div>
      <TextInput source="name" style={{ width: 250 }} />
      <br />
      <SelectInput
        source="icon"
        choices={[
          'beer',
          'bluetooth',
          'cake',
          'hot-drinks',
          'coffee',
          'coupon',
          'credit-card',
          'extra',
          'payment-method',
          'shopping-bag',
          'user',
          'cupcake',
          'waffle',
          'tea',
          'water-bottle',
        ].map((icon) => ({
          id: icon,
          name: icon,
        }))}
        style={{ width: 250 }}
      />
      <br />
      <NumberInput source="orderIndex" />
    </div>
  );
};

export const CategoryCreate = (props: any) => {
  const { identity } = useGetIdentity();
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm defaultValue={{ orderIndex: 0, user: identity?.id }}>
        <CategoryForm />
      </SimpleForm>
    </Create>
  );
};

export const CategoryEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <CategoryForm />
      </SimpleForm>
    </Edit>
  );
};

export const CategoryFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
  </Filter>
);

// const MoveButton = () => {
//   return <Row></Row>;
// };

export const CategoryList = (props: any) => {
  return (
    <List
      {...props}
      sort={{
        field: 'orderIndex',
        order: 'ASC',
      }}
      filters={<CategoryFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="orderIndex" />
        <TextField source="name" />
        <TextField source="icon" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

// const Row = styled.div``;
