import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
} from 'react-admin';

import CustomPagination from '../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const redirect = () => 'BestSeller';

const BestSellerForm = () => {
  return (
    <div>
      <ReferenceInput reference="Product" source="product_id">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </div>
  );
};

export const BestSellerCreate = (props: any) => {
  const { identity } = useGetIdentity();
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <BestSellerForm />
      </SimpleForm>
    </Create>
  );
};

export const BestSellerEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <BestSellerForm />
      </SimpleForm>
    </Edit>
  );
};

export const BestSellerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
  </Filter>
);

export const BestSellerList = (props: any) => {
  return (
    <List
      {...props}
      sort={{
        field: 'orderIndex',
        order: 'ASC',
      }}
      filters={<BestSellerFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="product.name" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};


const fragment = gql`
  fragment BestSellerFragment on BestSeller {
    product {
      id
      name
    }
  }
`;

export const BestSeller: ResourceView = {
  resource: 'BestSeller',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};

