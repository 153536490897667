export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAttendance = {
  __typename?: 'AggregateAttendance';
  _count?: Maybe<AttendanceCountAggregate>;
  _min?: Maybe<AttendanceMinAggregate>;
  _max?: Maybe<AttendanceMaxAggregate>;
};

export type AggregateBestSeller = {
  __typename?: 'AggregateBestSeller';
  _count?: Maybe<BestSellerCountAggregate>;
  _avg?: Maybe<BestSellerAvgAggregate>;
  _sum?: Maybe<BestSellerSumAggregate>;
  _min?: Maybe<BestSellerMinAggregate>;
  _max?: Maybe<BestSellerMaxAggregate>;
};

export type AggregateCashbox = {
  __typename?: 'AggregateCashbox';
  _count?: Maybe<CashboxCountAggregate>;
  _min?: Maybe<CashboxMinAggregate>;
  _max?: Maybe<CashboxMaxAggregate>;
};

export type AggregateCashboxItem = {
  __typename?: 'AggregateCashboxItem';
  _count?: Maybe<CashboxItemCountAggregate>;
  _avg?: Maybe<CashboxItemAvgAggregate>;
  _sum?: Maybe<CashboxItemSumAggregate>;
  _min?: Maybe<CashboxItemMinAggregate>;
  _max?: Maybe<CashboxItemMaxAggregate>;
};

export type AggregateCategory = {
  __typename?: 'AggregateCategory';
  _count?: Maybe<CategoryCountAggregate>;
  _avg?: Maybe<CategoryAvgAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
};

export type AggregateExtra = {
  __typename?: 'AggregateExtra';
  _count?: Maybe<ExtraCountAggregate>;
  _avg?: Maybe<ExtraAvgAggregate>;
  _sum?: Maybe<ExtraSumAggregate>;
  _min?: Maybe<ExtraMinAggregate>;
  _max?: Maybe<ExtraMaxAggregate>;
};

export type AggregateExtraInventory = {
  __typename?: 'AggregateExtraInventory';
  _count?: Maybe<ExtraInventoryCountAggregate>;
  _min?: Maybe<ExtraInventoryMinAggregate>;
  _max?: Maybe<ExtraInventoryMaxAggregate>;
};

export type AggregateIngredience = {
  __typename?: 'AggregateIngredience';
  _count?: Maybe<IngredienceCountAggregate>;
  _min?: Maybe<IngredienceMinAggregate>;
  _max?: Maybe<IngredienceMaxAggregate>;
};

export type AggregateInventory = {
  __typename?: 'AggregateInventory';
  _count?: Maybe<InventoryCountAggregate>;
  _avg?: Maybe<InventoryAvgAggregate>;
  _sum?: Maybe<InventorySumAggregate>;
  _min?: Maybe<InventoryMinAggregate>;
  _max?: Maybe<InventoryMaxAggregate>;
};

export type AggregateInventoryItem = {
  __typename?: 'AggregateInventoryItem';
  _count?: Maybe<InventoryItemCountAggregate>;
  _avg?: Maybe<InventoryItemAvgAggregate>;
  _sum?: Maybe<InventoryItemSumAggregate>;
  _min?: Maybe<InventoryItemMinAggregate>;
  _max?: Maybe<InventoryItemMaxAggregate>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  _count?: Maybe<OrderCountAggregate>;
  _avg?: Maybe<OrderAvgAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
};

export type AggregateOrderItem = {
  __typename?: 'AggregateOrderItem';
  _count?: Maybe<OrderItemCountAggregate>;
  _avg?: Maybe<OrderItemAvgAggregate>;
  _sum?: Maybe<OrderItemSumAggregate>;
  _min?: Maybe<OrderItemMinAggregate>;
  _max?: Maybe<OrderItemMaxAggregate>;
};

export type AggregateOrderItemExtra = {
  __typename?: 'AggregateOrderItemExtra';
  _count?: Maybe<OrderItemExtraCountAggregate>;
  _avg?: Maybe<OrderItemExtraAvgAggregate>;
  _sum?: Maybe<OrderItemExtraSumAggregate>;
  _min?: Maybe<OrderItemExtraMinAggregate>;
  _max?: Maybe<OrderItemExtraMaxAggregate>;
};

export type AggregatePriceHistoryIngredience = {
  __typename?: 'AggregatePriceHistoryIngredience';
  _count?: Maybe<PriceHistoryIngredienceCountAggregate>;
  _avg?: Maybe<PriceHistoryIngredienceAvgAggregate>;
  _sum?: Maybe<PriceHistoryIngredienceSumAggregate>;
  _min?: Maybe<PriceHistoryIngredienceMinAggregate>;
  _max?: Maybe<PriceHistoryIngredienceMaxAggregate>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _count?: Maybe<ProductCountAggregate>;
  _avg?: Maybe<ProductAvgAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
};

export type AggregateProductIngredience = {
  __typename?: 'AggregateProductIngredience';
  _count?: Maybe<ProductIngredienceCountAggregate>;
  _avg?: Maybe<ProductIngredienceAvgAggregate>;
  _sum?: Maybe<ProductIngredienceSumAggregate>;
  _min?: Maybe<ProductIngredienceMinAggregate>;
  _max?: Maybe<ProductIngredienceMaxAggregate>;
};

export type AggregateProductInventory = {
  __typename?: 'AggregateProductInventory';
  _count?: Maybe<ProductInventoryCountAggregate>;
  _min?: Maybe<ProductInventoryMinAggregate>;
  _max?: Maybe<ProductInventoryMaxAggregate>;
};

export type AggregateProductStock = {
  __typename?: 'AggregateProductStock';
  _count?: Maybe<ProductStockCountAggregate>;
  _avg?: Maybe<ProductStockAvgAggregate>;
  _sum?: Maybe<ProductStockSumAggregate>;
  _min?: Maybe<ProductStockMinAggregate>;
  _max?: Maybe<ProductStockMaxAggregate>;
};

export type AggregateSingleExpense = {
  __typename?: 'AggregateSingleExpense';
  _count?: Maybe<SingleExpenseCountAggregate>;
  _avg?: Maybe<SingleExpenseAvgAggregate>;
  _sum?: Maybe<SingleExpenseSumAggregate>;
  _min?: Maybe<SingleExpenseMinAggregate>;
  _max?: Maybe<SingleExpenseMaxAggregate>;
};

export type AggregateTaxe = {
  __typename?: 'AggregateTaxe';
  _count?: Maybe<TaxeCountAggregate>;
  _avg?: Maybe<TaxeAvgAggregate>;
  _sum?: Maybe<TaxeSumAggregate>;
  _min?: Maybe<TaxeMinAggregate>;
  _max?: Maybe<TaxeMaxAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type Attendance = {
  __typename?: 'Attendance';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userId: Scalars['String'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
  user: User;
};

export type AttendanceCountAggregate = {
  __typename?: 'AttendanceCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  userId: Scalars['Int'];
  startAt: Scalars['Int'];
  endAt: Scalars['Int'];
  paidOff: Scalars['Int'];
  _all: Scalars['Int'];
};

export type AttendanceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
  paidOff?: Maybe<SortOrder>;
};

export type AttendanceCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAttendanceInput;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
};

export type AttendanceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
};

export type AttendanceCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
};

export type AttendanceCreateManyUserInputEnvelope = {
  data: Array<AttendanceCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AttendanceCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<AttendanceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AttendanceCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<AttendanceCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<AttendanceWhereUniqueInput>>;
};

export type AttendanceCreateOrConnectWithoutUserInput = {
  where: AttendanceWhereUniqueInput;
  create: AttendanceCreateWithoutUserInput;
};

export type AttendanceCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
};

export type AttendanceGroupBy = {
  __typename?: 'AttendanceGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  userId: Scalars['String'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  paidOff: Scalars['Boolean'];
  _count?: Maybe<AttendanceCountAggregate>;
  _min?: Maybe<AttendanceMinAggregate>;
  _max?: Maybe<AttendanceMaxAggregate>;
};

export type AttendanceListRelationFilter = {
  every?: Maybe<AttendanceWhereInput>;
  some?: Maybe<AttendanceWhereInput>;
  none?: Maybe<AttendanceWhereInput>;
};

export type AttendanceMaxAggregate = {
  __typename?: 'AttendanceMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  paidOff?: Maybe<Scalars['Boolean']>;
};

export type AttendanceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
  paidOff?: Maybe<SortOrder>;
};

export type AttendanceMinAggregate = {
  __typename?: 'AttendanceMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  paidOff?: Maybe<Scalars['Boolean']>;
};

export type AttendanceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
  paidOff?: Maybe<SortOrder>;
};

export type AttendanceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AttendanceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
  paidOff?: Maybe<SortOrder>;
  _count?: Maybe<AttendanceCountOrderByAggregateInput>;
  _max?: Maybe<AttendanceMaxOrderByAggregateInput>;
  _min?: Maybe<AttendanceMinOrderByAggregateInput>;
};

export type AttendanceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
  paidOff?: Maybe<SortOrder>;
};

export enum AttendanceScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  UserId = 'userId',
  StartAt = 'startAt',
  EndAt = 'endAt',
  PaidOff = 'paidOff',
}

export type AttendanceScalarWhereInput = {
  AND?: Maybe<Array<AttendanceScalarWhereInput>>;
  OR?: Maybe<Array<AttendanceScalarWhereInput>>;
  NOT?: Maybe<Array<AttendanceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
  startAt?: Maybe<DateTimeFilter>;
  endAt?: Maybe<DateTimeFilter>;
  paidOff?: Maybe<BoolFilter>;
};

export type AttendanceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AttendanceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AttendanceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AttendanceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  startAt?: Maybe<DateTimeWithAggregatesFilter>;
  endAt?: Maybe<DateTimeWithAggregatesFilter>;
  paidOff?: Maybe<BoolWithAggregatesFilter>;
};

export type AttendanceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutAttendanceNestedInput>;
  startAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  paidOff?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type AttendanceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  paidOff?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type AttendanceUpdateManyWithWhereWithoutUserInput = {
  where: AttendanceScalarWhereInput;
  data: AttendanceUpdateManyMutationInput;
};

export type AttendanceUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<AttendanceCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AttendanceCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<AttendanceUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<AttendanceCreateManyUserInputEnvelope>;
  set?: Maybe<Array<AttendanceWhereUniqueInput>>;
  disconnect?: Maybe<Array<AttendanceWhereUniqueInput>>;
  delete?: Maybe<Array<AttendanceWhereUniqueInput>>;
  connect?: Maybe<Array<AttendanceWhereUniqueInput>>;
  update?: Maybe<Array<AttendanceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<AttendanceUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<AttendanceScalarWhereInput>>;
};

export type AttendanceUpdateWithWhereUniqueWithoutUserInput = {
  where: AttendanceWhereUniqueInput;
  data: AttendanceUpdateWithoutUserInput;
};

export type AttendanceUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  paidOff?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type AttendanceUpsertWithWhereUniqueWithoutUserInput = {
  where: AttendanceWhereUniqueInput;
  update: AttendanceUpdateWithoutUserInput;
  create: AttendanceCreateWithoutUserInput;
};

export type AttendanceWhereInput = {
  AND?: Maybe<Array<AttendanceWhereInput>>;
  OR?: Maybe<Array<AttendanceWhereInput>>;
  NOT?: Maybe<Array<AttendanceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  startAt?: Maybe<DateTimeFilter>;
  endAt?: Maybe<DateTimeFilter>;
  paidOff?: Maybe<BoolFilter>;
};

export type AttendanceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BestSeller = {
  __typename?: 'BestSeller';
  id: Scalars['String'];
  orderIndex: Scalars['Float'];
  extrasId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  extras?: Maybe<Extra>;
  product?: Maybe<Product>;
};

export type BestSellerAvgAggregate = {
  __typename?: 'BestSellerAvgAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type BestSellerAvgOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type BestSellerCountAggregate = {
  __typename?: 'BestSellerCountAggregate';
  id: Scalars['Int'];
  orderIndex: Scalars['Int'];
  extrasId: Scalars['Int'];
  productId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BestSellerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  extrasId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type BestSellerCreateInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  extras?: Maybe<ExtraCreateNestedOneWithoutBestSellersInput>;
  product?: Maybe<ProductCreateNestedOneWithoutBestSellersInput>;
};

export type BestSellerCreateManyExtrasInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  productId?: Maybe<Scalars['String']>;
};

export type BestSellerCreateManyExtrasInputEnvelope = {
  data: Array<BestSellerCreateManyExtrasInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BestSellerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  extrasId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type BestSellerCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  extrasId?: Maybe<Scalars['String']>;
};

export type BestSellerCreateManyProductInputEnvelope = {
  data: Array<BestSellerCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BestSellerCreateNestedManyWithoutExtrasInput = {
  create?: Maybe<Array<BestSellerCreateWithoutExtrasInput>>;
  connectOrCreate?: Maybe<Array<BestSellerCreateOrConnectWithoutExtrasInput>>;
  createMany?: Maybe<BestSellerCreateManyExtrasInputEnvelope>;
  connect?: Maybe<Array<BestSellerWhereUniqueInput>>;
};

export type BestSellerCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<BestSellerCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<BestSellerCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<BestSellerCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<BestSellerWhereUniqueInput>>;
};

export type BestSellerCreateOrConnectWithoutExtrasInput = {
  where: BestSellerWhereUniqueInput;
  create: BestSellerCreateWithoutExtrasInput;
};

export type BestSellerCreateOrConnectWithoutProductInput = {
  where: BestSellerWhereUniqueInput;
  create: BestSellerCreateWithoutProductInput;
};

export type BestSellerCreateWithoutExtrasInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  product?: Maybe<ProductCreateNestedOneWithoutBestSellersInput>;
};

export type BestSellerCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  orderIndex: Scalars['Float'];
  extras?: Maybe<ExtraCreateNestedOneWithoutBestSellersInput>;
};

export type BestSellerGroupBy = {
  __typename?: 'BestSellerGroupBy';
  id: Scalars['String'];
  orderIndex: Scalars['Float'];
  extrasId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  _count?: Maybe<BestSellerCountAggregate>;
  _avg?: Maybe<BestSellerAvgAggregate>;
  _sum?: Maybe<BestSellerSumAggregate>;
  _min?: Maybe<BestSellerMinAggregate>;
  _max?: Maybe<BestSellerMaxAggregate>;
};

export type BestSellerListRelationFilter = {
  every?: Maybe<BestSellerWhereInput>;
  some?: Maybe<BestSellerWhereInput>;
  none?: Maybe<BestSellerWhereInput>;
};

export type BestSellerMaxAggregate = {
  __typename?: 'BestSellerMaxAggregate';
  id?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Float']>;
  extrasId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type BestSellerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  extrasId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type BestSellerMinAggregate = {
  __typename?: 'BestSellerMinAggregate';
  id?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Float']>;
  extrasId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type BestSellerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  extrasId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type BestSellerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BestSellerOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  extrasId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  _count?: Maybe<BestSellerCountOrderByAggregateInput>;
  _avg?: Maybe<BestSellerAvgOrderByAggregateInput>;
  _max?: Maybe<BestSellerMaxOrderByAggregateInput>;
  _min?: Maybe<BestSellerMinOrderByAggregateInput>;
  _sum?: Maybe<BestSellerSumOrderByAggregateInput>;
};

export type BestSellerOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  extras?: Maybe<ExtraOrderByWithRelationInput>;
  extrasId?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
};

export enum BestSellerScalarFieldEnum {
  Id = 'id',
  OrderIndex = 'orderIndex',
  ExtrasId = 'extrasId',
  ProductId = 'productId',
}

export type BestSellerScalarWhereInput = {
  AND?: Maybe<Array<BestSellerScalarWhereInput>>;
  OR?: Maybe<Array<BestSellerScalarWhereInput>>;
  NOT?: Maybe<Array<BestSellerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  orderIndex?: Maybe<FloatFilter>;
  extrasId?: Maybe<StringNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type BestSellerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BestSellerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BestSellerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BestSellerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  orderIndex?: Maybe<FloatWithAggregatesFilter>;
  extrasId?: Maybe<StringNullableWithAggregatesFilter>;
  productId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BestSellerSumAggregate = {
  __typename?: 'BestSellerSumAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type BestSellerSumOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type BestSellerUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateOneWithoutBestSellersNestedInput>;
  product?: Maybe<ProductUpdateOneWithoutBestSellersNestedInput>;
};

export type BestSellerUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type BestSellerUpdateManyWithWhereWithoutExtrasInput = {
  where: BestSellerScalarWhereInput;
  data: BestSellerUpdateManyMutationInput;
};

export type BestSellerUpdateManyWithWhereWithoutProductInput = {
  where: BestSellerScalarWhereInput;
  data: BestSellerUpdateManyMutationInput;
};

export type BestSellerUpdateManyWithoutExtrasNestedInput = {
  create?: Maybe<Array<BestSellerCreateWithoutExtrasInput>>;
  connectOrCreate?: Maybe<Array<BestSellerCreateOrConnectWithoutExtrasInput>>;
  upsert?: Maybe<Array<BestSellerUpsertWithWhereUniqueWithoutExtrasInput>>;
  createMany?: Maybe<BestSellerCreateManyExtrasInputEnvelope>;
  set?: Maybe<Array<BestSellerWhereUniqueInput>>;
  disconnect?: Maybe<Array<BestSellerWhereUniqueInput>>;
  delete?: Maybe<Array<BestSellerWhereUniqueInput>>;
  connect?: Maybe<Array<BestSellerWhereUniqueInput>>;
  update?: Maybe<Array<BestSellerUpdateWithWhereUniqueWithoutExtrasInput>>;
  updateMany?: Maybe<Array<BestSellerUpdateManyWithWhereWithoutExtrasInput>>;
  deleteMany?: Maybe<Array<BestSellerScalarWhereInput>>;
};

export type BestSellerUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<BestSellerCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<BestSellerCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<BestSellerUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<BestSellerCreateManyProductInputEnvelope>;
  set?: Maybe<Array<BestSellerWhereUniqueInput>>;
  disconnect?: Maybe<Array<BestSellerWhereUniqueInput>>;
  delete?: Maybe<Array<BestSellerWhereUniqueInput>>;
  connect?: Maybe<Array<BestSellerWhereUniqueInput>>;
  update?: Maybe<Array<BestSellerUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<BestSellerUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<BestSellerScalarWhereInput>>;
};

export type BestSellerUpdateWithWhereUniqueWithoutExtrasInput = {
  where: BestSellerWhereUniqueInput;
  data: BestSellerUpdateWithoutExtrasInput;
};

export type BestSellerUpdateWithWhereUniqueWithoutProductInput = {
  where: BestSellerWhereUniqueInput;
  data: BestSellerUpdateWithoutProductInput;
};

export type BestSellerUpdateWithoutExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutBestSellersNestedInput>;
};

export type BestSellerUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateOneWithoutBestSellersNestedInput>;
};

export type BestSellerUpsertWithWhereUniqueWithoutExtrasInput = {
  where: BestSellerWhereUniqueInput;
  update: BestSellerUpdateWithoutExtrasInput;
  create: BestSellerCreateWithoutExtrasInput;
};

export type BestSellerUpsertWithWhereUniqueWithoutProductInput = {
  where: BestSellerWhereUniqueInput;
  update: BestSellerUpdateWithoutProductInput;
  create: BestSellerCreateWithoutProductInput;
};

export type BestSellerWhereInput = {
  AND?: Maybe<Array<BestSellerWhereInput>>;
  OR?: Maybe<Array<BestSellerWhereInput>>;
  NOT?: Maybe<Array<BestSellerWhereInput>>;
  id?: Maybe<StringFilter>;
  orderIndex?: Maybe<FloatFilter>;
  extras?: Maybe<ExtraRelationFilter>;
  extrasId?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type BestSellerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type Cashbox = {
  __typename?: 'Cashbox';
  id: Scalars['String'];
  dateAt: Scalars['DateTime'];
  _count?: Maybe<CashboxCount>;
  cashboxItems: Array<CashboxItem>;
};

export type CashboxCashboxItemsArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxItemScalarFieldEnum>>;
};

export type CashboxCount = {
  __typename?: 'CashboxCount';
  cashboxItems: Scalars['Int'];
};

export type CashboxCountAggregate = {
  __typename?: 'CashboxCountAggregate';
  id: Scalars['Int'];
  dateAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CashboxCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
};

export type CashboxCreateInput = {
  id?: Maybe<Scalars['String']>;
  dateAt: Scalars['DateTime'];
  cashboxItems?: Maybe<CashboxItemCreateNestedManyWithoutCashboxInput>;
};

export type CashboxCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  dateAt: Scalars['DateTime'];
};

export type CashboxCreateNestedOneWithoutCashboxItemsInput = {
  create?: Maybe<CashboxCreateWithoutCashboxItemsInput>;
  connectOrCreate?: Maybe<CashboxCreateOrConnectWithoutCashboxItemsInput>;
  connect?: Maybe<CashboxWhereUniqueInput>;
};

export type CashboxCreateOrConnectWithoutCashboxItemsInput = {
  where: CashboxWhereUniqueInput;
  create: CashboxCreateWithoutCashboxItemsInput;
};

export type CashboxCreateWithoutCashboxItemsInput = {
  id?: Maybe<Scalars['String']>;
  dateAt: Scalars['DateTime'];
};

export type CashboxGroupBy = {
  __typename?: 'CashboxGroupBy';
  id: Scalars['String'];
  dateAt: Scalars['DateTime'];
  _count?: Maybe<CashboxCountAggregate>;
  _min?: Maybe<CashboxMinAggregate>;
  _max?: Maybe<CashboxMaxAggregate>;
};

export type CashboxItem = {
  __typename?: 'CashboxItem';
  id: Scalars['String'];
  kind: Scalars['Float'];
  amount: Scalars['Float'];
  cashboxId: Scalars['String'];
  cashbox: Cashbox;
};

export type CashboxItemAvgAggregate = {
  __typename?: 'CashboxItemAvgAggregate';
  kind?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CashboxItemAvgOrderByAggregateInput = {
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type CashboxItemCountAggregate = {
  __typename?: 'CashboxItemCountAggregate';
  id: Scalars['Int'];
  kind: Scalars['Int'];
  amount: Scalars['Int'];
  cashboxId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CashboxItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  cashboxId?: Maybe<SortOrder>;
};

export type CashboxItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  kind: Scalars['Float'];
  amount: Scalars['Float'];
  cashbox: CashboxCreateNestedOneWithoutCashboxItemsInput;
};

export type CashboxItemCreateManyCashboxInput = {
  id?: Maybe<Scalars['String']>;
  kind: Scalars['Float'];
  amount: Scalars['Float'];
};

export type CashboxItemCreateManyCashboxInputEnvelope = {
  data: Array<CashboxItemCreateManyCashboxInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CashboxItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  kind: Scalars['Float'];
  amount: Scalars['Float'];
  cashboxId: Scalars['String'];
};

export type CashboxItemCreateNestedManyWithoutCashboxInput = {
  create?: Maybe<Array<CashboxItemCreateWithoutCashboxInput>>;
  connectOrCreate?: Maybe<Array<CashboxItemCreateOrConnectWithoutCashboxInput>>;
  createMany?: Maybe<CashboxItemCreateManyCashboxInputEnvelope>;
  connect?: Maybe<Array<CashboxItemWhereUniqueInput>>;
};

export type CashboxItemCreateOrConnectWithoutCashboxInput = {
  where: CashboxItemWhereUniqueInput;
  create: CashboxItemCreateWithoutCashboxInput;
};

export type CashboxItemCreateWithoutCashboxInput = {
  id?: Maybe<Scalars['String']>;
  kind: Scalars['Float'];
  amount: Scalars['Float'];
};

export type CashboxItemGroupBy = {
  __typename?: 'CashboxItemGroupBy';
  id: Scalars['String'];
  kind: Scalars['Float'];
  amount: Scalars['Float'];
  cashboxId: Scalars['String'];
  _count?: Maybe<CashboxItemCountAggregate>;
  _avg?: Maybe<CashboxItemAvgAggregate>;
  _sum?: Maybe<CashboxItemSumAggregate>;
  _min?: Maybe<CashboxItemMinAggregate>;
  _max?: Maybe<CashboxItemMaxAggregate>;
};

export type CashboxItemListRelationFilter = {
  every?: Maybe<CashboxItemWhereInput>;
  some?: Maybe<CashboxItemWhereInput>;
  none?: Maybe<CashboxItemWhereInput>;
};

export type CashboxItemMaxAggregate = {
  __typename?: 'CashboxItemMaxAggregate';
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  cashboxId?: Maybe<Scalars['String']>;
};

export type CashboxItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  cashboxId?: Maybe<SortOrder>;
};

export type CashboxItemMinAggregate = {
  __typename?: 'CashboxItemMinAggregate';
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  cashboxId?: Maybe<Scalars['String']>;
};

export type CashboxItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  cashboxId?: Maybe<SortOrder>;
};

export type CashboxItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CashboxItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  cashboxId?: Maybe<SortOrder>;
  _count?: Maybe<CashboxItemCountOrderByAggregateInput>;
  _avg?: Maybe<CashboxItemAvgOrderByAggregateInput>;
  _max?: Maybe<CashboxItemMaxOrderByAggregateInput>;
  _min?: Maybe<CashboxItemMinOrderByAggregateInput>;
  _sum?: Maybe<CashboxItemSumOrderByAggregateInput>;
};

export type CashboxItemOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  cashbox?: Maybe<CashboxOrderByWithRelationInput>;
  cashboxId?: Maybe<SortOrder>;
};

export enum CashboxItemScalarFieldEnum {
  Id = 'id',
  Kind = 'kind',
  Amount = 'amount',
  CashboxId = 'cashboxId',
}

export type CashboxItemScalarWhereInput = {
  AND?: Maybe<Array<CashboxItemScalarWhereInput>>;
  OR?: Maybe<Array<CashboxItemScalarWhereInput>>;
  NOT?: Maybe<Array<CashboxItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  kind?: Maybe<FloatFilter>;
  amount?: Maybe<FloatFilter>;
  cashboxId?: Maybe<StringFilter>;
};

export type CashboxItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CashboxItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CashboxItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CashboxItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  kind?: Maybe<FloatWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  cashboxId?: Maybe<StringWithAggregatesFilter>;
};

export type CashboxItemSumAggregate = {
  __typename?: 'CashboxItemSumAggregate';
  kind?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CashboxItemSumOrderByAggregateInput = {
  kind?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type CashboxItemUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  cashbox?: Maybe<CashboxUpdateOneRequiredWithoutCashboxItemsNestedInput>;
};

export type CashboxItemUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type CashboxItemUpdateManyWithWhereWithoutCashboxInput = {
  where: CashboxItemScalarWhereInput;
  data: CashboxItemUpdateManyMutationInput;
};

export type CashboxItemUpdateManyWithoutCashboxNestedInput = {
  create?: Maybe<Array<CashboxItemCreateWithoutCashboxInput>>;
  connectOrCreate?: Maybe<Array<CashboxItemCreateOrConnectWithoutCashboxInput>>;
  upsert?: Maybe<Array<CashboxItemUpsertWithWhereUniqueWithoutCashboxInput>>;
  createMany?: Maybe<CashboxItemCreateManyCashboxInputEnvelope>;
  set?: Maybe<Array<CashboxItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<CashboxItemWhereUniqueInput>>;
  delete?: Maybe<Array<CashboxItemWhereUniqueInput>>;
  connect?: Maybe<Array<CashboxItemWhereUniqueInput>>;
  update?: Maybe<Array<CashboxItemUpdateWithWhereUniqueWithoutCashboxInput>>;
  updateMany?: Maybe<Array<CashboxItemUpdateManyWithWhereWithoutCashboxInput>>;
  deleteMany?: Maybe<Array<CashboxItemScalarWhereInput>>;
};

export type CashboxItemUpdateWithWhereUniqueWithoutCashboxInput = {
  where: CashboxItemWhereUniqueInput;
  data: CashboxItemUpdateWithoutCashboxInput;
};

export type CashboxItemUpdateWithoutCashboxInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kind?: Maybe<FloatFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type CashboxItemUpsertWithWhereUniqueWithoutCashboxInput = {
  where: CashboxItemWhereUniqueInput;
  update: CashboxItemUpdateWithoutCashboxInput;
  create: CashboxItemCreateWithoutCashboxInput;
};

export type CashboxItemWhereInput = {
  AND?: Maybe<Array<CashboxItemWhereInput>>;
  OR?: Maybe<Array<CashboxItemWhereInput>>;
  NOT?: Maybe<Array<CashboxItemWhereInput>>;
  id?: Maybe<StringFilter>;
  kind?: Maybe<FloatFilter>;
  amount?: Maybe<FloatFilter>;
  cashbox?: Maybe<CashboxRelationFilter>;
  cashboxId?: Maybe<StringFilter>;
};

export type CashboxItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CashboxMaxAggregate = {
  __typename?: 'CashboxMaxAggregate';
  id?: Maybe<Scalars['String']>;
  dateAt?: Maybe<Scalars['DateTime']>;
};

export type CashboxMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
};

export type CashboxMinAggregate = {
  __typename?: 'CashboxMinAggregate';
  id?: Maybe<Scalars['String']>;
  dateAt?: Maybe<Scalars['DateTime']>;
};

export type CashboxMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
};

export type CashboxOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  _count?: Maybe<CashboxCountOrderByAggregateInput>;
  _max?: Maybe<CashboxMaxOrderByAggregateInput>;
  _min?: Maybe<CashboxMinOrderByAggregateInput>;
};

export type CashboxOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  cashboxItems?: Maybe<CashboxItemOrderByRelationAggregateInput>;
};

export type CashboxRelationFilter = {
  is?: Maybe<CashboxWhereInput>;
  isNot?: Maybe<CashboxWhereInput>;
};

export enum CashboxScalarFieldEnum {
  Id = 'id',
  DateAt = 'dateAt',
}

export type CashboxScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CashboxScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CashboxScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CashboxScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  dateAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CashboxUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  dateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cashboxItems?: Maybe<CashboxItemUpdateManyWithoutCashboxNestedInput>;
};

export type CashboxUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  dateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CashboxUpdateOneRequiredWithoutCashboxItemsNestedInput = {
  create?: Maybe<CashboxCreateWithoutCashboxItemsInput>;
  connectOrCreate?: Maybe<CashboxCreateOrConnectWithoutCashboxItemsInput>;
  upsert?: Maybe<CashboxUpsertWithoutCashboxItemsInput>;
  connect?: Maybe<CashboxWhereUniqueInput>;
  update?: Maybe<CashboxUpdateWithoutCashboxItemsInput>;
};

export type CashboxUpdateWithoutCashboxItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  dateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CashboxUpsertWithoutCashboxItemsInput = {
  update: CashboxUpdateWithoutCashboxItemsInput;
  create: CashboxCreateWithoutCashboxItemsInput;
};

export type CashboxWhereInput = {
  AND?: Maybe<Array<CashboxWhereInput>>;
  OR?: Maybe<Array<CashboxWhereInput>>;
  NOT?: Maybe<Array<CashboxWhereInput>>;
  id?: Maybe<StringFilter>;
  dateAt?: Maybe<DateTimeFilter>;
  cashboxItems?: Maybe<CashboxItemListRelationFilter>;
};

export type CashboxWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  userId: Scalars['String'];
  _count?: Maybe<CategoryCount>;
  user: User;
  products: Array<Product>;
  extras: Array<Extra>;
};

export type CategoryProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type CategoryExtrasArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type CategoryAvgAggregate = {
  __typename?: 'CategoryAvgAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type CategoryAvgOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type CategoryCount = {
  __typename?: 'CategoryCount';
  products: Scalars['Int'];
  extras: Scalars['Int'];
};

export type CategoryCountAggregate = {
  __typename?: 'CategoryCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  orderIndex: Scalars['Int'];
  name: Scalars['Int'];
  icon: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type CategoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  icon?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CategoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  user: UserCreateNestedOneWithoutCategoriesInput;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  userId: Scalars['String'];
};

export type CategoryCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
};

export type CategoryCreateManyUserInputEnvelope = {
  data: Array<CategoryCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CategoryCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CategoryCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CategoryCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
};

export type CategoryCreateNestedOneWithoutExtrasInput = {
  create?: Maybe<CategoryCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutExtrasInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateNestedOneWithoutProductsInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
};

export type CategoryCreateOrConnectWithoutExtrasInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutExtrasInput;
};

export type CategoryCreateOrConnectWithoutProductsInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutProductsInput;
};

export type CategoryCreateOrConnectWithoutUserInput = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateWithoutUserInput;
};

export type CategoryCreateWithoutExtrasInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  user: UserCreateNestedOneWithoutCategoriesInput;
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  user: UserCreateNestedOneWithoutCategoriesInput;
  extras?: Maybe<ExtraCreateNestedManyWithoutCategoryInput>;
};

export type CategoryCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutCategoryInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutCategoryInput>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex: Scalars['Float'];
  name: Scalars['String'];
  icon: Scalars['String'];
  userId: Scalars['String'];
  _count?: Maybe<CategoryCountAggregate>;
  _avg?: Maybe<CategoryAvgAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
};

export type CategoryListRelationFilter = {
  every?: Maybe<CategoryWhereInput>;
  some?: Maybe<CategoryWhereInput>;
  none?: Maybe<CategoryWhereInput>;
};

export type CategoryMaxAggregate = {
  __typename?: 'CategoryMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CategoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  icon?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CategoryMinAggregate = {
  __typename?: 'CategoryMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderIndex?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CategoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  icon?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CategoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  icon?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<CategoryCountOrderByAggregateInput>;
  _avg?: Maybe<CategoryAvgOrderByAggregateInput>;
  _max?: Maybe<CategoryMaxOrderByAggregateInput>;
  _min?: Maybe<CategoryMinOrderByAggregateInput>;
  _sum?: Maybe<CategorySumOrderByAggregateInput>;
};

export type CategoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  icon?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  extras?: Maybe<ExtraOrderByRelationAggregateInput>;
};

export type CategoryRelationFilter = {
  is?: Maybe<CategoryWhereInput>;
  isNot?: Maybe<CategoryWhereInput>;
};

export enum CategoryScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  OrderIndex = 'orderIndex',
  Name = 'name',
  Icon = 'icon',
  UserId = 'userId',
}

export type CategoryScalarWhereInput = {
  AND?: Maybe<Array<CategoryScalarWhereInput>>;
  OR?: Maybe<Array<CategoryScalarWhereInput>>;
  NOT?: Maybe<Array<CategoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  orderIndex?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  icon?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  orderIndex?: Maybe<FloatWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  icon?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type CategorySumAggregate = {
  __typename?: 'CategorySumAggregate';
  orderIndex?: Maybe<Scalars['Float']>;
};

export type CategorySumOrderByAggregateInput = {
  orderIndex?: Maybe<SortOrder>;
};

export type CategoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  icon?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCategoriesNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  icon?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CategoryUpdateManyWithWhereWithoutUserInput = {
  where: CategoryScalarWhereInput;
  data: CategoryUpdateManyMutationInput;
};

export type CategoryUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<CategoryCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CategoryCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CategoryUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CategoryCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<CategoryWhereUniqueInput>>;
  delete?: Maybe<Array<CategoryWhereUniqueInput>>;
  connect?: Maybe<Array<CategoryWhereUniqueInput>>;
  update?: Maybe<Array<CategoryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CategoryUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CategoryScalarWhereInput>>;
};

export type CategoryUpdateOneRequiredWithoutProductsNestedInput = {
  create?: Maybe<CategoryCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<CategoryUpsertWithoutProductsInput>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  update?: Maybe<CategoryUpdateWithoutProductsInput>;
};

export type CategoryUpdateOneWithoutExtrasNestedInput = {
  create?: Maybe<CategoryCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<CategoryCreateOrConnectWithoutExtrasInput>;
  upsert?: Maybe<CategoryUpsertWithoutExtrasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CategoryWhereUniqueInput>;
  update?: Maybe<CategoryUpdateWithoutExtrasInput>;
};

export type CategoryUpdateWithWhereUniqueWithoutUserInput = {
  where: CategoryWhereUniqueInput;
  data: CategoryUpdateWithoutUserInput;
};

export type CategoryUpdateWithoutExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  icon?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCategoriesNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  icon?: Maybe<StringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCategoriesNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  icon?: Maybe<StringFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutCategoryNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutCategoryNestedInput>;
};

export type CategoryUpsertWithWhereUniqueWithoutUserInput = {
  where: CategoryWhereUniqueInput;
  update: CategoryUpdateWithoutUserInput;
  create: CategoryCreateWithoutUserInput;
};

export type CategoryUpsertWithoutExtrasInput = {
  update: CategoryUpdateWithoutExtrasInput;
  create: CategoryCreateWithoutExtrasInput;
};

export type CategoryUpsertWithoutProductsInput = {
  update: CategoryUpdateWithoutProductsInput;
  create: CategoryCreateWithoutProductsInput;
};

export type CategoryWhereInput = {
  AND?: Maybe<Array<CategoryWhereInput>>;
  OR?: Maybe<Array<CategoryWhereInput>>;
  NOT?: Maybe<Array<CategoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  orderIndex?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  icon?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  products?: Maybe<ProductListRelationFilter>;
  extras?: Maybe<ExtraListRelationFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  notIn?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
};

export type EnumOrderStatusWithAggregatesFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  notIn?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumOrderStatusFilter>;
  _max?: Maybe<NestedEnumOrderStatusFilter>;
};

export type Extra = {
  __typename?: 'Extra';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  _count?: Maybe<ExtraCount>;
  product?: Maybe<Product>;
  category?: Maybe<Category>;
  user: User;
  orderItemExtras: Array<OrderItemExtra>;
  orderItems: Array<OrderItem>;
  extraInventories: Array<ExtraInventory>;
  bestSellers: Array<BestSeller>;
};

export type ExtraOrderItemExtrasArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemExtraScalarFieldEnum>>;
};

export type ExtraOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type ExtraExtraInventoriesArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraInventoryScalarFieldEnum>>;
};

export type ExtraBestSellersArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BestSellerScalarFieldEnum>>;
};

export type ExtraAvgAggregate = {
  __typename?: 'ExtraAvgAggregate';
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
};

export type ExtraAvgOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
};

export type ExtraCount = {
  __typename?: 'ExtraCount';
  orderItemExtras: Scalars['Int'];
  orderItems: Scalars['Int'];
  extraInventories: Scalars['Int'];
  bestSellers: Scalars['Int'];
};

export type ExtraCountAggregate = {
  __typename?: 'ExtraCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
  orderIndex: Scalars['Int'];
  description: Scalars['Int'];
  autoIncrementAllow: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  productId: Scalars['Int'];
  categoryId: Scalars['Int'];
  userId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ExtraCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ExtraCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateManyCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ExtraCreateManyCategoryInputEnvelope = {
  data: Array<ExtraCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ExtraCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ExtraCreateManyProductInputEnvelope = {
  data: Array<ExtraCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
};

export type ExtraCreateManyUserInputEnvelope = {
  data: Array<ExtraCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<ExtraCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<ExtraCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
};

export type ExtraCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ExtraCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<ExtraCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
};

export type ExtraCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ExtraCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ExtraCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
};

export type ExtraCreateNestedOneWithoutBestSellersInput = {
  create?: Maybe<ExtraCreateWithoutBestSellersInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutBestSellersInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
};

export type ExtraCreateNestedOneWithoutExtraInventoriesInput = {
  create?: Maybe<ExtraCreateWithoutExtraInventoriesInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutExtraInventoriesInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
};

export type ExtraCreateNestedOneWithoutOrderItemExtrasInput = {
  create?: Maybe<ExtraCreateWithoutOrderItemExtrasInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutOrderItemExtrasInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
};

export type ExtraCreateNestedOneWithoutOrderItemsInput = {
  create?: Maybe<ExtraCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutOrderItemsInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
};

export type ExtraCreateOrConnectWithoutBestSellersInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutBestSellersInput;
};

export type ExtraCreateOrConnectWithoutCategoryInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutCategoryInput;
};

export type ExtraCreateOrConnectWithoutExtraInventoriesInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutExtraInventoriesInput;
};

export type ExtraCreateOrConnectWithoutOrderItemExtrasInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutOrderItemExtrasInput;
};

export type ExtraCreateOrConnectWithoutOrderItemsInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutOrderItemsInput;
};

export type ExtraCreateOrConnectWithoutProductInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutProductInput;
};

export type ExtraCreateOrConnectWithoutUserInput = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateWithoutUserInput;
};

export type ExtraCreateWithoutBestSellersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
};

export type ExtraCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateWithoutExtraInventoriesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateWithoutOrderItemExtrasInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateWithoutOrderItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  user: UserCreateNestedOneWithoutExtrasInput;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutExtrasInput>;
  category?: Maybe<CategoryCreateNestedOneWithoutExtrasInput>;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutExtraInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutExtraInput>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutExtrasInput>;
};

export type ExtraGroupBy = {
  __typename?: 'ExtraGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  _count?: Maybe<ExtraCountAggregate>;
  _avg?: Maybe<ExtraAvgAggregate>;
  _sum?: Maybe<ExtraSumAggregate>;
  _min?: Maybe<ExtraMinAggregate>;
  _max?: Maybe<ExtraMaxAggregate>;
};

export type ExtraInventory = {
  __typename?: 'ExtraInventory';
  id: Scalars['String'];
  extraId: Scalars['String'];
  inventoryId: Scalars['String'];
  extra: Extra;
  inventory: Inventory;
};

export type ExtraInventoryCountAggregate = {
  __typename?: 'ExtraInventoryCountAggregate';
  id: Scalars['Int'];
  extraId: Scalars['Int'];
  inventoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ExtraInventoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ExtraInventoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  extra: ExtraCreateNestedOneWithoutExtraInventoriesInput;
  inventory: InventoryCreateNestedOneWithoutExtraInventoriesInput;
};

export type ExtraInventoryCreateManyExtraInput = {
  id?: Maybe<Scalars['String']>;
  inventoryId: Scalars['String'];
};

export type ExtraInventoryCreateManyExtraInputEnvelope = {
  data: Array<ExtraInventoryCreateManyExtraInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraInventoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  extraId: Scalars['String'];
  inventoryId: Scalars['String'];
};

export type ExtraInventoryCreateManyInventoryInput = {
  id?: Maybe<Scalars['String']>;
  extraId: Scalars['String'];
};

export type ExtraInventoryCreateManyInventoryInputEnvelope = {
  data: Array<ExtraInventoryCreateManyInventoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraInventoryCreateNestedManyWithoutExtraInput = {
  create?: Maybe<Array<ExtraInventoryCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<
    Array<ExtraInventoryCreateOrConnectWithoutExtraInput>
  >;
  createMany?: Maybe<ExtraInventoryCreateManyExtraInputEnvelope>;
  connect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
};

export type ExtraInventoryCreateNestedManyWithoutInventoryInput = {
  create?: Maybe<Array<ExtraInventoryCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<ExtraInventoryCreateOrConnectWithoutInventoryInput>
  >;
  createMany?: Maybe<ExtraInventoryCreateManyInventoryInputEnvelope>;
  connect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
};

export type ExtraInventoryCreateOrConnectWithoutExtraInput = {
  where: ExtraInventoryWhereUniqueInput;
  create: ExtraInventoryCreateWithoutExtraInput;
};

export type ExtraInventoryCreateOrConnectWithoutInventoryInput = {
  where: ExtraInventoryWhereUniqueInput;
  create: ExtraInventoryCreateWithoutInventoryInput;
};

export type ExtraInventoryCreateWithoutExtraInput = {
  id?: Maybe<Scalars['String']>;
  inventory: InventoryCreateNestedOneWithoutExtraInventoriesInput;
};

export type ExtraInventoryCreateWithoutInventoryInput = {
  id?: Maybe<Scalars['String']>;
  extra: ExtraCreateNestedOneWithoutExtraInventoriesInput;
};

export type ExtraInventoryGroupBy = {
  __typename?: 'ExtraInventoryGroupBy';
  id: Scalars['String'];
  extraId: Scalars['String'];
  inventoryId: Scalars['String'];
  _count?: Maybe<ExtraInventoryCountAggregate>;
  _min?: Maybe<ExtraInventoryMinAggregate>;
  _max?: Maybe<ExtraInventoryMaxAggregate>;
};

export type ExtraInventoryListRelationFilter = {
  every?: Maybe<ExtraInventoryWhereInput>;
  some?: Maybe<ExtraInventoryWhereInput>;
  none?: Maybe<ExtraInventoryWhereInput>;
};

export type ExtraInventoryMaxAggregate = {
  __typename?: 'ExtraInventoryMaxAggregate';
  id?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type ExtraInventoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ExtraInventoryMinAggregate = {
  __typename?: 'ExtraInventoryMinAggregate';
  id?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type ExtraInventoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ExtraInventoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ExtraInventoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
  _count?: Maybe<ExtraInventoryCountOrderByAggregateInput>;
  _max?: Maybe<ExtraInventoryMaxOrderByAggregateInput>;
  _min?: Maybe<ExtraInventoryMinOrderByAggregateInput>;
};

export type ExtraInventoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  extra?: Maybe<ExtraOrderByWithRelationInput>;
  extraId?: Maybe<SortOrder>;
  inventory?: Maybe<InventoryOrderByWithRelationInput>;
  inventoryId?: Maybe<SortOrder>;
};

export enum ExtraInventoryScalarFieldEnum {
  Id = 'id',
  ExtraId = 'extraId',
  InventoryId = 'inventoryId',
}

export type ExtraInventoryScalarWhereInput = {
  AND?: Maybe<Array<ExtraInventoryScalarWhereInput>>;
  OR?: Maybe<Array<ExtraInventoryScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraInventoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  extraId?: Maybe<StringFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type ExtraInventoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ExtraInventoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ExtraInventoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ExtraInventoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  extraId?: Maybe<StringWithAggregatesFilter>;
  inventoryId?: Maybe<StringWithAggregatesFilter>;
};

export type ExtraInventoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutExtraInventoriesNestedInput>;
  inventory?: Maybe<InventoryUpdateOneRequiredWithoutExtraInventoriesNestedInput>;
};

export type ExtraInventoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ExtraInventoryUpdateManyWithWhereWithoutExtraInput = {
  where: ExtraInventoryScalarWhereInput;
  data: ExtraInventoryUpdateManyMutationInput;
};

export type ExtraInventoryUpdateManyWithWhereWithoutInventoryInput = {
  where: ExtraInventoryScalarWhereInput;
  data: ExtraInventoryUpdateManyMutationInput;
};

export type ExtraInventoryUpdateManyWithoutExtraNestedInput = {
  create?: Maybe<Array<ExtraInventoryCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<
    Array<ExtraInventoryCreateOrConnectWithoutExtraInput>
  >;
  upsert?: Maybe<Array<ExtraInventoryUpsertWithWhereUniqueWithoutExtraInput>>;
  createMany?: Maybe<ExtraInventoryCreateManyExtraInputEnvelope>;
  set?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  connect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  update?: Maybe<Array<ExtraInventoryUpdateWithWhereUniqueWithoutExtraInput>>;
  updateMany?: Maybe<Array<ExtraInventoryUpdateManyWithWhereWithoutExtraInput>>;
  deleteMany?: Maybe<Array<ExtraInventoryScalarWhereInput>>;
};

export type ExtraInventoryUpdateManyWithoutInventoryNestedInput = {
  create?: Maybe<Array<ExtraInventoryCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<ExtraInventoryCreateOrConnectWithoutInventoryInput>
  >;
  upsert?: Maybe<
    Array<ExtraInventoryUpsertWithWhereUniqueWithoutInventoryInput>
  >;
  createMany?: Maybe<ExtraInventoryCreateManyInventoryInputEnvelope>;
  set?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  connect?: Maybe<Array<ExtraInventoryWhereUniqueInput>>;
  update?: Maybe<
    Array<ExtraInventoryUpdateWithWhereUniqueWithoutInventoryInput>
  >;
  updateMany?: Maybe<
    Array<ExtraInventoryUpdateManyWithWhereWithoutInventoryInput>
  >;
  deleteMany?: Maybe<Array<ExtraInventoryScalarWhereInput>>;
};

export type ExtraInventoryUpdateWithWhereUniqueWithoutExtraInput = {
  where: ExtraInventoryWhereUniqueInput;
  data: ExtraInventoryUpdateWithoutExtraInput;
};

export type ExtraInventoryUpdateWithWhereUniqueWithoutInventoryInput = {
  where: ExtraInventoryWhereUniqueInput;
  data: ExtraInventoryUpdateWithoutInventoryInput;
};

export type ExtraInventoryUpdateWithoutExtraInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inventory?: Maybe<InventoryUpdateOneRequiredWithoutExtraInventoriesNestedInput>;
};

export type ExtraInventoryUpdateWithoutInventoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutExtraInventoriesNestedInput>;
};

export type ExtraInventoryUpsertWithWhereUniqueWithoutExtraInput = {
  where: ExtraInventoryWhereUniqueInput;
  update: ExtraInventoryUpdateWithoutExtraInput;
  create: ExtraInventoryCreateWithoutExtraInput;
};

export type ExtraInventoryUpsertWithWhereUniqueWithoutInventoryInput = {
  where: ExtraInventoryWhereUniqueInput;
  update: ExtraInventoryUpdateWithoutInventoryInput;
  create: ExtraInventoryCreateWithoutInventoryInput;
};

export type ExtraInventoryWhereInput = {
  AND?: Maybe<Array<ExtraInventoryWhereInput>>;
  OR?: Maybe<Array<ExtraInventoryWhereInput>>;
  NOT?: Maybe<Array<ExtraInventoryWhereInput>>;
  id?: Maybe<StringFilter>;
  extra?: Maybe<ExtraRelationFilter>;
  extraId?: Maybe<StringFilter>;
  inventory?: Maybe<InventoryRelationFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type ExtraInventoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ExtraListRelationFilter = {
  every?: Maybe<ExtraWhereInput>;
  some?: Maybe<ExtraWhereInput>;
  none?: Maybe<ExtraWhereInput>;
};

export type ExtraMaxAggregate = {
  __typename?: 'ExtraMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ExtraMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ExtraMinAggregate = {
  __typename?: 'ExtraMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ExtraMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ExtraOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ExtraOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  _count?: Maybe<ExtraCountOrderByAggregateInput>;
  _avg?: Maybe<ExtraAvgOrderByAggregateInput>;
  _max?: Maybe<ExtraMaxOrderByAggregateInput>;
  _min?: Maybe<ExtraMinOrderByAggregateInput>;
  _sum?: Maybe<ExtraSumOrderByAggregateInput>;
};

export type ExtraOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  category?: Maybe<CategoryOrderByWithRelationInput>;
  categoryId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  orderItemExtras?: Maybe<OrderItemExtraOrderByRelationAggregateInput>;
  orderItems?: Maybe<OrderItemOrderByRelationAggregateInput>;
  extraInventories?: Maybe<ExtraInventoryOrderByRelationAggregateInput>;
  bestSellers?: Maybe<BestSellerOrderByRelationAggregateInput>;
};

export type ExtraRelationFilter = {
  is?: Maybe<ExtraWhereInput>;
  isNot?: Maybe<ExtraWhereInput>;
};

export enum ExtraScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Price = 'price',
  OrderIndex = 'orderIndex',
  Description = 'description',
  AutoIncrementAllow = 'autoIncrementAllow',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ProductId = 'productId',
  CategoryId = 'categoryId',
  UserId = 'userId',
}

export type ExtraScalarWhereInput = {
  AND?: Maybe<Array<ExtraScalarWhereInput>>;
  OR?: Maybe<Array<ExtraScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  orderIndex?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  autoIncrementAllow?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  productId?: Maybe<StringNullableFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type ExtraScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ExtraScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ExtraScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ExtraScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  orderIndex?: Maybe<FloatWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  autoIncrementAllow?: Maybe<BoolWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  productId?: Maybe<StringNullableWithAggregatesFilter>;
  categoryId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type ExtraSumAggregate = {
  __typename?: 'ExtraSumAggregate';
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
};

export type ExtraSumOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
};

export type ExtraUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateManyWithWhereWithoutCategoryInput = {
  where: ExtraScalarWhereInput;
  data: ExtraUpdateManyMutationInput;
};

export type ExtraUpdateManyWithWhereWithoutProductInput = {
  where: ExtraScalarWhereInput;
  data: ExtraUpdateManyMutationInput;
};

export type ExtraUpdateManyWithWhereWithoutUserInput = {
  where: ExtraScalarWhereInput;
  data: ExtraUpdateManyMutationInput;
};

export type ExtraUpdateManyWithoutCategoryNestedInput = {
  create?: Maybe<Array<ExtraCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<ExtraCreateManyCategoryInputEnvelope>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
};

export type ExtraUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ExtraCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ExtraCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
};

export type ExtraUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ExtraCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ExtraCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
};

export type ExtraUpdateOneRequiredWithoutExtraInventoriesNestedInput = {
  create?: Maybe<ExtraCreateWithoutExtraInventoriesInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutExtraInventoriesInput>;
  upsert?: Maybe<ExtraUpsertWithoutExtraInventoriesInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
  update?: Maybe<ExtraUpdateWithoutExtraInventoriesInput>;
};

export type ExtraUpdateOneRequiredWithoutOrderItemExtrasNestedInput = {
  create?: Maybe<ExtraCreateWithoutOrderItemExtrasInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutOrderItemExtrasInput>;
  upsert?: Maybe<ExtraUpsertWithoutOrderItemExtrasInput>;
  connect?: Maybe<ExtraWhereUniqueInput>;
  update?: Maybe<ExtraUpdateWithoutOrderItemExtrasInput>;
};

export type ExtraUpdateOneWithoutBestSellersNestedInput = {
  create?: Maybe<ExtraCreateWithoutBestSellersInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutBestSellersInput>;
  upsert?: Maybe<ExtraUpsertWithoutBestSellersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ExtraWhereUniqueInput>;
  update?: Maybe<ExtraUpdateWithoutBestSellersInput>;
};

export type ExtraUpdateOneWithoutOrderItemsNestedInput = {
  create?: Maybe<ExtraCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutOrderItemsInput>;
  upsert?: Maybe<ExtraUpsertWithoutOrderItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ExtraWhereUniqueInput>;
  update?: Maybe<ExtraUpdateWithoutOrderItemsInput>;
};

export type ExtraUpdateWithWhereUniqueWithoutCategoryInput = {
  where: ExtraWhereUniqueInput;
  data: ExtraUpdateWithoutCategoryInput;
};

export type ExtraUpdateWithWhereUniqueWithoutProductInput = {
  where: ExtraWhereUniqueInput;
  data: ExtraUpdateWithoutProductInput;
};

export type ExtraUpdateWithWhereUniqueWithoutUserInput = {
  where: ExtraWhereUniqueInput;
  data: ExtraUpdateWithoutUserInput;
};

export type ExtraUpdateWithoutBestSellersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
};

export type ExtraUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateWithoutExtraInventoriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateWithoutOrderItemExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateWithoutOrderItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutExtrasNestedInput>;
  category?: Maybe<CategoryUpdateOneWithoutExtrasNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutExtraNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutExtraNestedInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutExtrasNestedInput>;
};

export type ExtraUpsertWithWhereUniqueWithoutCategoryInput = {
  where: ExtraWhereUniqueInput;
  update: ExtraUpdateWithoutCategoryInput;
  create: ExtraCreateWithoutCategoryInput;
};

export type ExtraUpsertWithWhereUniqueWithoutProductInput = {
  where: ExtraWhereUniqueInput;
  update: ExtraUpdateWithoutProductInput;
  create: ExtraCreateWithoutProductInput;
};

export type ExtraUpsertWithWhereUniqueWithoutUserInput = {
  where: ExtraWhereUniqueInput;
  update: ExtraUpdateWithoutUserInput;
  create: ExtraCreateWithoutUserInput;
};

export type ExtraUpsertWithoutBestSellersInput = {
  update: ExtraUpdateWithoutBestSellersInput;
  create: ExtraCreateWithoutBestSellersInput;
};

export type ExtraUpsertWithoutExtraInventoriesInput = {
  update: ExtraUpdateWithoutExtraInventoriesInput;
  create: ExtraCreateWithoutExtraInventoriesInput;
};

export type ExtraUpsertWithoutOrderItemExtrasInput = {
  update: ExtraUpdateWithoutOrderItemExtrasInput;
  create: ExtraCreateWithoutOrderItemExtrasInput;
};

export type ExtraUpsertWithoutOrderItemsInput = {
  update: ExtraUpdateWithoutOrderItemsInput;
  create: ExtraCreateWithoutOrderItemsInput;
};

export type ExtraWhereInput = {
  AND?: Maybe<Array<ExtraWhereInput>>;
  OR?: Maybe<Array<ExtraWhereInput>>;
  NOT?: Maybe<Array<ExtraWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  orderIndex?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  autoIncrementAllow?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
  category?: Maybe<CategoryRelationFilter>;
  categoryId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  orderItemExtras?: Maybe<OrderItemExtraListRelationFilter>;
  orderItems?: Maybe<OrderItemListRelationFilter>;
  extraInventories?: Maybe<ExtraInventoryListRelationFilter>;
  bestSellers?: Maybe<BestSellerListRelationFilter>;
};

export type ExtraWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type Ingredience = {
  __typename?: 'Ingredience';
  id: Scalars['String'];
  name: Scalars['String'];
  _count?: Maybe<IngredienceCount>;
  priceHistoryIngrediences: Array<PriceHistoryIngredience>;
  productIngrediences: Array<ProductIngredience>;
};

export type IngrediencePriceHistoryIngrediencesArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceHistoryIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PriceHistoryIngredienceScalarFieldEnum>>;
};

export type IngredienceProductIngrediencesArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductIngredienceScalarFieldEnum>>;
};

export type IngredienceCount = {
  __typename?: 'IngredienceCount';
  priceHistoryIngrediences: Scalars['Int'];
  productIngrediences: Scalars['Int'];
};

export type IngredienceCountAggregate = {
  __typename?: 'IngredienceCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  _all: Scalars['Int'];
};

export type IngredienceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type IngredienceCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceCreateNestedManyWithoutIngredienceInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutIngredienceInput>;
};

export type IngredienceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type IngredienceCreateNestedOneWithoutPriceHistoryIngrediencesInput = {
  create?: Maybe<IngredienceCreateWithoutPriceHistoryIngrediencesInput>;
  connectOrCreate?: Maybe<IngredienceCreateOrConnectWithoutPriceHistoryIngrediencesInput>;
  connect?: Maybe<IngredienceWhereUniqueInput>;
};

export type IngredienceCreateNestedOneWithoutProductIngrediencesInput = {
  create?: Maybe<IngredienceCreateWithoutProductIngrediencesInput>;
  connectOrCreate?: Maybe<IngredienceCreateOrConnectWithoutProductIngrediencesInput>;
  connect?: Maybe<IngredienceWhereUniqueInput>;
};

export type IngredienceCreateOrConnectWithoutPriceHistoryIngrediencesInput = {
  where: IngredienceWhereUniqueInput;
  create: IngredienceCreateWithoutPriceHistoryIngrediencesInput;
};

export type IngredienceCreateOrConnectWithoutProductIngrediencesInput = {
  where: IngredienceWhereUniqueInput;
  create: IngredienceCreateWithoutProductIngrediencesInput;
};

export type IngredienceCreateWithoutPriceHistoryIngrediencesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutIngredienceInput>;
};

export type IngredienceCreateWithoutProductIngrediencesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceCreateNestedManyWithoutIngredienceInput>;
};

export type IngredienceGroupBy = {
  __typename?: 'IngredienceGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  _count?: Maybe<IngredienceCountAggregate>;
  _min?: Maybe<IngredienceMinAggregate>;
  _max?: Maybe<IngredienceMaxAggregate>;
};

export type IngredienceMaxAggregate = {
  __typename?: 'IngredienceMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IngredienceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type IngredienceMinAggregate = {
  __typename?: 'IngredienceMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IngredienceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
};

export type IngredienceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  _count?: Maybe<IngredienceCountOrderByAggregateInput>;
  _max?: Maybe<IngredienceMaxOrderByAggregateInput>;
  _min?: Maybe<IngredienceMinOrderByAggregateInput>;
};

export type IngredienceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceOrderByRelationAggregateInput>;
  productIngrediences?: Maybe<ProductIngredienceOrderByRelationAggregateInput>;
};

export type IngredienceRelationFilter = {
  is?: Maybe<IngredienceWhereInput>;
  isNot?: Maybe<IngredienceWhereInput>;
};

export enum IngredienceScalarFieldEnum {
  Id = 'id',
  Name = 'name',
}

export type IngredienceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<IngredienceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<IngredienceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<IngredienceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
};

export type IngredienceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceUpdateManyWithoutIngredienceNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutIngredienceNestedInput>;
};

export type IngredienceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type IngredienceUpdateOneRequiredWithoutProductIngrediencesNestedInput =
  {
    create?: Maybe<IngredienceCreateWithoutProductIngrediencesInput>;
    connectOrCreate?: Maybe<IngredienceCreateOrConnectWithoutProductIngrediencesInput>;
    upsert?: Maybe<IngredienceUpsertWithoutProductIngrediencesInput>;
    connect?: Maybe<IngredienceWhereUniqueInput>;
    update?: Maybe<IngredienceUpdateWithoutProductIngrediencesInput>;
  };

export type IngredienceUpdateOneWithoutPriceHistoryIngrediencesNestedInput = {
  create?: Maybe<IngredienceCreateWithoutPriceHistoryIngrediencesInput>;
  connectOrCreate?: Maybe<IngredienceCreateOrConnectWithoutPriceHistoryIngrediencesInput>;
  upsert?: Maybe<IngredienceUpsertWithoutPriceHistoryIngrediencesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<IngredienceWhereUniqueInput>;
  update?: Maybe<IngredienceUpdateWithoutPriceHistoryIngrediencesInput>;
};

export type IngredienceUpdateWithoutPriceHistoryIngrediencesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutIngredienceNestedInput>;
};

export type IngredienceUpdateWithoutProductIngrediencesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceUpdateManyWithoutIngredienceNestedInput>;
};

export type IngredienceUpsertWithoutPriceHistoryIngrediencesInput = {
  update: IngredienceUpdateWithoutPriceHistoryIngrediencesInput;
  create: IngredienceCreateWithoutPriceHistoryIngrediencesInput;
};

export type IngredienceUpsertWithoutProductIngrediencesInput = {
  update: IngredienceUpdateWithoutProductIngrediencesInput;
  create: IngredienceCreateWithoutProductIngrediencesInput;
};

export type IngredienceWhereInput = {
  AND?: Maybe<Array<IngredienceWhereInput>>;
  OR?: Maybe<Array<IngredienceWhereInput>>;
  NOT?: Maybe<Array<IngredienceWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  priceHistoryIngrediences?: Maybe<PriceHistoryIngredienceListRelationFilter>;
  productIngrediences?: Maybe<ProductIngredienceListRelationFilter>;
};

export type IngredienceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type Inventory = {
  __typename?: 'Inventory';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  _count?: Maybe<InventoryCount>;
  inventoryItems: Array<InventoryItem>;
  productInventories: Array<ProductInventory>;
  extraInventories: Array<ExtraInventory>;
};

export type InventoryInventoryItemsArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryItemScalarFieldEnum>>;
};

export type InventoryProductInventoriesArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductInventoryScalarFieldEnum>>;
};

export type InventoryExtraInventoriesArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraInventoryScalarFieldEnum>>;
};

export type InventoryAvgAggregate = {
  __typename?: 'InventoryAvgAggregate';
  minimumAmount?: Maybe<Scalars['Float']>;
};

export type InventoryAvgOrderByAggregateInput = {
  minimumAmount?: Maybe<SortOrder>;
};

export type InventoryCount = {
  __typename?: 'InventoryCount';
  inventoryItems: Scalars['Int'];
  productInventories: Scalars['Int'];
  extraInventories: Scalars['Int'];
};

export type InventoryCountAggregate = {
  __typename?: 'InventoryCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  description: Scalars['Int'];
  minimumAmount: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InventoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minimumAmount?: Maybe<SortOrder>;
};

export type InventoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  inventoryItems?: Maybe<InventoryItemCreateNestedManyWithoutInventoryInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutInventoryInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutInventoryInput>;
};

export type InventoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
};

export type InventoryCreateNestedOneWithoutExtraInventoriesInput = {
  create?: Maybe<InventoryCreateWithoutExtraInventoriesInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutExtraInventoriesInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
};

export type InventoryCreateNestedOneWithoutInventoryItemsInput = {
  create?: Maybe<InventoryCreateWithoutInventoryItemsInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutInventoryItemsInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
};

export type InventoryCreateNestedOneWithoutProductInventoriesInput = {
  create?: Maybe<InventoryCreateWithoutProductInventoriesInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutProductInventoriesInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
};

export type InventoryCreateOrConnectWithoutExtraInventoriesInput = {
  where: InventoryWhereUniqueInput;
  create: InventoryCreateWithoutExtraInventoriesInput;
};

export type InventoryCreateOrConnectWithoutInventoryItemsInput = {
  where: InventoryWhereUniqueInput;
  create: InventoryCreateWithoutInventoryItemsInput;
};

export type InventoryCreateOrConnectWithoutProductInventoriesInput = {
  where: InventoryWhereUniqueInput;
  create: InventoryCreateWithoutProductInventoriesInput;
};

export type InventoryCreateWithoutExtraInventoriesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  inventoryItems?: Maybe<InventoryItemCreateNestedManyWithoutInventoryInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutInventoryInput>;
};

export type InventoryCreateWithoutInventoryItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutInventoryInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutInventoryInput>;
};

export type InventoryCreateWithoutProductInventoriesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  inventoryItems?: Maybe<InventoryItemCreateNestedManyWithoutInventoryInput>;
  extraInventories?: Maybe<ExtraInventoryCreateNestedManyWithoutInventoryInput>;
};

export type InventoryGroupBy = {
  __typename?: 'InventoryGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
  _count?: Maybe<InventoryCountAggregate>;
  _avg?: Maybe<InventoryAvgAggregate>;
  _sum?: Maybe<InventorySumAggregate>;
  _min?: Maybe<InventoryMinAggregate>;
  _max?: Maybe<InventoryMaxAggregate>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  id: Scalars['String'];
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  inventoryId: Scalars['String'];
  inventory: Inventory;
};

export type InventoryItemAvgAggregate = {
  __typename?: 'InventoryItemAvgAggregate';
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type InventoryItemAvgOrderByAggregateInput = {
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
};

export type InventoryItemCountAggregate = {
  __typename?: 'InventoryItemCountAggregate';
  id: Scalars['Int'];
  availableAmount: Scalars['Int'];
  price: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  inventoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type InventoryItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type InventoryItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  inventory: InventoryCreateNestedOneWithoutInventoryItemsInput;
};

export type InventoryItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  inventoryId: Scalars['String'];
};

export type InventoryItemCreateManyInventoryInput = {
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyInventoryInputEnvelope = {
  data: Array<InventoryItemCreateManyInventoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InventoryItemCreateNestedManyWithoutInventoryInput = {
  create?: Maybe<Array<InventoryItemCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<InventoryItemCreateOrConnectWithoutInventoryInput>
  >;
  createMany?: Maybe<InventoryItemCreateManyInventoryInputEnvelope>;
  connect?: Maybe<Array<InventoryItemWhereUniqueInput>>;
};

export type InventoryItemCreateOrConnectWithoutInventoryInput = {
  where: InventoryItemWhereUniqueInput;
  create: InventoryItemCreateWithoutInventoryInput;
};

export type InventoryItemCreateWithoutInventoryInput = {
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryItemGroupBy = {
  __typename?: 'InventoryItemGroupBy';
  id: Scalars['String'];
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  inventoryId: Scalars['String'];
  _count?: Maybe<InventoryItemCountAggregate>;
  _avg?: Maybe<InventoryItemAvgAggregate>;
  _sum?: Maybe<InventoryItemSumAggregate>;
  _min?: Maybe<InventoryItemMinAggregate>;
  _max?: Maybe<InventoryItemMaxAggregate>;
};

export type InventoryItemListRelationFilter = {
  every?: Maybe<InventoryItemWhereInput>;
  some?: Maybe<InventoryItemWhereInput>;
  none?: Maybe<InventoryItemWhereInput>;
};

export type InventoryItemMaxAggregate = {
  __typename?: 'InventoryItemMaxAggregate';
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type InventoryItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type InventoryItemMinAggregate = {
  __typename?: 'InventoryItemMinAggregate';
  id?: Maybe<Scalars['String']>;
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type InventoryItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type InventoryItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type InventoryItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
  _count?: Maybe<InventoryItemCountOrderByAggregateInput>;
  _avg?: Maybe<InventoryItemAvgOrderByAggregateInput>;
  _max?: Maybe<InventoryItemMaxOrderByAggregateInput>;
  _min?: Maybe<InventoryItemMinOrderByAggregateInput>;
  _sum?: Maybe<InventoryItemSumOrderByAggregateInput>;
};

export type InventoryItemOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  inventory?: Maybe<InventoryOrderByWithRelationInput>;
  inventoryId?: Maybe<SortOrder>;
};

export enum InventoryItemScalarFieldEnum {
  Id = 'id',
  AvailableAmount = 'availableAmount',
  Price = 'price',
  Description = 'description',
  CreatedAt = 'createdAt',
  InventoryId = 'inventoryId',
}

export type InventoryItemScalarWhereInput = {
  AND?: Maybe<Array<InventoryItemScalarWhereInput>>;
  OR?: Maybe<Array<InventoryItemScalarWhereInput>>;
  NOT?: Maybe<Array<InventoryItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  availableAmount?: Maybe<FloatNullableFilter>;
  price?: Maybe<FloatNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type InventoryItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InventoryItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InventoryItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InventoryItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  availableAmount?: Maybe<FloatNullableWithAggregatesFilter>;
  price?: Maybe<FloatNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  inventoryId?: Maybe<StringWithAggregatesFilter>;
};

export type InventoryItemSumAggregate = {
  __typename?: 'InventoryItemSumAggregate';
  availableAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type InventoryItemSumOrderByAggregateInput = {
  availableAmount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
};

export type InventoryItemUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  availableAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  inventory?: Maybe<InventoryUpdateOneRequiredWithoutInventoryItemsNestedInput>;
};

export type InventoryItemUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  availableAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InventoryItemUpdateManyWithWhereWithoutInventoryInput = {
  where: InventoryItemScalarWhereInput;
  data: InventoryItemUpdateManyMutationInput;
};

export type InventoryItemUpdateManyWithoutInventoryNestedInput = {
  create?: Maybe<Array<InventoryItemCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<InventoryItemCreateOrConnectWithoutInventoryInput>
  >;
  upsert?: Maybe<
    Array<InventoryItemUpsertWithWhereUniqueWithoutInventoryInput>
  >;
  createMany?: Maybe<InventoryItemCreateManyInventoryInputEnvelope>;
  set?: Maybe<Array<InventoryItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<InventoryItemWhereUniqueInput>>;
  delete?: Maybe<Array<InventoryItemWhereUniqueInput>>;
  connect?: Maybe<Array<InventoryItemWhereUniqueInput>>;
  update?: Maybe<
    Array<InventoryItemUpdateWithWhereUniqueWithoutInventoryInput>
  >;
  updateMany?: Maybe<
    Array<InventoryItemUpdateManyWithWhereWithoutInventoryInput>
  >;
  deleteMany?: Maybe<Array<InventoryItemScalarWhereInput>>;
};

export type InventoryItemUpdateWithWhereUniqueWithoutInventoryInput = {
  where: InventoryItemWhereUniqueInput;
  data: InventoryItemUpdateWithoutInventoryInput;
};

export type InventoryItemUpdateWithoutInventoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  availableAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  price?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InventoryItemUpsertWithWhereUniqueWithoutInventoryInput = {
  where: InventoryItemWhereUniqueInput;
  update: InventoryItemUpdateWithoutInventoryInput;
  create: InventoryItemCreateWithoutInventoryInput;
};

export type InventoryItemWhereInput = {
  AND?: Maybe<Array<InventoryItemWhereInput>>;
  OR?: Maybe<Array<InventoryItemWhereInput>>;
  NOT?: Maybe<Array<InventoryItemWhereInput>>;
  id?: Maybe<StringFilter>;
  availableAmount?: Maybe<FloatNullableFilter>;
  price?: Maybe<FloatNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  inventory?: Maybe<InventoryRelationFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type InventoryItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InventoryMaxAggregate = {
  __typename?: 'InventoryMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
};

export type InventoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minimumAmount?: Maybe<SortOrder>;
};

export type InventoryMinAggregate = {
  __typename?: 'InventoryMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['Float']>;
};

export type InventoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minimumAmount?: Maybe<SortOrder>;
};

export type InventoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minimumAmount?: Maybe<SortOrder>;
  _count?: Maybe<InventoryCountOrderByAggregateInput>;
  _avg?: Maybe<InventoryAvgOrderByAggregateInput>;
  _max?: Maybe<InventoryMaxOrderByAggregateInput>;
  _min?: Maybe<InventoryMinOrderByAggregateInput>;
  _sum?: Maybe<InventorySumOrderByAggregateInput>;
};

export type InventoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  minimumAmount?: Maybe<SortOrder>;
  inventoryItems?: Maybe<InventoryItemOrderByRelationAggregateInput>;
  productInventories?: Maybe<ProductInventoryOrderByRelationAggregateInput>;
  extraInventories?: Maybe<ExtraInventoryOrderByRelationAggregateInput>;
};

export type InventoryRelationFilter = {
  is?: Maybe<InventoryWhereInput>;
  isNot?: Maybe<InventoryWhereInput>;
};

export enum InventoryScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  MinimumAmount = 'minimumAmount',
}

export type InventoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InventoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InventoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InventoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  minimumAmount?: Maybe<FloatNullableWithAggregatesFilter>;
};

export type InventorySumAggregate = {
  __typename?: 'InventorySumAggregate';
  minimumAmount?: Maybe<Scalars['Float']>;
};

export type InventorySumOrderByAggregateInput = {
  minimumAmount?: Maybe<SortOrder>;
};

export type InventoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  minimumAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  inventoryItems?: Maybe<InventoryItemUpdateManyWithoutInventoryNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutInventoryNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutInventoryNestedInput>;
};

export type InventoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  minimumAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type InventoryUpdateOneRequiredWithoutExtraInventoriesNestedInput = {
  create?: Maybe<InventoryCreateWithoutExtraInventoriesInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutExtraInventoriesInput>;
  upsert?: Maybe<InventoryUpsertWithoutExtraInventoriesInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
  update?: Maybe<InventoryUpdateWithoutExtraInventoriesInput>;
};

export type InventoryUpdateOneRequiredWithoutInventoryItemsNestedInput = {
  create?: Maybe<InventoryCreateWithoutInventoryItemsInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutInventoryItemsInput>;
  upsert?: Maybe<InventoryUpsertWithoutInventoryItemsInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
  update?: Maybe<InventoryUpdateWithoutInventoryItemsInput>;
};

export type InventoryUpdateOneRequiredWithoutProductInventoriesNestedInput = {
  create?: Maybe<InventoryCreateWithoutProductInventoriesInput>;
  connectOrCreate?: Maybe<InventoryCreateOrConnectWithoutProductInventoriesInput>;
  upsert?: Maybe<InventoryUpsertWithoutProductInventoriesInput>;
  connect?: Maybe<InventoryWhereUniqueInput>;
  update?: Maybe<InventoryUpdateWithoutProductInventoriesInput>;
};

export type InventoryUpdateWithoutExtraInventoriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  minimumAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  inventoryItems?: Maybe<InventoryItemUpdateManyWithoutInventoryNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutInventoryNestedInput>;
};

export type InventoryUpdateWithoutInventoryItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  minimumAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutInventoryNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutInventoryNestedInput>;
};

export type InventoryUpdateWithoutProductInventoriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  minimumAmount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  inventoryItems?: Maybe<InventoryItemUpdateManyWithoutInventoryNestedInput>;
  extraInventories?: Maybe<ExtraInventoryUpdateManyWithoutInventoryNestedInput>;
};

export type InventoryUpsertWithoutExtraInventoriesInput = {
  update: InventoryUpdateWithoutExtraInventoriesInput;
  create: InventoryCreateWithoutExtraInventoriesInput;
};

export type InventoryUpsertWithoutInventoryItemsInput = {
  update: InventoryUpdateWithoutInventoryItemsInput;
  create: InventoryCreateWithoutInventoryItemsInput;
};

export type InventoryUpsertWithoutProductInventoriesInput = {
  update: InventoryUpdateWithoutProductInventoriesInput;
  create: InventoryCreateWithoutProductInventoriesInput;
};

export type InventoryWhereInput = {
  AND?: Maybe<Array<InventoryWhereInput>>;
  OR?: Maybe<Array<InventoryWhereInput>>;
  NOT?: Maybe<Array<InventoryWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  minimumAmount?: Maybe<FloatNullableFilter>;
  inventoryItems?: Maybe<InventoryItemListRelationFilter>;
  productInventories?: Maybe<ProductInventoryListRelationFilter>;
  extraInventories?: Maybe<ExtraInventoryListRelationFilter>;
};

export type InventoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
  access_token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createManyAttendance: AffectedRowsOutput;
  createOneAttendance: Attendance;
  deleteManyAttendance: AffectedRowsOutput;
  deleteOneAttendance?: Maybe<Attendance>;
  updateManyAttendance: AffectedRowsOutput;
  updateOneAttendance?: Maybe<Attendance>;
  upsertOneAttendance: Attendance;
  createManyBestSeller: AffectedRowsOutput;
  createOneBestSeller: BestSeller;
  deleteManyBestSeller: AffectedRowsOutput;
  deleteOneBestSeller?: Maybe<BestSeller>;
  updateManyBestSeller: AffectedRowsOutput;
  updateOneBestSeller?: Maybe<BestSeller>;
  upsertOneBestSeller: BestSeller;
  createManyCashbox: AffectedRowsOutput;
  createOneCashbox: Cashbox;
  deleteManyCashbox: AffectedRowsOutput;
  deleteOneCashbox?: Maybe<Cashbox>;
  updateManyCashbox: AffectedRowsOutput;
  updateOneCashbox?: Maybe<Cashbox>;
  upsertOneCashbox: Cashbox;
  createManyCashboxItem: AffectedRowsOutput;
  createOneCashboxItem: CashboxItem;
  deleteManyCashboxItem: AffectedRowsOutput;
  deleteOneCashboxItem?: Maybe<CashboxItem>;
  updateManyCashboxItem: AffectedRowsOutput;
  updateOneCashboxItem?: Maybe<CashboxItem>;
  upsertOneCashboxItem: CashboxItem;
  createManyCategory: AffectedRowsOutput;
  createOneCategory: Category;
  deleteManyCategory: AffectedRowsOutput;
  deleteOneCategory?: Maybe<Category>;
  updateManyCategory: AffectedRowsOutput;
  updateOneCategory?: Maybe<Category>;
  upsertOneCategory: Category;
  createManyExtra: AffectedRowsOutput;
  createOneExtra: Extra;
  deleteManyExtra: AffectedRowsOutput;
  deleteOneExtra?: Maybe<Extra>;
  updateManyExtra: AffectedRowsOutput;
  updateOneExtra?: Maybe<Extra>;
  upsertOneExtra: Extra;
  createManyExtraInventory: AffectedRowsOutput;
  createOneExtraInventory: ExtraInventory;
  deleteManyExtraInventory: AffectedRowsOutput;
  deleteOneExtraInventory?: Maybe<ExtraInventory>;
  updateManyExtraInventory: AffectedRowsOutput;
  updateOneExtraInventory?: Maybe<ExtraInventory>;
  upsertOneExtraInventory: ExtraInventory;
  createManyIngredience: AffectedRowsOutput;
  createOneIngredience: Ingredience;
  deleteManyIngredience: AffectedRowsOutput;
  deleteOneIngredience?: Maybe<Ingredience>;
  updateManyIngredience: AffectedRowsOutput;
  updateOneIngredience?: Maybe<Ingredience>;
  upsertOneIngredience: Ingredience;
  createManyInventory: AffectedRowsOutput;
  createOneInventory: Inventory;
  deleteManyInventory: AffectedRowsOutput;
  deleteOneInventory?: Maybe<Inventory>;
  updateManyInventory: AffectedRowsOutput;
  updateOneInventory?: Maybe<Inventory>;
  upsertOneInventory: Inventory;
  createManyInventoryItem: AffectedRowsOutput;
  createOneInventoryItem: InventoryItem;
  deleteManyInventoryItem: AffectedRowsOutput;
  deleteOneInventoryItem?: Maybe<InventoryItem>;
  updateManyInventoryItem: AffectedRowsOutput;
  updateOneInventoryItem?: Maybe<InventoryItem>;
  upsertOneInventoryItem: InventoryItem;
  createManyOrder: AffectedRowsOutput;
  createOneOrder: Order;
  deleteManyOrder: AffectedRowsOutput;
  deleteOneOrder?: Maybe<Order>;
  updateManyOrder: AffectedRowsOutput;
  updateOneOrder?: Maybe<Order>;
  upsertOneOrder: Order;
  createManyOrderItem: AffectedRowsOutput;
  createOneOrderItem: OrderItem;
  deleteManyOrderItem: AffectedRowsOutput;
  deleteOneOrderItem?: Maybe<OrderItem>;
  updateManyOrderItem: AffectedRowsOutput;
  updateOneOrderItem?: Maybe<OrderItem>;
  upsertOneOrderItem: OrderItem;
  createManyOrderItemExtra: AffectedRowsOutput;
  createOneOrderItemExtra: OrderItemExtra;
  deleteManyOrderItemExtra: AffectedRowsOutput;
  deleteOneOrderItemExtra?: Maybe<OrderItemExtra>;
  updateManyOrderItemExtra: AffectedRowsOutput;
  updateOneOrderItemExtra?: Maybe<OrderItemExtra>;
  upsertOneOrderItemExtra: OrderItemExtra;
  createManyPriceHistoryIngredience: AffectedRowsOutput;
  createOnePriceHistoryIngredience: PriceHistoryIngredience;
  deleteManyPriceHistoryIngredience: AffectedRowsOutput;
  deleteOnePriceHistoryIngredience?: Maybe<PriceHistoryIngredience>;
  updateManyPriceHistoryIngredience: AffectedRowsOutput;
  updateOnePriceHistoryIngredience?: Maybe<PriceHistoryIngredience>;
  upsertOnePriceHistoryIngredience: PriceHistoryIngredience;
  createManyProduct: AffectedRowsOutput;
  createOneProduct: Product;
  deleteManyProduct: AffectedRowsOutput;
  deleteOneProduct?: Maybe<Product>;
  updateManyProduct: AffectedRowsOutput;
  updateOneProduct?: Maybe<Product>;
  upsertOneProduct: Product;
  createManyProductIngredience: AffectedRowsOutput;
  createOneProductIngredience: ProductIngredience;
  deleteManyProductIngredience: AffectedRowsOutput;
  deleteOneProductIngredience?: Maybe<ProductIngredience>;
  updateManyProductIngredience: AffectedRowsOutput;
  updateOneProductIngredience?: Maybe<ProductIngredience>;
  upsertOneProductIngredience: ProductIngredience;
  createManyProductInventory: AffectedRowsOutput;
  createOneProductInventory: ProductInventory;
  deleteManyProductInventory: AffectedRowsOutput;
  deleteOneProductInventory?: Maybe<ProductInventory>;
  updateManyProductInventory: AffectedRowsOutput;
  updateOneProductInventory?: Maybe<ProductInventory>;
  upsertOneProductInventory: ProductInventory;
  createManyProductStock: AffectedRowsOutput;
  createOneProductStock: ProductStock;
  deleteManyProductStock: AffectedRowsOutput;
  deleteOneProductStock?: Maybe<ProductStock>;
  updateManyProductStock: AffectedRowsOutput;
  updateOneProductStock?: Maybe<ProductStock>;
  upsertOneProductStock: ProductStock;
  createManySingleExpense: AffectedRowsOutput;
  createOneSingleExpense: SingleExpense;
  deleteManySingleExpense: AffectedRowsOutput;
  deleteOneSingleExpense?: Maybe<SingleExpense>;
  updateManySingleExpense: AffectedRowsOutput;
  updateOneSingleExpense?: Maybe<SingleExpense>;
  upsertOneSingleExpense: SingleExpense;
  createManyTaxe: AffectedRowsOutput;
  createOneTaxe: Taxe;
  deleteManyTaxe: AffectedRowsOutput;
  deleteOneTaxe?: Maybe<Taxe>;
  updateManyTaxe: AffectedRowsOutput;
  updateOneTaxe?: Maybe<Taxe>;
  upsertOneTaxe: Taxe;
  createManyUser: AffectedRowsOutput;
  createOneUser: User;
  deleteManyUser: AffectedRowsOutput;
  deleteOneUser?: Maybe<User>;
  updateManyUser: AffectedRowsOutput;
  updateOneUser?: Maybe<User>;
  upsertOneUser: User;
  login: LoginResponse;
  testMutation: Scalars['Boolean'];
};

export type MutationCreateManyAttendanceArgs = {
  data: Array<AttendanceCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneAttendanceArgs = {
  data: AttendanceCreateInput;
};

export type MutationDeleteManyAttendanceArgs = {
  where?: Maybe<AttendanceWhereInput>;
};

export type MutationDeleteOneAttendanceArgs = {
  where: AttendanceWhereUniqueInput;
};

export type MutationUpdateManyAttendanceArgs = {
  data: AttendanceUpdateManyMutationInput;
  where?: Maybe<AttendanceWhereInput>;
};

export type MutationUpdateOneAttendanceArgs = {
  data: AttendanceUpdateInput;
  where: AttendanceWhereUniqueInput;
};

export type MutationUpsertOneAttendanceArgs = {
  where: AttendanceWhereUniqueInput;
  create: AttendanceCreateInput;
  update: AttendanceUpdateInput;
};

export type MutationCreateManyBestSellerArgs = {
  data: Array<BestSellerCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneBestSellerArgs = {
  data: BestSellerCreateInput;
};

export type MutationDeleteManyBestSellerArgs = {
  where?: Maybe<BestSellerWhereInput>;
};

export type MutationDeleteOneBestSellerArgs = {
  where: BestSellerWhereUniqueInput;
};

export type MutationUpdateManyBestSellerArgs = {
  data: BestSellerUpdateManyMutationInput;
  where?: Maybe<BestSellerWhereInput>;
};

export type MutationUpdateOneBestSellerArgs = {
  data: BestSellerUpdateInput;
  where: BestSellerWhereUniqueInput;
};

export type MutationUpsertOneBestSellerArgs = {
  where: BestSellerWhereUniqueInput;
  create: BestSellerCreateInput;
  update: BestSellerUpdateInput;
};

export type MutationCreateManyCashboxArgs = {
  data: Array<CashboxCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneCashboxArgs = {
  data: CashboxCreateInput;
};

export type MutationDeleteManyCashboxArgs = {
  where?: Maybe<CashboxWhereInput>;
};

export type MutationDeleteOneCashboxArgs = {
  where: CashboxWhereUniqueInput;
};

export type MutationUpdateManyCashboxArgs = {
  data: CashboxUpdateManyMutationInput;
  where?: Maybe<CashboxWhereInput>;
};

export type MutationUpdateOneCashboxArgs = {
  data: CashboxUpdateInput;
  where: CashboxWhereUniqueInput;
};

export type MutationUpsertOneCashboxArgs = {
  where: CashboxWhereUniqueInput;
  create: CashboxCreateInput;
  update: CashboxUpdateInput;
};

export type MutationCreateManyCashboxItemArgs = {
  data: Array<CashboxItemCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneCashboxItemArgs = {
  data: CashboxItemCreateInput;
};

export type MutationDeleteManyCashboxItemArgs = {
  where?: Maybe<CashboxItemWhereInput>;
};

export type MutationDeleteOneCashboxItemArgs = {
  where: CashboxItemWhereUniqueInput;
};

export type MutationUpdateManyCashboxItemArgs = {
  data: CashboxItemUpdateManyMutationInput;
  where?: Maybe<CashboxItemWhereInput>;
};

export type MutationUpdateOneCashboxItemArgs = {
  data: CashboxItemUpdateInput;
  where: CashboxItemWhereUniqueInput;
};

export type MutationUpsertOneCashboxItemArgs = {
  where: CashboxItemWhereUniqueInput;
  create: CashboxItemCreateInput;
  update: CashboxItemUpdateInput;
};

export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};

export type MutationDeleteManyCategoryArgs = {
  where?: Maybe<CategoryWhereInput>;
};

export type MutationDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: Maybe<CategoryWhereInput>;
};

export type MutationUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpsertOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
};

export type MutationCreateManyExtraArgs = {
  data: Array<ExtraCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneExtraArgs = {
  data: ExtraCreateInput;
};

export type MutationDeleteManyExtraArgs = {
  where?: Maybe<ExtraWhereInput>;
};

export type MutationDeleteOneExtraArgs = {
  where: ExtraWhereUniqueInput;
};

export type MutationUpdateManyExtraArgs = {
  data: ExtraUpdateManyMutationInput;
  where?: Maybe<ExtraWhereInput>;
};

export type MutationUpdateOneExtraArgs = {
  data: ExtraUpdateInput;
  where: ExtraWhereUniqueInput;
};

export type MutationUpsertOneExtraArgs = {
  where: ExtraWhereUniqueInput;
  create: ExtraCreateInput;
  update: ExtraUpdateInput;
};

export type MutationCreateManyExtraInventoryArgs = {
  data: Array<ExtraInventoryCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneExtraInventoryArgs = {
  data: ExtraInventoryCreateInput;
};

export type MutationDeleteManyExtraInventoryArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
};

export type MutationDeleteOneExtraInventoryArgs = {
  where: ExtraInventoryWhereUniqueInput;
};

export type MutationUpdateManyExtraInventoryArgs = {
  data: ExtraInventoryUpdateManyMutationInput;
  where?: Maybe<ExtraInventoryWhereInput>;
};

export type MutationUpdateOneExtraInventoryArgs = {
  data: ExtraInventoryUpdateInput;
  where: ExtraInventoryWhereUniqueInput;
};

export type MutationUpsertOneExtraInventoryArgs = {
  where: ExtraInventoryWhereUniqueInput;
  create: ExtraInventoryCreateInput;
  update: ExtraInventoryUpdateInput;
};

export type MutationCreateManyIngredienceArgs = {
  data: Array<IngredienceCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneIngredienceArgs = {
  data: IngredienceCreateInput;
};

export type MutationDeleteManyIngredienceArgs = {
  where?: Maybe<IngredienceWhereInput>;
};

export type MutationDeleteOneIngredienceArgs = {
  where: IngredienceWhereUniqueInput;
};

export type MutationUpdateManyIngredienceArgs = {
  data: IngredienceUpdateManyMutationInput;
  where?: Maybe<IngredienceWhereInput>;
};

export type MutationUpdateOneIngredienceArgs = {
  data: IngredienceUpdateInput;
  where: IngredienceWhereUniqueInput;
};

export type MutationUpsertOneIngredienceArgs = {
  where: IngredienceWhereUniqueInput;
  create: IngredienceCreateInput;
  update: IngredienceUpdateInput;
};

export type MutationCreateManyInventoryArgs = {
  data: Array<InventoryCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneInventoryArgs = {
  data: InventoryCreateInput;
};

export type MutationDeleteManyInventoryArgs = {
  where?: Maybe<InventoryWhereInput>;
};

export type MutationDeleteOneInventoryArgs = {
  where: InventoryWhereUniqueInput;
};

export type MutationUpdateManyInventoryArgs = {
  data: InventoryUpdateManyMutationInput;
  where?: Maybe<InventoryWhereInput>;
};

export type MutationUpdateOneInventoryArgs = {
  data: InventoryUpdateInput;
  where: InventoryWhereUniqueInput;
};

export type MutationUpsertOneInventoryArgs = {
  where: InventoryWhereUniqueInput;
  create: InventoryCreateInput;
  update: InventoryUpdateInput;
};

export type MutationCreateManyInventoryItemArgs = {
  data: Array<InventoryItemCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneInventoryItemArgs = {
  data: InventoryItemCreateInput;
};

export type MutationDeleteManyInventoryItemArgs = {
  where?: Maybe<InventoryItemWhereInput>;
};

export type MutationDeleteOneInventoryItemArgs = {
  where: InventoryItemWhereUniqueInput;
};

export type MutationUpdateManyInventoryItemArgs = {
  data: InventoryItemUpdateManyMutationInput;
  where?: Maybe<InventoryItemWhereInput>;
};

export type MutationUpdateOneInventoryItemArgs = {
  data: InventoryItemUpdateInput;
  where: InventoryItemWhereUniqueInput;
};

export type MutationUpsertOneInventoryItemArgs = {
  where: InventoryItemWhereUniqueInput;
  create: InventoryItemCreateInput;
  update: InventoryItemUpdateInput;
};

export type MutationCreateManyOrderArgs = {
  data: Array<OrderCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};

export type MutationDeleteManyOrderArgs = {
  where?: Maybe<OrderWhereInput>;
};

export type MutationDeleteOneOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: Maybe<OrderWhereInput>;
};

export type MutationUpdateOneOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};

export type MutationUpsertOneOrderArgs = {
  where: OrderWhereUniqueInput;
  create: OrderCreateInput;
  update: OrderUpdateInput;
};

export type MutationCreateManyOrderItemArgs = {
  data: Array<OrderItemCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneOrderItemArgs = {
  data: OrderItemCreateInput;
};

export type MutationDeleteManyOrderItemArgs = {
  where?: Maybe<OrderItemWhereInput>;
};

export type MutationDeleteOneOrderItemArgs = {
  where: OrderItemWhereUniqueInput;
};

export type MutationUpdateManyOrderItemArgs = {
  data: OrderItemUpdateManyMutationInput;
  where?: Maybe<OrderItemWhereInput>;
};

export type MutationUpdateOneOrderItemArgs = {
  data: OrderItemUpdateInput;
  where: OrderItemWhereUniqueInput;
};

export type MutationUpsertOneOrderItemArgs = {
  where: OrderItemWhereUniqueInput;
  create: OrderItemCreateInput;
  update: OrderItemUpdateInput;
};

export type MutationCreateManyOrderItemExtraArgs = {
  data: Array<OrderItemExtraCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneOrderItemExtraArgs = {
  data: OrderItemExtraCreateInput;
};

export type MutationDeleteManyOrderItemExtraArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
};

export type MutationDeleteOneOrderItemExtraArgs = {
  where: OrderItemExtraWhereUniqueInput;
};

export type MutationUpdateManyOrderItemExtraArgs = {
  data: OrderItemExtraUpdateManyMutationInput;
  where?: Maybe<OrderItemExtraWhereInput>;
};

export type MutationUpdateOneOrderItemExtraArgs = {
  data: OrderItemExtraUpdateInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type MutationUpsertOneOrderItemExtraArgs = {
  where: OrderItemExtraWhereUniqueInput;
  create: OrderItemExtraCreateInput;
  update: OrderItemExtraUpdateInput;
};

export type MutationCreateManyPriceHistoryIngredienceArgs = {
  data: Array<PriceHistoryIngredienceCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOnePriceHistoryIngredienceArgs = {
  data: PriceHistoryIngredienceCreateInput;
};

export type MutationDeleteManyPriceHistoryIngredienceArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
};

export type MutationDeleteOnePriceHistoryIngredienceArgs = {
  where: PriceHistoryIngredienceWhereUniqueInput;
};

export type MutationUpdateManyPriceHistoryIngredienceArgs = {
  data: PriceHistoryIngredienceUpdateManyMutationInput;
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
};

export type MutationUpdateOnePriceHistoryIngredienceArgs = {
  data: PriceHistoryIngredienceUpdateInput;
  where: PriceHistoryIngredienceWhereUniqueInput;
};

export type MutationUpsertOnePriceHistoryIngredienceArgs = {
  where: PriceHistoryIngredienceWhereUniqueInput;
  create: PriceHistoryIngredienceCreateInput;
  update: PriceHistoryIngredienceUpdateInput;
};

export type MutationCreateManyProductArgs = {
  data: Array<ProductCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};

export type MutationDeleteManyProductArgs = {
  where?: Maybe<ProductWhereInput>;
};

export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};

export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: Maybe<ProductWhereInput>;
};

export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpsertOneProductArgs = {
  where: ProductWhereUniqueInput;
  create: ProductCreateInput;
  update: ProductUpdateInput;
};

export type MutationCreateManyProductIngredienceArgs = {
  data: Array<ProductIngredienceCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneProductIngredienceArgs = {
  data: ProductIngredienceCreateInput;
};

export type MutationDeleteManyProductIngredienceArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
};

export type MutationDeleteOneProductIngredienceArgs = {
  where: ProductIngredienceWhereUniqueInput;
};

export type MutationUpdateManyProductIngredienceArgs = {
  data: ProductIngredienceUpdateManyMutationInput;
  where?: Maybe<ProductIngredienceWhereInput>;
};

export type MutationUpdateOneProductIngredienceArgs = {
  data: ProductIngredienceUpdateInput;
  where: ProductIngredienceWhereUniqueInput;
};

export type MutationUpsertOneProductIngredienceArgs = {
  where: ProductIngredienceWhereUniqueInput;
  create: ProductIngredienceCreateInput;
  update: ProductIngredienceUpdateInput;
};

export type MutationCreateManyProductInventoryArgs = {
  data: Array<ProductInventoryCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneProductInventoryArgs = {
  data: ProductInventoryCreateInput;
};

export type MutationDeleteManyProductInventoryArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
};

export type MutationDeleteOneProductInventoryArgs = {
  where: ProductInventoryWhereUniqueInput;
};

export type MutationUpdateManyProductInventoryArgs = {
  data: ProductInventoryUpdateManyMutationInput;
  where?: Maybe<ProductInventoryWhereInput>;
};

export type MutationUpdateOneProductInventoryArgs = {
  data: ProductInventoryUpdateInput;
  where: ProductInventoryWhereUniqueInput;
};

export type MutationUpsertOneProductInventoryArgs = {
  where: ProductInventoryWhereUniqueInput;
  create: ProductInventoryCreateInput;
  update: ProductInventoryUpdateInput;
};

export type MutationCreateManyProductStockArgs = {
  data: Array<ProductStockCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneProductStockArgs = {
  data: ProductStockCreateInput;
};

export type MutationDeleteManyProductStockArgs = {
  where?: Maybe<ProductStockWhereInput>;
};

export type MutationDeleteOneProductStockArgs = {
  where: ProductStockWhereUniqueInput;
};

export type MutationUpdateManyProductStockArgs = {
  data: ProductStockUpdateManyMutationInput;
  where?: Maybe<ProductStockWhereInput>;
};

export type MutationUpdateOneProductStockArgs = {
  data: ProductStockUpdateInput;
  where: ProductStockWhereUniqueInput;
};

export type MutationUpsertOneProductStockArgs = {
  where: ProductStockWhereUniqueInput;
  create: ProductStockCreateInput;
  update: ProductStockUpdateInput;
};

export type MutationCreateManySingleExpenseArgs = {
  data: Array<SingleExpenseCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneSingleExpenseArgs = {
  data: SingleExpenseCreateInput;
};

export type MutationDeleteManySingleExpenseArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
};

export type MutationDeleteOneSingleExpenseArgs = {
  where: SingleExpenseWhereUniqueInput;
};

export type MutationUpdateManySingleExpenseArgs = {
  data: SingleExpenseUpdateManyMutationInput;
  where?: Maybe<SingleExpenseWhereInput>;
};

export type MutationUpdateOneSingleExpenseArgs = {
  data: SingleExpenseUpdateInput;
  where: SingleExpenseWhereUniqueInput;
};

export type MutationUpsertOneSingleExpenseArgs = {
  where: SingleExpenseWhereUniqueInput;
  create: SingleExpenseCreateInput;
  update: SingleExpenseUpdateInput;
};

export type MutationCreateManyTaxeArgs = {
  data: Array<TaxeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneTaxeArgs = {
  data: TaxeCreateInput;
};

export type MutationDeleteManyTaxeArgs = {
  where?: Maybe<TaxeWhereInput>;
};

export type MutationDeleteOneTaxeArgs = {
  where: TaxeWhereUniqueInput;
};

export type MutationUpdateManyTaxeArgs = {
  data: TaxeUpdateManyMutationInput;
  where?: Maybe<TaxeWhereInput>;
};

export type MutationUpdateOneTaxeArgs = {
  data: TaxeUpdateInput;
  where: TaxeWhereUniqueInput;
};

export type MutationUpsertOneTaxeArgs = {
  where: TaxeWhereUniqueInput;
  create: TaxeCreateInput;
  update: TaxeUpdateInput;
};

export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};

export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};

export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpsertOneUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedBoolFilter>;
  _max?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  notIn?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
};

export type NestedEnumOrderStatusWithAggregatesFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  notIn?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedEnumOrderStatusFilter>;
  _max?: Maybe<NestedEnumOrderStatusFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _avg?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _max?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _avg?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  userId: Scalars['String'];
  orderIncrement?: Maybe<Scalars['Float']>;
  _count?: Maybe<OrderCount>;
  user: User;
  orderItems: Array<OrderItem>;
};

export type OrderOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  sale?: Maybe<Scalars['Float']>;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderAvgOrderByAggregateInput = {
  sale?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
};

export type OrderCount = {
  __typename?: 'OrderCount';
  orderItems: Scalars['Int'];
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  id: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  orderDescription: Scalars['Int'];
  sale: Scalars['Int'];
  status: Scalars['Int'];
  userId: Scalars['Int'];
  orderIncrement: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrderCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderDescription?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
};

export type OrderCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  user: UserCreateNestedOneWithoutOrdersInput;
  orderIncrement?: Maybe<Scalars['Float']>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
};

export type OrderCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  userId: Scalars['String'];
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderCreateManyUserInputEnvelope = {
  data: Array<OrderCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<OrderCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<OrderCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedOneWithoutOrderItemsInput = {
  create?: Maybe<OrderCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutOrderItemsInput>;
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type OrderCreateOrConnectWithoutOrderItemsInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutOrderItemsInput;
};

export type OrderCreateOrConnectWithoutUserInput = {
  where: OrderWhereUniqueInput;
  create: OrderCreateWithoutUserInput;
};

export type OrderCreateWithoutOrderItemsInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  user: UserCreateNestedOneWithoutOrdersInput;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  orderIncrement?: Maybe<Scalars['Float']>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
};

export type OrderGroupBy = {
  __typename?: 'OrderGroupBy';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status: OrderStatus;
  userId: Scalars['String'];
  orderIncrement?: Maybe<Scalars['Float']>;
  _count?: Maybe<OrderCountAggregate>;
  _avg?: Maybe<OrderAvgAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['String'];
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  _count?: Maybe<OrderItemCount>;
  product?: Maybe<Product>;
  extra?: Maybe<Extra>;
  order?: Maybe<Order>;
  orderItemExtras: Array<OrderItemExtra>;
};

export type OrderItemOrderItemExtrasArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemExtraScalarFieldEnum>>;
};

export type OrderItemAvgAggregate = {
  __typename?: 'OrderItemAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Float']>;
};

export type OrderItemAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
};

export type OrderItemCount = {
  __typename?: 'OrderItemCount';
  orderItemExtras: Scalars['Int'];
};

export type OrderItemCountAggregate = {
  __typename?: 'OrderItemCountAggregate';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  price: Scalars['Int'];
  createdAt: Scalars['Int'];
  productId: Scalars['Int'];
  extraId: Scalars['Int'];
  description: Scalars['Int'];
  sale: Scalars['Int'];
  orderId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrderItemCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderId?: Maybe<SortOrder>;
};

export type OrderItemCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutOrderItemsInput>;
  extra?: Maybe<ExtraCreateNestedOneWithoutOrderItemsInput>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderCreateNestedOneWithoutOrderItemsInput>;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
};

export type OrderItemCreateManyExtraInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItemCreateManyExtraInputEnvelope = {
  data: Array<OrderItemCreateManyExtraInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItemCreateManyOrderInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
};

export type OrderItemCreateManyOrderInputEnvelope = {
  data: Array<OrderItemCreateManyOrderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItemCreateManyProductInputEnvelope = {
  data: Array<OrderItemCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateNestedManyWithoutExtraInput = {
  create?: Maybe<Array<OrderItemCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutExtraInput>>;
  createMany?: Maybe<OrderItemCreateManyExtraInputEnvelope>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
};

export type OrderItemCreateNestedManyWithoutOrderInput = {
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
};

export type OrderItemCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<OrderItemCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutProductInput>>;
  createMany?: Maybe<OrderItemCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
};

export type OrderItemCreateNestedOneWithoutOrderItemExtrasInput = {
  create?: Maybe<OrderItemCreateWithoutOrderItemExtrasInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutOrderItemExtrasInput>;
  connect?: Maybe<OrderItemWhereUniqueInput>;
};

export type OrderItemCreateOrConnectWithoutExtraInput = {
  where: OrderItemWhereUniqueInput;
  create: OrderItemCreateWithoutExtraInput;
};

export type OrderItemCreateOrConnectWithoutOrderInput = {
  where: OrderItemWhereUniqueInput;
  create: OrderItemCreateWithoutOrderInput;
};

export type OrderItemCreateOrConnectWithoutOrderItemExtrasInput = {
  where: OrderItemWhereUniqueInput;
  create: OrderItemCreateWithoutOrderItemExtrasInput;
};

export type OrderItemCreateOrConnectWithoutProductInput = {
  where: OrderItemWhereUniqueInput;
  create: OrderItemCreateWithoutProductInput;
};

export type OrderItemCreateWithoutExtraInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutOrderItemsInput>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderCreateNestedOneWithoutOrderItemsInput>;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
};

export type OrderItemCreateWithoutOrderInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutOrderItemsInput>;
  extra?: Maybe<ExtraCreateNestedOneWithoutOrderItemsInput>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
};

export type OrderItemCreateWithoutOrderItemExtrasInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutOrderItemsInput>;
  extra?: Maybe<ExtraCreateNestedOneWithoutOrderItemsInput>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderCreateNestedOneWithoutOrderItemsInput>;
};

export type OrderItemCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  extra?: Maybe<ExtraCreateNestedOneWithoutOrderItemsInput>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderCreateNestedOneWithoutOrderItemsInput>;
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
};

export type OrderItemExtra = {
  __typename?: 'OrderItemExtra';
  id: Scalars['String'];
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extraId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
  extra: Extra;
  orderItem?: Maybe<OrderItem>;
};

export type OrderItemExtraAvgAggregate = {
  __typename?: 'OrderItemExtraAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Float']>;
};

export type OrderItemExtraAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
};

export type OrderItemExtraCountAggregate = {
  __typename?: 'OrderItemExtraCountAggregate';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  price: Scalars['Int'];
  extraId: Scalars['Int'];
  createdAt: Scalars['Int'];
  sale: Scalars['Int'];
  orderItemId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type OrderItemExtraCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderItemId?: Maybe<SortOrder>;
};

export type OrderItemExtraCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extra: ExtraCreateNestedOneWithoutOrderItemExtrasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemCreateNestedOneWithoutOrderItemExtrasInput>;
};

export type OrderItemExtraCreateManyExtraInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
};

export type OrderItemExtraCreateManyExtraInputEnvelope = {
  data: Array<OrderItemExtraCreateManyExtraInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extraId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
};

export type OrderItemExtraCreateManyOrderItemInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extraId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
};

export type OrderItemExtraCreateManyOrderItemInputEnvelope = {
  data: Array<OrderItemExtraCreateManyOrderItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateNestedManyWithoutExtraInput = {
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<
    Array<OrderItemExtraCreateOrConnectWithoutExtraInput>
  >;
  createMany?: Maybe<OrderItemExtraCreateManyExtraInputEnvelope>;
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
};

export type OrderItemExtraCreateNestedManyWithoutOrderItemInput = {
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  connectOrCreate?: Maybe<
    Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>
  >;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
};

export type OrderItemExtraCreateOrConnectWithoutExtraInput = {
  where: OrderItemExtraWhereUniqueInput;
  create: OrderItemExtraCreateWithoutExtraInput;
};

export type OrderItemExtraCreateOrConnectWithoutOrderItemInput = {
  where: OrderItemExtraWhereUniqueInput;
  create: OrderItemExtraCreateWithoutOrderItemInput;
};

export type OrderItemExtraCreateWithoutExtraInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemCreateNestedOneWithoutOrderItemExtrasInput>;
};

export type OrderItemExtraCreateWithoutOrderItemInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extra: ExtraCreateNestedOneWithoutOrderItemExtrasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
};

export type OrderItemExtraGroupBy = {
  __typename?: 'OrderItemExtraGroupBy';
  id: Scalars['String'];
  amount: Scalars['Float'];
  price: Scalars['Float'];
  extraId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
  _count?: Maybe<OrderItemExtraCountAggregate>;
  _avg?: Maybe<OrderItemExtraAvgAggregate>;
  _sum?: Maybe<OrderItemExtraSumAggregate>;
  _min?: Maybe<OrderItemExtraMinAggregate>;
  _max?: Maybe<OrderItemExtraMaxAggregate>;
};

export type OrderItemExtraListRelationFilter = {
  every?: Maybe<OrderItemExtraWhereInput>;
  some?: Maybe<OrderItemExtraWhereInput>;
  none?: Maybe<OrderItemExtraWhereInput>;
};

export type OrderItemExtraMaxAggregate = {
  __typename?: 'OrderItemExtraMaxAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  extraId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
};

export type OrderItemExtraMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderItemId?: Maybe<SortOrder>;
};

export type OrderItemExtraMinAggregate = {
  __typename?: 'OrderItemExtraMinAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  extraId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sale?: Maybe<Scalars['Float']>;
  orderItemId?: Maybe<Scalars['String']>;
};

export type OrderItemExtraMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderItemId?: Maybe<SortOrder>;
};

export type OrderItemExtraOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type OrderItemExtraOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderItemId?: Maybe<SortOrder>;
  _count?: Maybe<OrderItemExtraCountOrderByAggregateInput>;
  _avg?: Maybe<OrderItemExtraAvgOrderByAggregateInput>;
  _max?: Maybe<OrderItemExtraMaxOrderByAggregateInput>;
  _min?: Maybe<OrderItemExtraMinOrderByAggregateInput>;
  _sum?: Maybe<OrderItemExtraSumOrderByAggregateInput>;
};

export type OrderItemExtraOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  extra?: Maybe<ExtraOrderByWithRelationInput>;
  extraId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderItem?: Maybe<OrderItemOrderByWithRelationInput>;
  orderItemId?: Maybe<SortOrder>;
};

export enum OrderItemExtraScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  Price = 'price',
  ExtraId = 'extraId',
  CreatedAt = 'createdAt',
  Sale = 'sale',
  OrderItemId = 'orderItemId',
}

export type OrderItemExtraScalarWhereInput = {
  AND?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  price?: Maybe<FloatFilter>;
  extraId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  sale?: Maybe<FloatNullableFilter>;
  orderItemId?: Maybe<StringNullableFilter>;
};

export type OrderItemExtraScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrderItemExtraScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrderItemExtraScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrderItemExtraScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  extraId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  sale?: Maybe<FloatNullableWithAggregatesFilter>;
  orderItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrderItemExtraSumAggregate = {
  __typename?: 'OrderItemExtraSumAggregate';
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Float']>;
};

export type OrderItemExtraSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
};

export type OrderItemExtraUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutOrderItemExtrasNestedInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneWithoutOrderItemExtrasNestedInput>;
};

export type OrderItemExtraUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpdateManyWithWhereWithoutExtraInput = {
  where: OrderItemExtraScalarWhereInput;
  data: OrderItemExtraUpdateManyMutationInput;
};

export type OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput = {
  where: OrderItemExtraScalarWhereInput;
  data: OrderItemExtraUpdateManyMutationInput;
};

export type OrderItemExtraUpdateManyWithoutExtraNestedInput = {
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<
    Array<OrderItemExtraCreateOrConnectWithoutExtraInput>
  >;
  upsert?: Maybe<Array<OrderItemExtraUpsertWithWhereUniqueWithoutExtraInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyExtraInputEnvelope>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemExtraUpdateWithWhereUniqueWithoutExtraInput>>;
  updateMany?: Maybe<Array<OrderItemExtraUpdateManyWithWhereWithoutExtraInput>>;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
};

export type OrderItemExtraUpdateManyWithoutOrderItemNestedInput = {
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  connectOrCreate?: Maybe<
    Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>
  >;
  upsert?: Maybe<
    Array<OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput>
  >;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<
    Array<OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput>
  >;
  updateMany?: Maybe<
    Array<OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput>
  >;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutExtraInput = {
  where: OrderItemExtraWhereUniqueInput;
  data: OrderItemExtraUpdateWithoutExtraInput;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput = {
  where: OrderItemExtraWhereUniqueInput;
  data: OrderItemExtraUpdateWithoutOrderItemInput;
};

export type OrderItemExtraUpdateWithoutExtraInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneWithoutOrderItemExtrasNestedInput>;
};

export type OrderItemExtraUpdateWithoutOrderItemInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutOrderItemExtrasNestedInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutExtraInput = {
  where: OrderItemExtraWhereUniqueInput;
  update: OrderItemExtraUpdateWithoutExtraInput;
  create: OrderItemExtraCreateWithoutExtraInput;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput = {
  where: OrderItemExtraWhereUniqueInput;
  update: OrderItemExtraUpdateWithoutOrderItemInput;
  create: OrderItemExtraCreateWithoutOrderItemInput;
};

export type OrderItemExtraWhereInput = {
  AND?: Maybe<Array<OrderItemExtraWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  price?: Maybe<FloatFilter>;
  extra?: Maybe<ExtraRelationFilter>;
  extraId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  sale?: Maybe<FloatNullableFilter>;
  orderItem?: Maybe<OrderItemRelationFilter>;
  orderItemId?: Maybe<StringNullableFilter>;
};

export type OrderItemExtraWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderItemGroupBy = {
  __typename?: 'OrderItemGroupBy';
  id: Scalars['String'];
  amount: Scalars['Float'];
  price: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  _count?: Maybe<OrderItemCountAggregate>;
  _avg?: Maybe<OrderItemAvgAggregate>;
  _sum?: Maybe<OrderItemSumAggregate>;
  _min?: Maybe<OrderItemMinAggregate>;
  _max?: Maybe<OrderItemMaxAggregate>;
};

export type OrderItemListRelationFilter = {
  every?: Maybe<OrderItemWhereInput>;
  some?: Maybe<OrderItemWhereInput>;
  none?: Maybe<OrderItemWhereInput>;
};

export type OrderItemMaxAggregate = {
  __typename?: 'OrderItemMaxAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItemMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderId?: Maybe<SortOrder>;
};

export type OrderItemMinAggregate = {
  __typename?: 'OrderItemMinAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
  extraId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
};

export type OrderItemMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderId?: Maybe<SortOrder>;
};

export type OrderItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type OrderItemOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  extraId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  orderId?: Maybe<SortOrder>;
  _count?: Maybe<OrderItemCountOrderByAggregateInput>;
  _avg?: Maybe<OrderItemAvgOrderByAggregateInput>;
  _max?: Maybe<OrderItemMaxOrderByAggregateInput>;
  _min?: Maybe<OrderItemMinOrderByAggregateInput>;
  _sum?: Maybe<OrderItemSumOrderByAggregateInput>;
};

export type OrderItemOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  extra?: Maybe<ExtraOrderByWithRelationInput>;
  extraId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  order?: Maybe<OrderOrderByWithRelationInput>;
  orderId?: Maybe<SortOrder>;
  orderItemExtras?: Maybe<OrderItemExtraOrderByRelationAggregateInput>;
};

export type OrderItemRelationFilter = {
  is?: Maybe<OrderItemWhereInput>;
  isNot?: Maybe<OrderItemWhereInput>;
};

export enum OrderItemScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  Price = 'price',
  CreatedAt = 'createdAt',
  ProductId = 'productId',
  ExtraId = 'extraId',
  Description = 'description',
  Sale = 'sale',
  OrderId = 'orderId',
}

export type OrderItemScalarWhereInput = {
  AND?: Maybe<Array<OrderItemScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  price?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  productId?: Maybe<StringNullableFilter>;
  extraId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  sale?: Maybe<IntNullableFilter>;
  orderId?: Maybe<StringNullableFilter>;
};

export type OrderItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrderItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrderItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrderItemScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  productId?: Maybe<StringNullableWithAggregatesFilter>;
  extraId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  sale?: Maybe<IntNullableWithAggregatesFilter>;
  orderId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type OrderItemSumAggregate = {
  __typename?: 'OrderItemSumAggregate';
  amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sale?: Maybe<Scalars['Int']>;
};

export type OrderItemSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
};

export type OrderItemUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutOrderItemsNestedInput>;
  extra?: Maybe<ExtraUpdateOneWithoutOrderItemsNestedInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutOrderItemsNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemNestedInput>;
};

export type OrderItemUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type OrderItemUpdateManyWithWhereWithoutExtraInput = {
  where: OrderItemScalarWhereInput;
  data: OrderItemUpdateManyMutationInput;
};

export type OrderItemUpdateManyWithWhereWithoutOrderInput = {
  where: OrderItemScalarWhereInput;
  data: OrderItemUpdateManyMutationInput;
};

export type OrderItemUpdateManyWithWhereWithoutProductInput = {
  where: OrderItemScalarWhereInput;
  data: OrderItemUpdateManyMutationInput;
};

export type OrderItemUpdateManyWithoutExtraNestedInput = {
  create?: Maybe<Array<OrderItemCreateWithoutExtraInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutExtraInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutExtraInput>>;
  createMany?: Maybe<OrderItemCreateManyExtraInputEnvelope>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutExtraInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutExtraInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
};

export type OrderItemUpdateManyWithoutOrderNestedInput = {
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutOrderInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
};

export type OrderItemUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<OrderItemCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutProductInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<OrderItemCreateManyProductInputEnvelope>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
};

export type OrderItemUpdateOneWithoutOrderItemExtrasNestedInput = {
  create?: Maybe<OrderItemCreateWithoutOrderItemExtrasInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutOrderItemExtrasInput>;
  upsert?: Maybe<OrderItemUpsertWithoutOrderItemExtrasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrderItemWhereUniqueInput>;
  update?: Maybe<OrderItemUpdateWithoutOrderItemExtrasInput>;
};

export type OrderItemUpdateWithWhereUniqueWithoutExtraInput = {
  where: OrderItemWhereUniqueInput;
  data: OrderItemUpdateWithoutExtraInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutOrderInput = {
  where: OrderItemWhereUniqueInput;
  data: OrderItemUpdateWithoutOrderInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutProductInput = {
  where: OrderItemWhereUniqueInput;
  data: OrderItemUpdateWithoutProductInput;
};

export type OrderItemUpdateWithoutExtraInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutOrderItemsNestedInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutOrderItemsNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemNestedInput>;
};

export type OrderItemUpdateWithoutOrderInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutOrderItemsNestedInput>;
  extra?: Maybe<ExtraUpdateOneWithoutOrderItemsNestedInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemNestedInput>;
};

export type OrderItemUpdateWithoutOrderItemExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutOrderItemsNestedInput>;
  extra?: Maybe<ExtraUpdateOneWithoutOrderItemsNestedInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutOrderItemsNestedInput>;
};

export type OrderItemUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneWithoutOrderItemsNestedInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableIntFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneWithoutOrderItemsNestedInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemNestedInput>;
};

export type OrderItemUpsertWithWhereUniqueWithoutExtraInput = {
  where: OrderItemWhereUniqueInput;
  update: OrderItemUpdateWithoutExtraInput;
  create: OrderItemCreateWithoutExtraInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutOrderInput = {
  where: OrderItemWhereUniqueInput;
  update: OrderItemUpdateWithoutOrderInput;
  create: OrderItemCreateWithoutOrderInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutProductInput = {
  where: OrderItemWhereUniqueInput;
  update: OrderItemUpdateWithoutProductInput;
  create: OrderItemCreateWithoutProductInput;
};

export type OrderItemUpsertWithoutOrderItemExtrasInput = {
  update: OrderItemUpdateWithoutOrderItemExtrasInput;
  create: OrderItemCreateWithoutOrderItemExtrasInput;
};

export type OrderItemWhereInput = {
  AND?: Maybe<Array<OrderItemWhereInput>>;
  OR?: Maybe<Array<OrderItemWhereInput>>;
  NOT?: Maybe<Array<OrderItemWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  price?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
  extra?: Maybe<ExtraRelationFilter>;
  extraId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  sale?: Maybe<IntNullableFilter>;
  order?: Maybe<OrderRelationFilter>;
  orderId?: Maybe<StringNullableFilter>;
  orderItemExtras?: Maybe<OrderItemExtraListRelationFilter>;
};

export type OrderItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status?: Maybe<OrderStatus>;
  userId?: Maybe<Scalars['String']>;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderDescription?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderDescription?: Maybe<Scalars['String']>;
  sale?: Maybe<Scalars['Float']>;
  status?: Maybe<OrderStatus>;
  userId?: Maybe<Scalars['String']>;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderDescription?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type OrderOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderDescription?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
  _count?: Maybe<OrderCountOrderByAggregateInput>;
  _avg?: Maybe<OrderAvgOrderByAggregateInput>;
  _max?: Maybe<OrderMaxOrderByAggregateInput>;
  _min?: Maybe<OrderMinOrderByAggregateInput>;
  _sum?: Maybe<OrderSumOrderByAggregateInput>;
};

export type OrderOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  orderDescription?: Maybe<SortOrder>;
  sale?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
  orderItems?: Maybe<OrderItemOrderByRelationAggregateInput>;
};

export type OrderRelationFilter = {
  is?: Maybe<OrderWhereInput>;
  isNot?: Maybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  OrderDescription = 'orderDescription',
  Sale = 'sale',
  Status = 'status',
  UserId = 'userId',
  OrderIncrement = 'orderIncrement',
}

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<OrderScalarWhereInput>>;
  OR?: Maybe<Array<OrderScalarWhereInput>>;
  NOT?: Maybe<Array<OrderScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  orderDescription?: Maybe<StringNullableFilter>;
  sale?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  userId?: Maybe<StringFilter>;
  orderIncrement?: Maybe<FloatNullableFilter>;
};

export type OrderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrderScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  orderDescription?: Maybe<StringNullableWithAggregatesFilter>;
  sale?: Maybe<FloatNullableWithAggregatesFilter>;
  status?: Maybe<EnumOrderStatusWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  orderIncrement?: Maybe<FloatNullableWithAggregatesFilter>;
};

export enum OrderStatus {
  Cash = 'CASH',
  Card = 'CARD',
  Created = 'CREATED',
  Canceled = 'CANCELED',
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  sale?: Maybe<Scalars['Float']>;
  orderIncrement?: Maybe<Scalars['Float']>;
};

export type OrderSumOrderByAggregateInput = {
  sale?: Maybe<SortOrder>;
  orderIncrement?: Maybe<SortOrder>;
};

export type OrderUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutOrdersNestedInput>;
  orderIncrement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutOrderNestedInput>;
};

export type OrderUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  orderIncrement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutUserInput = {
  where: OrderScalarWhereInput;
  data: OrderUpdateManyMutationInput;
};

export type OrderUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<OrderCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<OrderCreateManyUserInputEnvelope>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
};

export type OrderUpdateOneWithoutOrderItemsNestedInput = {
  create?: Maybe<OrderCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutOrderItemsInput>;
  upsert?: Maybe<OrderUpsertWithoutOrderItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrderWhereUniqueInput>;
  update?: Maybe<OrderUpdateWithoutOrderItemsInput>;
};

export type OrderUpdateWithWhereUniqueWithoutUserInput = {
  where: OrderWhereUniqueInput;
  data: OrderUpdateWithoutUserInput;
};

export type OrderUpdateWithoutOrderItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutOrdersNestedInput>;
  orderIncrement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  orderDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sale?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  orderIncrement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutOrderNestedInput>;
};

export type OrderUpsertWithWhereUniqueWithoutUserInput = {
  where: OrderWhereUniqueInput;
  update: OrderUpdateWithoutUserInput;
  create: OrderCreateWithoutUserInput;
};

export type OrderUpsertWithoutOrderItemsInput = {
  update: OrderUpdateWithoutOrderItemsInput;
  create: OrderCreateWithoutOrderItemsInput;
};

export type OrderWhereInput = {
  AND?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  orderDescription?: Maybe<StringNullableFilter>;
  sale?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  orderIncrement?: Maybe<FloatNullableFilter>;
  orderItems?: Maybe<OrderItemListRelationFilter>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PriceHistoryIngredience = {
  __typename?: 'PriceHistoryIngredience';
  id: Scalars['String'];
  price: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  ingredienceId?: Maybe<Scalars['String']>;
  ingredience?: Maybe<Ingredience>;
};

export type PriceHistoryIngredienceAvgAggregate = {
  __typename?: 'PriceHistoryIngredienceAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type PriceHistoryIngredienceAvgOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceCountAggregate = {
  __typename?: 'PriceHistoryIngredienceCountAggregate';
  id: Scalars['Int'];
  price: Scalars['Int'];
  createdAt: Scalars['Int'];
  ingredienceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PriceHistoryIngredienceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceCreateInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  ingredience?: Maybe<IngredienceCreateNestedOneWithoutPriceHistoryIngrediencesInput>;
};

export type PriceHistoryIngredienceCreateManyIngredienceInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PriceHistoryIngredienceCreateManyIngredienceInputEnvelope = {
  data: Array<PriceHistoryIngredienceCreateManyIngredienceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PriceHistoryIngredienceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  ingredienceId?: Maybe<Scalars['String']>;
};

export type PriceHistoryIngredienceCreateNestedManyWithoutIngredienceInput = {
  create?: Maybe<Array<PriceHistoryIngredienceCreateWithoutIngredienceInput>>;
  connectOrCreate?: Maybe<
    Array<PriceHistoryIngredienceCreateOrConnectWithoutIngredienceInput>
  >;
  createMany?: Maybe<PriceHistoryIngredienceCreateManyIngredienceInputEnvelope>;
  connect?: Maybe<Array<PriceHistoryIngredienceWhereUniqueInput>>;
};

export type PriceHistoryIngredienceCreateOrConnectWithoutIngredienceInput = {
  where: PriceHistoryIngredienceWhereUniqueInput;
  create: PriceHistoryIngredienceCreateWithoutIngredienceInput;
};

export type PriceHistoryIngredienceCreateWithoutIngredienceInput = {
  id?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PriceHistoryIngredienceGroupBy = {
  __typename?: 'PriceHistoryIngredienceGroupBy';
  id: Scalars['String'];
  price: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  ingredienceId?: Maybe<Scalars['String']>;
  _count?: Maybe<PriceHistoryIngredienceCountAggregate>;
  _avg?: Maybe<PriceHistoryIngredienceAvgAggregate>;
  _sum?: Maybe<PriceHistoryIngredienceSumAggregate>;
  _min?: Maybe<PriceHistoryIngredienceMinAggregate>;
  _max?: Maybe<PriceHistoryIngredienceMaxAggregate>;
};

export type PriceHistoryIngredienceListRelationFilter = {
  every?: Maybe<PriceHistoryIngredienceWhereInput>;
  some?: Maybe<PriceHistoryIngredienceWhereInput>;
  none?: Maybe<PriceHistoryIngredienceWhereInput>;
};

export type PriceHistoryIngredienceMaxAggregate = {
  __typename?: 'PriceHistoryIngredienceMaxAggregate';
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ingredienceId?: Maybe<Scalars['String']>;
};

export type PriceHistoryIngredienceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceMinAggregate = {
  __typename?: 'PriceHistoryIngredienceMinAggregate';
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ingredienceId?: Maybe<Scalars['String']>;
};

export type PriceHistoryIngredienceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
  _count?: Maybe<PriceHistoryIngredienceCountOrderByAggregateInput>;
  _avg?: Maybe<PriceHistoryIngredienceAvgOrderByAggregateInput>;
  _max?: Maybe<PriceHistoryIngredienceMaxOrderByAggregateInput>;
  _min?: Maybe<PriceHistoryIngredienceMinOrderByAggregateInput>;
  _sum?: Maybe<PriceHistoryIngredienceSumOrderByAggregateInput>;
};

export type PriceHistoryIngredienceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  ingredience?: Maybe<IngredienceOrderByWithRelationInput>;
  ingredienceId?: Maybe<SortOrder>;
};

export enum PriceHistoryIngredienceScalarFieldEnum {
  Id = 'id',
  Price = 'price',
  CreatedAt = 'createdAt',
  IngredienceId = 'ingredienceId',
}

export type PriceHistoryIngredienceScalarWhereInput = {
  AND?: Maybe<Array<PriceHistoryIngredienceScalarWhereInput>>;
  OR?: Maybe<Array<PriceHistoryIngredienceScalarWhereInput>>;
  NOT?: Maybe<Array<PriceHistoryIngredienceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  ingredienceId?: Maybe<StringNullableFilter>;
};

export type PriceHistoryIngredienceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PriceHistoryIngredienceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PriceHistoryIngredienceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PriceHistoryIngredienceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  ingredienceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type PriceHistoryIngredienceSumAggregate = {
  __typename?: 'PriceHistoryIngredienceSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type PriceHistoryIngredienceSumOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
};

export type PriceHistoryIngredienceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  ingredience?: Maybe<IngredienceUpdateOneWithoutPriceHistoryIngrediencesNestedInput>;
};

export type PriceHistoryIngredienceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PriceHistoryIngredienceUpdateManyWithWhereWithoutIngredienceInput =
  {
    where: PriceHistoryIngredienceScalarWhereInput;
    data: PriceHistoryIngredienceUpdateManyMutationInput;
  };

export type PriceHistoryIngredienceUpdateManyWithoutIngredienceNestedInput = {
  create?: Maybe<Array<PriceHistoryIngredienceCreateWithoutIngredienceInput>>;
  connectOrCreate?: Maybe<
    Array<PriceHistoryIngredienceCreateOrConnectWithoutIngredienceInput>
  >;
  upsert?: Maybe<
    Array<PriceHistoryIngredienceUpsertWithWhereUniqueWithoutIngredienceInput>
  >;
  createMany?: Maybe<PriceHistoryIngredienceCreateManyIngredienceInputEnvelope>;
  set?: Maybe<Array<PriceHistoryIngredienceWhereUniqueInput>>;
  disconnect?: Maybe<Array<PriceHistoryIngredienceWhereUniqueInput>>;
  delete?: Maybe<Array<PriceHistoryIngredienceWhereUniqueInput>>;
  connect?: Maybe<Array<PriceHistoryIngredienceWhereUniqueInput>>;
  update?: Maybe<
    Array<PriceHistoryIngredienceUpdateWithWhereUniqueWithoutIngredienceInput>
  >;
  updateMany?: Maybe<
    Array<PriceHistoryIngredienceUpdateManyWithWhereWithoutIngredienceInput>
  >;
  deleteMany?: Maybe<Array<PriceHistoryIngredienceScalarWhereInput>>;
};

export type PriceHistoryIngredienceUpdateWithWhereUniqueWithoutIngredienceInput =
  {
    where: PriceHistoryIngredienceWhereUniqueInput;
    data: PriceHistoryIngredienceUpdateWithoutIngredienceInput;
  };

export type PriceHistoryIngredienceUpdateWithoutIngredienceInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PriceHistoryIngredienceUpsertWithWhereUniqueWithoutIngredienceInput =
  {
    where: PriceHistoryIngredienceWhereUniqueInput;
    update: PriceHistoryIngredienceUpdateWithoutIngredienceInput;
    create: PriceHistoryIngredienceCreateWithoutIngredienceInput;
  };

export type PriceHistoryIngredienceWhereInput = {
  AND?: Maybe<Array<PriceHistoryIngredienceWhereInput>>;
  OR?: Maybe<Array<PriceHistoryIngredienceWhereInput>>;
  NOT?: Maybe<Array<PriceHistoryIngredienceWhereInput>>;
  id?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  ingredience?: Maybe<IngredienceRelationFilter>;
  ingredienceId?: Maybe<StringNullableFilter>;
};

export type PriceHistoryIngredienceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  userId: Scalars['String'];
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow: Scalars['Boolean'];
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock: Scalars['Boolean'];
  _count?: Maybe<ProductCount>;
  category: Category;
  user: User;
  taxe?: Maybe<Taxe>;
  bestSellers: Array<BestSeller>;
  extras: Array<Extra>;
  orderItems: Array<OrderItem>;
  productStocks: Array<ProductStock>;
  productIngrediences: Array<ProductIngredience>;
  productInventories: Array<ProductInventory>;
};

export type ProductBestSellersArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BestSellerScalarFieldEnum>>;
};

export type ProductExtrasArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type ProductOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type ProductProductStocksArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithRelationInput>>;
  cursor?: Maybe<ProductStockWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductStockScalarFieldEnum>>;
};

export type ProductProductIngrediencesArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductIngredienceScalarFieldEnum>>;
};

export type ProductProductInventoriesArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductInventoryScalarFieldEnum>>;
};

export type ProductAvgAggregate = {
  __typename?: 'ProductAvgAggregate';
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
};

export type ProductAvgOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
};

export type ProductCount = {
  __typename?: 'ProductCount';
  bestSellers: Scalars['Int'];
  extras: Scalars['Int'];
  orderItems: Scalars['Int'];
  productStocks: Scalars['Int'];
  productIngrediences: Scalars['Int'];
  productInventories: Scalars['Int'];
};

export type ProductCountAggregate = {
  __typename?: 'ProductCountAggregate';
  id: Scalars['Int'];
  name: Scalars['Int'];
  price: Scalars['Int'];
  orderIndex: Scalars['Int'];
  description: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  categoryId: Scalars['Int'];
  userId: Scalars['Int'];
  taxeId: Scalars['Int'];
  autoIncrementAllow: Scalars['Int'];
  autoOpenExtras: Scalars['Int'];
  stock: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProductCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  taxeId?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  autoOpenExtras?: Maybe<SortOrder>;
  stock?: Maybe<SortOrder>;
};

export type ProductCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateManyCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyCategoryInputEnvelope = {
  data: Array<ProductCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  userId: Scalars['String'];
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyTaxeInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  userId: Scalars['String'];
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyTaxeInputEnvelope = {
  data: Array<ProductCreateManyTaxeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyUserInputEnvelope = {
  data: Array<ProductCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<ProductCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutTaxeInput = {
  create?: Maybe<Array<ProductCreateWithoutTaxeInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutTaxeInput>>;
  createMany?: Maybe<ProductCreateManyTaxeInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ProductCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ProductCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateNestedOneWithoutBestSellersInput = {
  create?: Maybe<ProductCreateWithoutBestSellersInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutBestSellersInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutExtrasInput = {
  create?: Maybe<ProductCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutExtrasInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutOrderItemsInput = {
  create?: Maybe<ProductCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutOrderItemsInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutProductIngrediencesInput = {
  create?: Maybe<ProductCreateWithoutProductIngrediencesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductIngrediencesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutProductInventoriesInput = {
  create?: Maybe<ProductCreateWithoutProductInventoriesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductInventoriesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateNestedOneWithoutProductStocksInput = {
  create?: Maybe<ProductCreateWithoutProductStocksInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductStocksInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateOrConnectWithoutBestSellersInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutBestSellersInput;
};

export type ProductCreateOrConnectWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutCategoryInput;
};

export type ProductCreateOrConnectWithoutExtrasInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutExtrasInput;
};

export type ProductCreateOrConnectWithoutOrderItemsInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutOrderItemsInput;
};

export type ProductCreateOrConnectWithoutProductIngrediencesInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutProductIngrediencesInput;
};

export type ProductCreateOrConnectWithoutProductInventoriesInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutProductInventoriesInput;
};

export type ProductCreateOrConnectWithoutProductStocksInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutProductStocksInput;
};

export type ProductCreateOrConnectWithoutTaxeInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutTaxeInput;
};

export type ProductCreateOrConnectWithoutUserInput = {
  where: ProductWhereUniqueInput;
  create: ProductCreateWithoutUserInput;
};

export type ProductCreateWithoutBestSellersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutCategoryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutExtrasInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutOrderItemsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutProductIngrediencesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutProductInventoriesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutProductStocksInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutTaxeInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  user: UserCreateNestedOneWithoutProductsInput;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  category: CategoryCreateNestedOneWithoutProductsInput;
  taxe?: Maybe<TaxeCreateNestedOneWithoutProductsInput>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
  bestSellers?: Maybe<BestSellerCreateNestedManyWithoutProductInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutProductInput>;
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutProductInput>;
  productStocks?: Maybe<ProductStockCreateNestedManyWithoutProductInput>;
  productIngrediences?: Maybe<ProductIngredienceCreateNestedManyWithoutProductInput>;
  productInventories?: Maybe<ProductInventoryCreateNestedManyWithoutProductInput>;
};

export type ProductGroupBy = {
  __typename?: 'ProductGroupBy';
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  orderIndex: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId: Scalars['String'];
  userId: Scalars['String'];
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow: Scalars['Boolean'];
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock: Scalars['Boolean'];
  _count?: Maybe<ProductCountAggregate>;
  _avg?: Maybe<ProductAvgAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
};

export type ProductIngredience = {
  __typename?: 'ProductIngredience';
  id: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  ingredienceId: Scalars['String'];
  product: Product;
  ingredience: Ingredience;
};

export type ProductIngredienceAvgAggregate = {
  __typename?: 'ProductIngredienceAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type ProductIngredienceAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type ProductIngredienceCountAggregate = {
  __typename?: 'ProductIngredienceCountAggregate';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  productId: Scalars['Int'];
  ingredienceId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProductIngredienceCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type ProductIngredienceCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutProductIngrediencesInput;
  ingredience: IngredienceCreateNestedOneWithoutProductIngrediencesInput;
};

export type ProductIngredienceCreateManyIngredienceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
};

export type ProductIngredienceCreateManyIngredienceInputEnvelope = {
  data: Array<ProductIngredienceCreateManyIngredienceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductIngredienceCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  ingredienceId: Scalars['String'];
};

export type ProductIngredienceCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  ingredienceId: Scalars['String'];
};

export type ProductIngredienceCreateManyProductInputEnvelope = {
  data: Array<ProductIngredienceCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductIngredienceCreateNestedManyWithoutIngredienceInput = {
  create?: Maybe<Array<ProductIngredienceCreateWithoutIngredienceInput>>;
  connectOrCreate?: Maybe<
    Array<ProductIngredienceCreateOrConnectWithoutIngredienceInput>
  >;
  createMany?: Maybe<ProductIngredienceCreateManyIngredienceInputEnvelope>;
  connect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
};

export type ProductIngredienceCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ProductIngredienceCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductIngredienceCreateOrConnectWithoutProductInput>
  >;
  createMany?: Maybe<ProductIngredienceCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
};

export type ProductIngredienceCreateOrConnectWithoutIngredienceInput = {
  where: ProductIngredienceWhereUniqueInput;
  create: ProductIngredienceCreateWithoutIngredienceInput;
};

export type ProductIngredienceCreateOrConnectWithoutProductInput = {
  where: ProductIngredienceWhereUniqueInput;
  create: ProductIngredienceCreateWithoutProductInput;
};

export type ProductIngredienceCreateWithoutIngredienceInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutProductIngrediencesInput;
};

export type ProductIngredienceCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  ingredience: IngredienceCreateNestedOneWithoutProductIngrediencesInput;
};

export type ProductIngredienceGroupBy = {
  __typename?: 'ProductIngredienceGroupBy';
  id: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  ingredienceId: Scalars['String'];
  _count?: Maybe<ProductIngredienceCountAggregate>;
  _avg?: Maybe<ProductIngredienceAvgAggregate>;
  _sum?: Maybe<ProductIngredienceSumAggregate>;
  _min?: Maybe<ProductIngredienceMinAggregate>;
  _max?: Maybe<ProductIngredienceMaxAggregate>;
};

export type ProductIngredienceListRelationFilter = {
  every?: Maybe<ProductIngredienceWhereInput>;
  some?: Maybe<ProductIngredienceWhereInput>;
  none?: Maybe<ProductIngredienceWhereInput>;
};

export type ProductIngredienceMaxAggregate = {
  __typename?: 'ProductIngredienceMaxAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  ingredienceId?: Maybe<Scalars['String']>;
};

export type ProductIngredienceMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type ProductIngredienceMinAggregate = {
  __typename?: 'ProductIngredienceMinAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  ingredienceId?: Maybe<Scalars['String']>;
};

export type ProductIngredienceMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
};

export type ProductIngredienceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProductIngredienceOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  ingredienceId?: Maybe<SortOrder>;
  _count?: Maybe<ProductIngredienceCountOrderByAggregateInput>;
  _avg?: Maybe<ProductIngredienceAvgOrderByAggregateInput>;
  _max?: Maybe<ProductIngredienceMaxOrderByAggregateInput>;
  _min?: Maybe<ProductIngredienceMinOrderByAggregateInput>;
  _sum?: Maybe<ProductIngredienceSumOrderByAggregateInput>;
};

export type ProductIngredienceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  ingredience?: Maybe<IngredienceOrderByWithRelationInput>;
  ingredienceId?: Maybe<SortOrder>;
};

export enum ProductIngredienceScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  ProductId = 'productId',
  IngredienceId = 'ingredienceId',
}

export type ProductIngredienceScalarWhereInput = {
  AND?: Maybe<Array<ProductIngredienceScalarWhereInput>>;
  OR?: Maybe<Array<ProductIngredienceScalarWhereInput>>;
  NOT?: Maybe<Array<ProductIngredienceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatNullableFilter>;
  productId?: Maybe<StringFilter>;
  ingredienceId?: Maybe<StringFilter>;
};

export type ProductIngredienceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductIngredienceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductIngredienceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductIngredienceScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<FloatNullableWithAggregatesFilter>;
  productId?: Maybe<StringWithAggregatesFilter>;
  ingredienceId?: Maybe<StringWithAggregatesFilter>;
};

export type ProductIngredienceSumAggregate = {
  __typename?: 'ProductIngredienceSumAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type ProductIngredienceSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type ProductIngredienceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneRequiredWithoutProductIngrediencesNestedInput>;
  ingredience?: Maybe<IngredienceUpdateOneRequiredWithoutProductIngrediencesNestedInput>;
};

export type ProductIngredienceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type ProductIngredienceUpdateManyWithWhereWithoutIngredienceInput = {
  where: ProductIngredienceScalarWhereInput;
  data: ProductIngredienceUpdateManyMutationInput;
};

export type ProductIngredienceUpdateManyWithWhereWithoutProductInput = {
  where: ProductIngredienceScalarWhereInput;
  data: ProductIngredienceUpdateManyMutationInput;
};

export type ProductIngredienceUpdateManyWithoutIngredienceNestedInput = {
  create?: Maybe<Array<ProductIngredienceCreateWithoutIngredienceInput>>;
  connectOrCreate?: Maybe<
    Array<ProductIngredienceCreateOrConnectWithoutIngredienceInput>
  >;
  upsert?: Maybe<
    Array<ProductIngredienceUpsertWithWhereUniqueWithoutIngredienceInput>
  >;
  createMany?: Maybe<ProductIngredienceCreateManyIngredienceInputEnvelope>;
  set?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  delete?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  connect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  update?: Maybe<
    Array<ProductIngredienceUpdateWithWhereUniqueWithoutIngredienceInput>
  >;
  updateMany?: Maybe<
    Array<ProductIngredienceUpdateManyWithWhereWithoutIngredienceInput>
  >;
  deleteMany?: Maybe<Array<ProductIngredienceScalarWhereInput>>;
};

export type ProductIngredienceUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ProductIngredienceCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductIngredienceCreateOrConnectWithoutProductInput>
  >;
  upsert?: Maybe<
    Array<ProductIngredienceUpsertWithWhereUniqueWithoutProductInput>
  >;
  createMany?: Maybe<ProductIngredienceCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  delete?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  connect?: Maybe<Array<ProductIngredienceWhereUniqueInput>>;
  update?: Maybe<
    Array<ProductIngredienceUpdateWithWhereUniqueWithoutProductInput>
  >;
  updateMany?: Maybe<
    Array<ProductIngredienceUpdateManyWithWhereWithoutProductInput>
  >;
  deleteMany?: Maybe<Array<ProductIngredienceScalarWhereInput>>;
};

export type ProductIngredienceUpdateWithWhereUniqueWithoutIngredienceInput = {
  where: ProductIngredienceWhereUniqueInput;
  data: ProductIngredienceUpdateWithoutIngredienceInput;
};

export type ProductIngredienceUpdateWithWhereUniqueWithoutProductInput = {
  where: ProductIngredienceWhereUniqueInput;
  data: ProductIngredienceUpdateWithoutProductInput;
};

export type ProductIngredienceUpdateWithoutIngredienceInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneRequiredWithoutProductIngrediencesNestedInput>;
};

export type ProductIngredienceUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  ingredience?: Maybe<IngredienceUpdateOneRequiredWithoutProductIngrediencesNestedInput>;
};

export type ProductIngredienceUpsertWithWhereUniqueWithoutIngredienceInput = {
  where: ProductIngredienceWhereUniqueInput;
  update: ProductIngredienceUpdateWithoutIngredienceInput;
  create: ProductIngredienceCreateWithoutIngredienceInput;
};

export type ProductIngredienceUpsertWithWhereUniqueWithoutProductInput = {
  where: ProductIngredienceWhereUniqueInput;
  update: ProductIngredienceUpdateWithoutProductInput;
  create: ProductIngredienceCreateWithoutProductInput;
};

export type ProductIngredienceWhereInput = {
  AND?: Maybe<Array<ProductIngredienceWhereInput>>;
  OR?: Maybe<Array<ProductIngredienceWhereInput>>;
  NOT?: Maybe<Array<ProductIngredienceWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatNullableFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringFilter>;
  ingredience?: Maybe<IngredienceRelationFilter>;
  ingredienceId?: Maybe<StringFilter>;
};

export type ProductIngredienceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  id: Scalars['String'];
  productId: Scalars['String'];
  inventoryId: Scalars['String'];
  product: Product;
  inventory: Inventory;
};

export type ProductInventoryCountAggregate = {
  __typename?: 'ProductInventoryCountAggregate';
  id: Scalars['Int'];
  productId: Scalars['Int'];
  inventoryId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProductInventoryCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ProductInventoryCreateInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutProductInventoriesInput;
  inventory: InventoryCreateNestedOneWithoutProductInventoriesInput;
};

export type ProductInventoryCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  inventoryId: Scalars['String'];
};

export type ProductInventoryCreateManyInventoryInput = {
  id?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type ProductInventoryCreateManyInventoryInputEnvelope = {
  data: Array<ProductInventoryCreateManyInventoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductInventoryCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  inventoryId: Scalars['String'];
};

export type ProductInventoryCreateManyProductInputEnvelope = {
  data: Array<ProductInventoryCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductInventoryCreateNestedManyWithoutInventoryInput = {
  create?: Maybe<Array<ProductInventoryCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<ProductInventoryCreateOrConnectWithoutInventoryInput>
  >;
  createMany?: Maybe<ProductInventoryCreateManyInventoryInputEnvelope>;
  connect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
};

export type ProductInventoryCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ProductInventoryCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductInventoryCreateOrConnectWithoutProductInput>
  >;
  createMany?: Maybe<ProductInventoryCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
};

export type ProductInventoryCreateOrConnectWithoutInventoryInput = {
  where: ProductInventoryWhereUniqueInput;
  create: ProductInventoryCreateWithoutInventoryInput;
};

export type ProductInventoryCreateOrConnectWithoutProductInput = {
  where: ProductInventoryWhereUniqueInput;
  create: ProductInventoryCreateWithoutProductInput;
};

export type ProductInventoryCreateWithoutInventoryInput = {
  id?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutProductInventoriesInput;
};

export type ProductInventoryCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  inventory: InventoryCreateNestedOneWithoutProductInventoriesInput;
};

export type ProductInventoryGroupBy = {
  __typename?: 'ProductInventoryGroupBy';
  id: Scalars['String'];
  productId: Scalars['String'];
  inventoryId: Scalars['String'];
  _count?: Maybe<ProductInventoryCountAggregate>;
  _min?: Maybe<ProductInventoryMinAggregate>;
  _max?: Maybe<ProductInventoryMaxAggregate>;
};

export type ProductInventoryListRelationFilter = {
  every?: Maybe<ProductInventoryWhereInput>;
  some?: Maybe<ProductInventoryWhereInput>;
  none?: Maybe<ProductInventoryWhereInput>;
};

export type ProductInventoryMaxAggregate = {
  __typename?: 'ProductInventoryMaxAggregate';
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type ProductInventoryMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ProductInventoryMinAggregate = {
  __typename?: 'ProductInventoryMinAggregate';
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
};

export type ProductInventoryMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
};

export type ProductInventoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProductInventoryOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  inventoryId?: Maybe<SortOrder>;
  _count?: Maybe<ProductInventoryCountOrderByAggregateInput>;
  _max?: Maybe<ProductInventoryMaxOrderByAggregateInput>;
  _min?: Maybe<ProductInventoryMinOrderByAggregateInput>;
};

export type ProductInventoryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
  inventory?: Maybe<InventoryOrderByWithRelationInput>;
  inventoryId?: Maybe<SortOrder>;
};

export enum ProductInventoryScalarFieldEnum {
  Id = 'id',
  ProductId = 'productId',
  InventoryId = 'inventoryId',
}

export type ProductInventoryScalarWhereInput = {
  AND?: Maybe<Array<ProductInventoryScalarWhereInput>>;
  OR?: Maybe<Array<ProductInventoryScalarWhereInput>>;
  NOT?: Maybe<Array<ProductInventoryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  productId?: Maybe<StringFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type ProductInventoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductInventoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductInventoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductInventoryScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  productId?: Maybe<StringWithAggregatesFilter>;
  inventoryId?: Maybe<StringWithAggregatesFilter>;
};

export type ProductInventoryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneRequiredWithoutProductInventoriesNestedInput>;
  inventory?: Maybe<InventoryUpdateOneRequiredWithoutProductInventoriesNestedInput>;
};

export type ProductInventoryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProductInventoryUpdateManyWithWhereWithoutInventoryInput = {
  where: ProductInventoryScalarWhereInput;
  data: ProductInventoryUpdateManyMutationInput;
};

export type ProductInventoryUpdateManyWithWhereWithoutProductInput = {
  where: ProductInventoryScalarWhereInput;
  data: ProductInventoryUpdateManyMutationInput;
};

export type ProductInventoryUpdateManyWithoutInventoryNestedInput = {
  create?: Maybe<Array<ProductInventoryCreateWithoutInventoryInput>>;
  connectOrCreate?: Maybe<
    Array<ProductInventoryCreateOrConnectWithoutInventoryInput>
  >;
  upsert?: Maybe<
    Array<ProductInventoryUpsertWithWhereUniqueWithoutInventoryInput>
  >;
  createMany?: Maybe<ProductInventoryCreateManyInventoryInputEnvelope>;
  set?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  connect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  update?: Maybe<
    Array<ProductInventoryUpdateWithWhereUniqueWithoutInventoryInput>
  >;
  updateMany?: Maybe<
    Array<ProductInventoryUpdateManyWithWhereWithoutInventoryInput>
  >;
  deleteMany?: Maybe<Array<ProductInventoryScalarWhereInput>>;
};

export type ProductInventoryUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ProductInventoryCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductInventoryCreateOrConnectWithoutProductInput>
  >;
  upsert?: Maybe<
    Array<ProductInventoryUpsertWithWhereUniqueWithoutProductInput>
  >;
  createMany?: Maybe<ProductInventoryCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  connect?: Maybe<Array<ProductInventoryWhereUniqueInput>>;
  update?: Maybe<
    Array<ProductInventoryUpdateWithWhereUniqueWithoutProductInput>
  >;
  updateMany?: Maybe<
    Array<ProductInventoryUpdateManyWithWhereWithoutProductInput>
  >;
  deleteMany?: Maybe<Array<ProductInventoryScalarWhereInput>>;
};

export type ProductInventoryUpdateWithWhereUniqueWithoutInventoryInput = {
  where: ProductInventoryWhereUniqueInput;
  data: ProductInventoryUpdateWithoutInventoryInput;
};

export type ProductInventoryUpdateWithWhereUniqueWithoutProductInput = {
  where: ProductInventoryWhereUniqueInput;
  data: ProductInventoryUpdateWithoutProductInput;
};

export type ProductInventoryUpdateWithoutInventoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneRequiredWithoutProductInventoriesNestedInput>;
};

export type ProductInventoryUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inventory?: Maybe<InventoryUpdateOneRequiredWithoutProductInventoriesNestedInput>;
};

export type ProductInventoryUpsertWithWhereUniqueWithoutInventoryInput = {
  where: ProductInventoryWhereUniqueInput;
  update: ProductInventoryUpdateWithoutInventoryInput;
  create: ProductInventoryCreateWithoutInventoryInput;
};

export type ProductInventoryUpsertWithWhereUniqueWithoutProductInput = {
  where: ProductInventoryWhereUniqueInput;
  update: ProductInventoryUpdateWithoutProductInput;
  create: ProductInventoryCreateWithoutProductInput;
};

export type ProductInventoryWhereInput = {
  AND?: Maybe<Array<ProductInventoryWhereInput>>;
  OR?: Maybe<Array<ProductInventoryWhereInput>>;
  NOT?: Maybe<Array<ProductInventoryWhereInput>>;
  id?: Maybe<StringFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringFilter>;
  inventory?: Maybe<InventoryRelationFilter>;
  inventoryId?: Maybe<StringFilter>;
};

export type ProductInventoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
};

export type ProductMaxAggregate = {
  __typename?: 'ProductMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  taxeId?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  autoOpenExtras?: Maybe<SortOrder>;
  stock?: Maybe<SortOrder>;
};

export type ProductMinAggregate = {
  __typename?: 'ProductMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  categoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  taxeId?: Maybe<Scalars['String']>;
  autoIncrementAllow?: Maybe<Scalars['Boolean']>;
  autoOpenExtras?: Maybe<Scalars['Boolean']>;
  stock?: Maybe<Scalars['Boolean']>;
};

export type ProductMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  taxeId?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  autoOpenExtras?: Maybe<SortOrder>;
  stock?: Maybe<SortOrder>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProductOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  taxeId?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  autoOpenExtras?: Maybe<SortOrder>;
  stock?: Maybe<SortOrder>;
  _count?: Maybe<ProductCountOrderByAggregateInput>;
  _avg?: Maybe<ProductAvgOrderByAggregateInput>;
  _max?: Maybe<ProductMaxOrderByAggregateInput>;
  _min?: Maybe<ProductMinOrderByAggregateInput>;
  _sum?: Maybe<ProductSumOrderByAggregateInput>;
};

export type ProductOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  category?: Maybe<CategoryOrderByWithRelationInput>;
  categoryId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  taxe?: Maybe<TaxeOrderByWithRelationInput>;
  taxeId?: Maybe<SortOrder>;
  autoIncrementAllow?: Maybe<SortOrder>;
  autoOpenExtras?: Maybe<SortOrder>;
  stock?: Maybe<SortOrder>;
  bestSellers?: Maybe<BestSellerOrderByRelationAggregateInput>;
  extras?: Maybe<ExtraOrderByRelationAggregateInput>;
  orderItems?: Maybe<OrderItemOrderByRelationAggregateInput>;
  productStocks?: Maybe<ProductStockOrderByRelationAggregateInput>;
  productIngrediences?: Maybe<ProductIngredienceOrderByRelationAggregateInput>;
  productInventories?: Maybe<ProductInventoryOrderByRelationAggregateInput>;
};

export type ProductRelationFilter = {
  is?: Maybe<ProductWhereInput>;
  isNot?: Maybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Price = 'price',
  OrderIndex = 'orderIndex',
  Description = 'description',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  CategoryId = 'categoryId',
  UserId = 'userId',
  TaxeId = 'taxeId',
  AutoIncrementAllow = 'autoIncrementAllow',
  AutoOpenExtras = 'autoOpenExtras',
  Stock = 'stock',
}

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  orderIndex?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  categoryId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  taxeId?: Maybe<StringNullableFilter>;
  autoIncrementAllow?: Maybe<BoolFilter>;
  autoOpenExtras?: Maybe<BoolNullableFilter>;
  stock?: Maybe<BoolFilter>;
};

export type ProductScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  price?: Maybe<FloatWithAggregatesFilter>;
  orderIndex?: Maybe<FloatWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  categoryId?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
  taxeId?: Maybe<StringNullableWithAggregatesFilter>;
  autoIncrementAllow?: Maybe<BoolWithAggregatesFilter>;
  autoOpenExtras?: Maybe<BoolNullableWithAggregatesFilter>;
  stock?: Maybe<BoolWithAggregatesFilter>;
};

export type ProductStock = {
  __typename?: 'ProductStock';
  id: Scalars['String'];
  amount: Scalars['Float'];
  applyAt: Scalars['DateTime'];
  productId?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
};

export type ProductStockAvgAggregate = {
  __typename?: 'ProductStockAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type ProductStockAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type ProductStockCountAggregate = {
  __typename?: 'ProductStockCountAggregate';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  applyAt: Scalars['Int'];
  productId: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ProductStockCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applyAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type ProductStockCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  applyAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductCreateNestedOneWithoutProductStocksInput>;
};

export type ProductStockCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  applyAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
};

export type ProductStockCreateManyProductInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  applyAt?: Maybe<Scalars['DateTime']>;
};

export type ProductStockCreateManyProductInputEnvelope = {
  data: Array<ProductStockCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductStockCreateNestedManyWithoutProductInput = {
  create?: Maybe<Array<ProductStockCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductStockCreateOrConnectWithoutProductInput>
  >;
  createMany?: Maybe<ProductStockCreateManyProductInputEnvelope>;
  connect?: Maybe<Array<ProductStockWhereUniqueInput>>;
};

export type ProductStockCreateOrConnectWithoutProductInput = {
  where: ProductStockWhereUniqueInput;
  create: ProductStockCreateWithoutProductInput;
};

export type ProductStockCreateWithoutProductInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  applyAt?: Maybe<Scalars['DateTime']>;
};

export type ProductStockGroupBy = {
  __typename?: 'ProductStockGroupBy';
  id: Scalars['String'];
  amount: Scalars['Float'];
  applyAt: Scalars['DateTime'];
  productId?: Maybe<Scalars['String']>;
  _count?: Maybe<ProductStockCountAggregate>;
  _avg?: Maybe<ProductStockAvgAggregate>;
  _sum?: Maybe<ProductStockSumAggregate>;
  _min?: Maybe<ProductStockMinAggregate>;
  _max?: Maybe<ProductStockMaxAggregate>;
};

export type ProductStockListRelationFilter = {
  every?: Maybe<ProductStockWhereInput>;
  some?: Maybe<ProductStockWhereInput>;
  none?: Maybe<ProductStockWhereInput>;
};

export type ProductStockMaxAggregate = {
  __typename?: 'ProductStockMaxAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  applyAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
};

export type ProductStockMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applyAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type ProductStockMinAggregate = {
  __typename?: 'ProductStockMinAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  applyAt?: Maybe<Scalars['DateTime']>;
  productId?: Maybe<Scalars['String']>;
};

export type ProductStockMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applyAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
};

export type ProductStockOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProductStockOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applyAt?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  _count?: Maybe<ProductStockCountOrderByAggregateInput>;
  _avg?: Maybe<ProductStockAvgOrderByAggregateInput>;
  _max?: Maybe<ProductStockMaxOrderByAggregateInput>;
  _min?: Maybe<ProductStockMinOrderByAggregateInput>;
  _sum?: Maybe<ProductStockSumOrderByAggregateInput>;
};

export type ProductStockOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  applyAt?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationInput>;
  productId?: Maybe<SortOrder>;
};

export enum ProductStockScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  ApplyAt = 'applyAt',
  ProductId = 'productId',
}

export type ProductStockScalarWhereInput = {
  AND?: Maybe<Array<ProductStockScalarWhereInput>>;
  OR?: Maybe<Array<ProductStockScalarWhereInput>>;
  NOT?: Maybe<Array<ProductStockScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  applyAt?: Maybe<DateTimeFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type ProductStockScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductStockScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductStockScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductStockScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  applyAt?: Maybe<DateTimeWithAggregatesFilter>;
  productId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ProductStockSumAggregate = {
  __typename?: 'ProductStockSumAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type ProductStockSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type ProductStockUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  applyAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  product?: Maybe<ProductUpdateOneWithoutProductStocksNestedInput>;
};

export type ProductStockUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  applyAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductStockUpdateManyWithWhereWithoutProductInput = {
  where: ProductStockScalarWhereInput;
  data: ProductStockUpdateManyMutationInput;
};

export type ProductStockUpdateManyWithoutProductNestedInput = {
  create?: Maybe<Array<ProductStockCreateWithoutProductInput>>;
  connectOrCreate?: Maybe<
    Array<ProductStockCreateOrConnectWithoutProductInput>
  >;
  upsert?: Maybe<Array<ProductStockUpsertWithWhereUniqueWithoutProductInput>>;
  createMany?: Maybe<ProductStockCreateManyProductInputEnvelope>;
  set?: Maybe<Array<ProductStockWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductStockWhereUniqueInput>>;
  delete?: Maybe<Array<ProductStockWhereUniqueInput>>;
  connect?: Maybe<Array<ProductStockWhereUniqueInput>>;
  update?: Maybe<Array<ProductStockUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ProductStockUpdateManyWithWhereWithoutProductInput>>;
  deleteMany?: Maybe<Array<ProductStockScalarWhereInput>>;
};

export type ProductStockUpdateWithWhereUniqueWithoutProductInput = {
  where: ProductStockWhereUniqueInput;
  data: ProductStockUpdateWithoutProductInput;
};

export type ProductStockUpdateWithoutProductInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  applyAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductStockUpsertWithWhereUniqueWithoutProductInput = {
  where: ProductStockWhereUniqueInput;
  update: ProductStockUpdateWithoutProductInput;
  create: ProductStockCreateWithoutProductInput;
};

export type ProductStockWhereInput = {
  AND?: Maybe<Array<ProductStockWhereInput>>;
  OR?: Maybe<Array<ProductStockWhereInput>>;
  NOT?: Maybe<Array<ProductStockWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  applyAt?: Maybe<DateTimeFilter>;
  product?: Maybe<ProductRelationFilter>;
  productId?: Maybe<StringNullableFilter>;
};

export type ProductStockWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProductSumAggregate = {
  __typename?: 'ProductSumAggregate';
  price?: Maybe<Scalars['Float']>;
  orderIndex?: Maybe<Scalars['Float']>;
};

export type ProductSumOrderByAggregateInput = {
  price?: Maybe<SortOrder>;
  orderIndex?: Maybe<SortOrder>;
};

export type ProductUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutCategoryInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithWhereWithoutTaxeInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithWhereWithoutUserInput = {
  where: ProductScalarWhereInput;
  data: ProductUpdateManyMutationInput;
};

export type ProductUpdateManyWithoutCategoryNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<ProductCreateManyCategoryInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutTaxeNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutTaxeInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutTaxeInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutTaxeInput>>;
  createMany?: Maybe<ProductCreateManyTaxeInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutTaxeInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutTaxeInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ProductCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ProductCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductUpdateOneRequiredWithoutProductIngrediencesNestedInput = {
  create?: Maybe<ProductCreateWithoutProductIngrediencesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductIngrediencesInput>;
  upsert?: Maybe<ProductUpsertWithoutProductIngrediencesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutProductIngrediencesInput>;
};

export type ProductUpdateOneRequiredWithoutProductInventoriesNestedInput = {
  create?: Maybe<ProductCreateWithoutProductInventoriesInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductInventoriesInput>;
  upsert?: Maybe<ProductUpsertWithoutProductInventoriesInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutProductInventoriesInput>;
};

export type ProductUpdateOneWithoutBestSellersNestedInput = {
  create?: Maybe<ProductCreateWithoutBestSellersInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutBestSellersInput>;
  upsert?: Maybe<ProductUpsertWithoutBestSellersInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutBestSellersInput>;
};

export type ProductUpdateOneWithoutExtrasNestedInput = {
  create?: Maybe<ProductCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutExtrasInput>;
  upsert?: Maybe<ProductUpsertWithoutExtrasInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutExtrasInput>;
};

export type ProductUpdateOneWithoutOrderItemsNestedInput = {
  create?: Maybe<ProductCreateWithoutOrderItemsInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutOrderItemsInput>;
  upsert?: Maybe<ProductUpsertWithoutOrderItemsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutOrderItemsInput>;
};

export type ProductUpdateOneWithoutProductStocksNestedInput = {
  create?: Maybe<ProductCreateWithoutProductStocksInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutProductStocksInput>;
  upsert?: Maybe<ProductUpsertWithoutProductStocksInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProductWhereUniqueInput>;
  update?: Maybe<ProductUpdateWithoutProductStocksInput>;
};

export type ProductUpdateWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutCategoryInput;
};

export type ProductUpdateWithWhereUniqueWithoutTaxeInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutTaxeInput;
};

export type ProductUpdateWithWhereUniqueWithoutUserInput = {
  where: ProductWhereUniqueInput;
  data: ProductUpdateWithoutUserInput;
};

export type ProductUpdateWithoutBestSellersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutCategoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutOrderItemsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutProductIngrediencesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutProductInventoriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutProductStocksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutTaxeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  orderIndex?: Maybe<FloatFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  category?: Maybe<CategoryUpdateOneRequiredWithoutProductsNestedInput>;
  taxe?: Maybe<TaxeUpdateOneWithoutProductsNestedInput>;
  autoIncrementAllow?: Maybe<BoolFieldUpdateOperationsInput>;
  autoOpenExtras?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  stock?: Maybe<BoolFieldUpdateOperationsInput>;
  bestSellers?: Maybe<BestSellerUpdateManyWithoutProductNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutProductNestedInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutProductNestedInput>;
  productStocks?: Maybe<ProductStockUpdateManyWithoutProductNestedInput>;
  productIngrediences?: Maybe<ProductIngredienceUpdateManyWithoutProductNestedInput>;
  productInventories?: Maybe<ProductInventoryUpdateManyWithoutProductNestedInput>;
};

export type ProductUpsertWithWhereUniqueWithoutCategoryInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutCategoryInput;
  create: ProductCreateWithoutCategoryInput;
};

export type ProductUpsertWithWhereUniqueWithoutTaxeInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutTaxeInput;
  create: ProductCreateWithoutTaxeInput;
};

export type ProductUpsertWithWhereUniqueWithoutUserInput = {
  where: ProductWhereUniqueInput;
  update: ProductUpdateWithoutUserInput;
  create: ProductCreateWithoutUserInput;
};

export type ProductUpsertWithoutBestSellersInput = {
  update: ProductUpdateWithoutBestSellersInput;
  create: ProductCreateWithoutBestSellersInput;
};

export type ProductUpsertWithoutExtrasInput = {
  update: ProductUpdateWithoutExtrasInput;
  create: ProductCreateWithoutExtrasInput;
};

export type ProductUpsertWithoutOrderItemsInput = {
  update: ProductUpdateWithoutOrderItemsInput;
  create: ProductCreateWithoutOrderItemsInput;
};

export type ProductUpsertWithoutProductIngrediencesInput = {
  update: ProductUpdateWithoutProductIngrediencesInput;
  create: ProductCreateWithoutProductIngrediencesInput;
};

export type ProductUpsertWithoutProductInventoriesInput = {
  update: ProductUpdateWithoutProductInventoriesInput;
  create: ProductCreateWithoutProductInventoriesInput;
};

export type ProductUpsertWithoutProductStocksInput = {
  update: ProductUpdateWithoutProductStocksInput;
  create: ProductCreateWithoutProductStocksInput;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  orderIndex?: Maybe<FloatFilter>;
  description?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  category?: Maybe<CategoryRelationFilter>;
  categoryId?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
  taxe?: Maybe<TaxeRelationFilter>;
  taxeId?: Maybe<StringNullableFilter>;
  autoIncrementAllow?: Maybe<BoolFilter>;
  autoOpenExtras?: Maybe<BoolNullableFilter>;
  stock?: Maybe<BoolFilter>;
  bestSellers?: Maybe<BestSellerListRelationFilter>;
  extras?: Maybe<ExtraListRelationFilter>;
  orderItems?: Maybe<OrderItemListRelationFilter>;
  productStocks?: Maybe<ProductStockListRelationFilter>;
  productIngrediences?: Maybe<ProductIngredienceListRelationFilter>;
  productInventories?: Maybe<ProductInventoryListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateAttendance: AggregateAttendance;
  findFirstAttendance?: Maybe<Attendance>;
  findFirstAttendanceOrThrow?: Maybe<Attendance>;
  attendances: Array<Attendance>;
  attendance?: Maybe<Attendance>;
  getAttendance?: Maybe<Attendance>;
  groupByAttendance: Array<AttendanceGroupBy>;
  aggregateBestSeller: AggregateBestSeller;
  findFirstBestSeller?: Maybe<BestSeller>;
  findFirstBestSellerOrThrow?: Maybe<BestSeller>;
  bestSellers: Array<BestSeller>;
  bestSeller?: Maybe<BestSeller>;
  getBestSeller?: Maybe<BestSeller>;
  groupByBestSeller: Array<BestSellerGroupBy>;
  aggregateCashbox: AggregateCashbox;
  findFirstCashbox?: Maybe<Cashbox>;
  findFirstCashboxOrThrow?: Maybe<Cashbox>;
  cashboxes: Array<Cashbox>;
  cashbox?: Maybe<Cashbox>;
  getCashbox?: Maybe<Cashbox>;
  groupByCashbox: Array<CashboxGroupBy>;
  aggregateCashboxItem: AggregateCashboxItem;
  findFirstCashboxItem?: Maybe<CashboxItem>;
  findFirstCashboxItemOrThrow?: Maybe<CashboxItem>;
  cashboxItems: Array<CashboxItem>;
  cashboxItem?: Maybe<CashboxItem>;
  getCashboxItem?: Maybe<CashboxItem>;
  groupByCashboxItem: Array<CashboxItemGroupBy>;
  aggregateCategory: AggregateCategory;
  findFirstCategory?: Maybe<Category>;
  findFirstCategoryOrThrow?: Maybe<Category>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  getCategory?: Maybe<Category>;
  groupByCategory: Array<CategoryGroupBy>;
  aggregateExtra: AggregateExtra;
  findFirstExtra?: Maybe<Extra>;
  findFirstExtraOrThrow?: Maybe<Extra>;
  extras: Array<Extra>;
  extra?: Maybe<Extra>;
  getExtra?: Maybe<Extra>;
  groupByExtra: Array<ExtraGroupBy>;
  aggregateExtraInventory: AggregateExtraInventory;
  findFirstExtraInventory?: Maybe<ExtraInventory>;
  findFirstExtraInventoryOrThrow?: Maybe<ExtraInventory>;
  extraInventories: Array<ExtraInventory>;
  extraInventory?: Maybe<ExtraInventory>;
  getExtraInventory?: Maybe<ExtraInventory>;
  groupByExtraInventory: Array<ExtraInventoryGroupBy>;
  aggregateIngredience: AggregateIngredience;
  findFirstIngredience?: Maybe<Ingredience>;
  findFirstIngredienceOrThrow?: Maybe<Ingredience>;
  ingrediences: Array<Ingredience>;
  ingredience?: Maybe<Ingredience>;
  getIngredience?: Maybe<Ingredience>;
  groupByIngredience: Array<IngredienceGroupBy>;
  aggregateInventory: AggregateInventory;
  findFirstInventory?: Maybe<Inventory>;
  findFirstInventoryOrThrow?: Maybe<Inventory>;
  inventories: Array<Inventory>;
  inventory?: Maybe<Inventory>;
  getInventory?: Maybe<Inventory>;
  groupByInventory: Array<InventoryGroupBy>;
  aggregateInventoryItem: AggregateInventoryItem;
  findFirstInventoryItem?: Maybe<InventoryItem>;
  findFirstInventoryItemOrThrow?: Maybe<InventoryItem>;
  inventoryItems: Array<InventoryItem>;
  inventoryItem?: Maybe<InventoryItem>;
  getInventoryItem?: Maybe<InventoryItem>;
  groupByInventoryItem: Array<InventoryItemGroupBy>;
  aggregateOrder: AggregateOrder;
  findFirstOrder?: Maybe<Order>;
  findFirstOrderOrThrow?: Maybe<Order>;
  orders: Array<Order>;
  order?: Maybe<Order>;
  getOrder?: Maybe<Order>;
  groupByOrder: Array<OrderGroupBy>;
  aggregateOrderItem: AggregateOrderItem;
  findFirstOrderItem?: Maybe<OrderItem>;
  findFirstOrderItemOrThrow?: Maybe<OrderItem>;
  orderItems: Array<OrderItem>;
  orderItem?: Maybe<OrderItem>;
  getOrderItem?: Maybe<OrderItem>;
  groupByOrderItem: Array<OrderItemGroupBy>;
  aggregateOrderItemExtra: AggregateOrderItemExtra;
  findFirstOrderItemExtra?: Maybe<OrderItemExtra>;
  findFirstOrderItemExtraOrThrow?: Maybe<OrderItemExtra>;
  orderItemExtras: Array<OrderItemExtra>;
  orderItemExtra?: Maybe<OrderItemExtra>;
  getOrderItemExtra?: Maybe<OrderItemExtra>;
  groupByOrderItemExtra: Array<OrderItemExtraGroupBy>;
  aggregatePriceHistoryIngredience: AggregatePriceHistoryIngredience;
  findFirstPriceHistoryIngredience?: Maybe<PriceHistoryIngredience>;
  findFirstPriceHistoryIngredienceOrThrow?: Maybe<PriceHistoryIngredience>;
  priceHistoryIngrediences: Array<PriceHistoryIngredience>;
  priceHistoryIngredience?: Maybe<PriceHistoryIngredience>;
  getPriceHistoryIngredience?: Maybe<PriceHistoryIngredience>;
  groupByPriceHistoryIngredience: Array<PriceHistoryIngredienceGroupBy>;
  aggregateProduct: AggregateProduct;
  findFirstProduct?: Maybe<Product>;
  findFirstProductOrThrow?: Maybe<Product>;
  products: Array<Product>;
  product?: Maybe<Product>;
  getProduct?: Maybe<Product>;
  groupByProduct: Array<ProductGroupBy>;
  aggregateProductIngredience: AggregateProductIngredience;
  findFirstProductIngredience?: Maybe<ProductIngredience>;
  findFirstProductIngredienceOrThrow?: Maybe<ProductIngredience>;
  productIngrediences: Array<ProductIngredience>;
  productIngredience?: Maybe<ProductIngredience>;
  getProductIngredience?: Maybe<ProductIngredience>;
  groupByProductIngredience: Array<ProductIngredienceGroupBy>;
  aggregateProductInventory: AggregateProductInventory;
  findFirstProductInventory?: Maybe<ProductInventory>;
  findFirstProductInventoryOrThrow?: Maybe<ProductInventory>;
  productInventories: Array<ProductInventory>;
  productInventory?: Maybe<ProductInventory>;
  getProductInventory?: Maybe<ProductInventory>;
  groupByProductInventory: Array<ProductInventoryGroupBy>;
  aggregateProductStock: AggregateProductStock;
  findFirstProductStock?: Maybe<ProductStock>;
  findFirstProductStockOrThrow?: Maybe<ProductStock>;
  productStocks: Array<ProductStock>;
  productStock?: Maybe<ProductStock>;
  getProductStock?: Maybe<ProductStock>;
  groupByProductStock: Array<ProductStockGroupBy>;
  aggregateSingleExpense: AggregateSingleExpense;
  findFirstSingleExpense?: Maybe<SingleExpense>;
  findFirstSingleExpenseOrThrow?: Maybe<SingleExpense>;
  singleExpenses: Array<SingleExpense>;
  singleExpense?: Maybe<SingleExpense>;
  getSingleExpense?: Maybe<SingleExpense>;
  groupBySingleExpense: Array<SingleExpenseGroupBy>;
  aggregateTaxe: AggregateTaxe;
  findFirstTaxe?: Maybe<Taxe>;
  findFirstTaxeOrThrow?: Maybe<Taxe>;
  taxes: Array<Taxe>;
  taxe?: Maybe<Taxe>;
  getTaxe?: Maybe<Taxe>;
  groupByTaxe: Array<TaxeGroupBy>;
  aggregateUser: AggregateUser;
  findFirstUser?: Maybe<User>;
  findFirstUserOrThrow?: Maybe<User>;
  users: Array<User>;
  user?: Maybe<User>;
  getUser?: Maybe<User>;
  groupByUser: Array<UserGroupBy>;
  testQuery: Scalars['Boolean'];
};

export type QueryAggregateAttendanceArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithRelationInput>>;
  cursor?: Maybe<AttendanceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstAttendanceArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithRelationInput>>;
  cursor?: Maybe<AttendanceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AttendanceScalarFieldEnum>>;
};

export type QueryFindFirstAttendanceOrThrowArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithRelationInput>>;
  cursor?: Maybe<AttendanceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AttendanceScalarFieldEnum>>;
};

export type QueryAttendancesArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithRelationInput>>;
  cursor?: Maybe<AttendanceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AttendanceScalarFieldEnum>>;
};

export type QueryAttendanceArgs = {
  where: AttendanceWhereUniqueInput;
};

export type QueryGetAttendanceArgs = {
  where: AttendanceWhereUniqueInput;
};

export type QueryGroupByAttendanceArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithAggregationInput>>;
  by: Array<AttendanceScalarFieldEnum>;
  having?: Maybe<AttendanceScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateBestSellerArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstBestSellerArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BestSellerScalarFieldEnum>>;
};

export type QueryFindFirstBestSellerOrThrowArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BestSellerScalarFieldEnum>>;
};

export type QueryBestSellersArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithRelationInput>>;
  cursor?: Maybe<BestSellerWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BestSellerScalarFieldEnum>>;
};

export type QueryBestSellerArgs = {
  where: BestSellerWhereUniqueInput;
};

export type QueryGetBestSellerArgs = {
  where: BestSellerWhereUniqueInput;
};

export type QueryGroupByBestSellerArgs = {
  where?: Maybe<BestSellerWhereInput>;
  orderBy?: Maybe<Array<BestSellerOrderByWithAggregationInput>>;
  by: Array<BestSellerScalarFieldEnum>;
  having?: Maybe<BestSellerScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateCashboxArgs = {
  where?: Maybe<CashboxWhereInput>;
  orderBy?: Maybe<Array<CashboxOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstCashboxArgs = {
  where?: Maybe<CashboxWhereInput>;
  orderBy?: Maybe<Array<CashboxOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxScalarFieldEnum>>;
};

export type QueryFindFirstCashboxOrThrowArgs = {
  where?: Maybe<CashboxWhereInput>;
  orderBy?: Maybe<Array<CashboxOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxScalarFieldEnum>>;
};

export type QueryCashboxesArgs = {
  where?: Maybe<CashboxWhereInput>;
  orderBy?: Maybe<Array<CashboxOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxScalarFieldEnum>>;
};

export type QueryCashboxArgs = {
  where: CashboxWhereUniqueInput;
};

export type QueryGetCashboxArgs = {
  where: CashboxWhereUniqueInput;
};

export type QueryGroupByCashboxArgs = {
  where?: Maybe<CashboxWhereInput>;
  orderBy?: Maybe<Array<CashboxOrderByWithAggregationInput>>;
  by: Array<CashboxScalarFieldEnum>;
  having?: Maybe<CashboxScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateCashboxItemArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstCashboxItemArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxItemScalarFieldEnum>>;
};

export type QueryFindFirstCashboxItemOrThrowArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxItemScalarFieldEnum>>;
};

export type QueryCashboxItemsArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithRelationInput>>;
  cursor?: Maybe<CashboxItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CashboxItemScalarFieldEnum>>;
};

export type QueryCashboxItemArgs = {
  where: CashboxItemWhereUniqueInput;
};

export type QueryGetCashboxItemArgs = {
  where: CashboxItemWhereUniqueInput;
};

export type QueryGroupByCashboxItemArgs = {
  where?: Maybe<CashboxItemWhereInput>;
  orderBy?: Maybe<Array<CashboxItemOrderByWithAggregationInput>>;
  by: Array<CashboxItemScalarFieldEnum>;
  having?: Maybe<CashboxItemScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateCategoryArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstCategoryArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};

export type QueryFindFirstCategoryOrThrowArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};

export type QueryCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};

export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type QueryGetCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type QueryGroupByCategoryArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithAggregationInput>>;
  by: Array<CategoryScalarFieldEnum>;
  having?: Maybe<CategoryScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateExtraArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstExtraArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type QueryFindFirstExtraOrThrowArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type QueryExtrasArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type QueryExtraArgs = {
  where: ExtraWhereUniqueInput;
};

export type QueryGetExtraArgs = {
  where: ExtraWhereUniqueInput;
};

export type QueryGroupByExtraArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithAggregationInput>>;
  by: Array<ExtraScalarFieldEnum>;
  having?: Maybe<ExtraScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateExtraInventoryArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstExtraInventoryArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraInventoryScalarFieldEnum>>;
};

export type QueryFindFirstExtraInventoryOrThrowArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraInventoryScalarFieldEnum>>;
};

export type QueryExtraInventoriesArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraInventoryScalarFieldEnum>>;
};

export type QueryExtraInventoryArgs = {
  where: ExtraInventoryWhereUniqueInput;
};

export type QueryGetExtraInventoryArgs = {
  where: ExtraInventoryWhereUniqueInput;
};

export type QueryGroupByExtraInventoryArgs = {
  where?: Maybe<ExtraInventoryWhereInput>;
  orderBy?: Maybe<Array<ExtraInventoryOrderByWithAggregationInput>>;
  by: Array<ExtraInventoryScalarFieldEnum>;
  having?: Maybe<ExtraInventoryScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateIngredienceArgs = {
  where?: Maybe<IngredienceWhereInput>;
  orderBy?: Maybe<Array<IngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<IngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstIngredienceArgs = {
  where?: Maybe<IngredienceWhereInput>;
  orderBy?: Maybe<Array<IngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<IngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<IngredienceScalarFieldEnum>>;
};

export type QueryFindFirstIngredienceOrThrowArgs = {
  where?: Maybe<IngredienceWhereInput>;
  orderBy?: Maybe<Array<IngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<IngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<IngredienceScalarFieldEnum>>;
};

export type QueryIngrediencesArgs = {
  where?: Maybe<IngredienceWhereInput>;
  orderBy?: Maybe<Array<IngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<IngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<IngredienceScalarFieldEnum>>;
};

export type QueryIngredienceArgs = {
  where: IngredienceWhereUniqueInput;
};

export type QueryGetIngredienceArgs = {
  where: IngredienceWhereUniqueInput;
};

export type QueryGroupByIngredienceArgs = {
  where?: Maybe<IngredienceWhereInput>;
  orderBy?: Maybe<Array<IngredienceOrderByWithAggregationInput>>;
  by: Array<IngredienceScalarFieldEnum>;
  having?: Maybe<IngredienceScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateInventoryArgs = {
  where?: Maybe<InventoryWhereInput>;
  orderBy?: Maybe<Array<InventoryOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstInventoryArgs = {
  where?: Maybe<InventoryWhereInput>;
  orderBy?: Maybe<Array<InventoryOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryScalarFieldEnum>>;
};

export type QueryFindFirstInventoryOrThrowArgs = {
  where?: Maybe<InventoryWhereInput>;
  orderBy?: Maybe<Array<InventoryOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryScalarFieldEnum>>;
};

export type QueryInventoriesArgs = {
  where?: Maybe<InventoryWhereInput>;
  orderBy?: Maybe<Array<InventoryOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryScalarFieldEnum>>;
};

export type QueryInventoryArgs = {
  where: InventoryWhereUniqueInput;
};

export type QueryGetInventoryArgs = {
  where: InventoryWhereUniqueInput;
};

export type QueryGroupByInventoryArgs = {
  where?: Maybe<InventoryWhereInput>;
  orderBy?: Maybe<Array<InventoryOrderByWithAggregationInput>>;
  by: Array<InventoryScalarFieldEnum>;
  having?: Maybe<InventoryScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateInventoryItemArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstInventoryItemArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryItemScalarFieldEnum>>;
};

export type QueryFindFirstInventoryItemOrThrowArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryItemScalarFieldEnum>>;
};

export type QueryInventoryItemsArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithRelationInput>>;
  cursor?: Maybe<InventoryItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryItemScalarFieldEnum>>;
};

export type QueryInventoryItemArgs = {
  where: InventoryItemWhereUniqueInput;
};

export type QueryGetInventoryItemArgs = {
  where: InventoryItemWhereUniqueInput;
};

export type QueryGroupByInventoryItemArgs = {
  where?: Maybe<InventoryItemWhereInput>;
  orderBy?: Maybe<Array<InventoryItemOrderByWithAggregationInput>>;
  by: Array<InventoryItemScalarFieldEnum>;
  having?: Maybe<InventoryItemScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateOrderArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithRelationInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstOrderArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithRelationInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
};

export type QueryFindFirstOrderOrThrowArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithRelationInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
};

export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithRelationInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
};

export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryGetOrderArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryGroupByOrderArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithAggregationInput>>;
  by: Array<OrderScalarFieldEnum>;
  having?: Maybe<OrderScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateOrderItemArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstOrderItemArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type QueryFindFirstOrderItemOrThrowArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type QueryOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemScalarFieldEnum>>;
};

export type QueryOrderItemArgs = {
  where: OrderItemWhereUniqueInput;
};

export type QueryGetOrderItemArgs = {
  where: OrderItemWhereUniqueInput;
};

export type QueryGroupByOrderItemArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<Array<OrderItemOrderByWithAggregationInput>>;
  by: Array<OrderItemScalarFieldEnum>;
  having?: Maybe<OrderItemScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateOrderItemExtraArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstOrderItemExtraArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemExtraScalarFieldEnum>>;
};

export type QueryFindFirstOrderItemExtraOrThrowArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemExtraScalarFieldEnum>>;
};

export type QueryOrderItemExtrasArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithRelationInput>>;
  cursor?: Maybe<OrderItemExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderItemExtraScalarFieldEnum>>;
};

export type QueryOrderItemExtraArgs = {
  where: OrderItemExtraWhereUniqueInput;
};

export type QueryGetOrderItemExtraArgs = {
  where: OrderItemExtraWhereUniqueInput;
};

export type QueryGroupByOrderItemExtraArgs = {
  where?: Maybe<OrderItemExtraWhereInput>;
  orderBy?: Maybe<Array<OrderItemExtraOrderByWithAggregationInput>>;
  by: Array<OrderItemExtraScalarFieldEnum>;
  having?: Maybe<OrderItemExtraScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregatePriceHistoryIngredienceArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceHistoryIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstPriceHistoryIngredienceArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceHistoryIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PriceHistoryIngredienceScalarFieldEnum>>;
};

export type QueryFindFirstPriceHistoryIngredienceOrThrowArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceHistoryIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PriceHistoryIngredienceScalarFieldEnum>>;
};

export type QueryPriceHistoryIngrediencesArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<PriceHistoryIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PriceHistoryIngredienceScalarFieldEnum>>;
};

export type QueryPriceHistoryIngredienceArgs = {
  where: PriceHistoryIngredienceWhereUniqueInput;
};

export type QueryGetPriceHistoryIngredienceArgs = {
  where: PriceHistoryIngredienceWhereUniqueInput;
};

export type QueryGroupByPriceHistoryIngredienceArgs = {
  where?: Maybe<PriceHistoryIngredienceWhereInput>;
  orderBy?: Maybe<Array<PriceHistoryIngredienceOrderByWithAggregationInput>>;
  by: Array<PriceHistoryIngredienceScalarFieldEnum>;
  having?: Maybe<PriceHistoryIngredienceScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type QueryFindFirstProductOrThrowArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type QueryProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};

export type QueryGetProductArgs = {
  where: ProductWhereUniqueInput;
};

export type QueryGroupByProductArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithAggregationInput>>;
  by: Array<ProductScalarFieldEnum>;
  having?: Maybe<ProductScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateProductIngredienceArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstProductIngredienceArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductIngredienceScalarFieldEnum>>;
};

export type QueryFindFirstProductIngredienceOrThrowArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductIngredienceScalarFieldEnum>>;
};

export type QueryProductIngrediencesArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithRelationInput>>;
  cursor?: Maybe<ProductIngredienceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductIngredienceScalarFieldEnum>>;
};

export type QueryProductIngredienceArgs = {
  where: ProductIngredienceWhereUniqueInput;
};

export type QueryGetProductIngredienceArgs = {
  where: ProductIngredienceWhereUniqueInput;
};

export type QueryGroupByProductIngredienceArgs = {
  where?: Maybe<ProductIngredienceWhereInput>;
  orderBy?: Maybe<Array<ProductIngredienceOrderByWithAggregationInput>>;
  by: Array<ProductIngredienceScalarFieldEnum>;
  having?: Maybe<ProductIngredienceScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateProductInventoryArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstProductInventoryArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductInventoryScalarFieldEnum>>;
};

export type QueryFindFirstProductInventoryOrThrowArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductInventoryScalarFieldEnum>>;
};

export type QueryProductInventoriesArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithRelationInput>>;
  cursor?: Maybe<ProductInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductInventoryScalarFieldEnum>>;
};

export type QueryProductInventoryArgs = {
  where: ProductInventoryWhereUniqueInput;
};

export type QueryGetProductInventoryArgs = {
  where: ProductInventoryWhereUniqueInput;
};

export type QueryGroupByProductInventoryArgs = {
  where?: Maybe<ProductInventoryWhereInput>;
  orderBy?: Maybe<Array<ProductInventoryOrderByWithAggregationInput>>;
  by: Array<ProductInventoryScalarFieldEnum>;
  having?: Maybe<ProductInventoryScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateProductStockArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithRelationInput>>;
  cursor?: Maybe<ProductStockWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstProductStockArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithRelationInput>>;
  cursor?: Maybe<ProductStockWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductStockScalarFieldEnum>>;
};

export type QueryFindFirstProductStockOrThrowArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithRelationInput>>;
  cursor?: Maybe<ProductStockWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductStockScalarFieldEnum>>;
};

export type QueryProductStocksArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithRelationInput>>;
  cursor?: Maybe<ProductStockWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductStockScalarFieldEnum>>;
};

export type QueryProductStockArgs = {
  where: ProductStockWhereUniqueInput;
};

export type QueryGetProductStockArgs = {
  where: ProductStockWhereUniqueInput;
};

export type QueryGroupByProductStockArgs = {
  where?: Maybe<ProductStockWhereInput>;
  orderBy?: Maybe<Array<ProductStockOrderByWithAggregationInput>>;
  by: Array<ProductStockScalarFieldEnum>;
  having?: Maybe<ProductStockScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateSingleExpenseArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
  orderBy?: Maybe<Array<SingleExpenseOrderByWithRelationInput>>;
  cursor?: Maybe<SingleExpenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstSingleExpenseArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
  orderBy?: Maybe<Array<SingleExpenseOrderByWithRelationInput>>;
  cursor?: Maybe<SingleExpenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SingleExpenseScalarFieldEnum>>;
};

export type QueryFindFirstSingleExpenseOrThrowArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
  orderBy?: Maybe<Array<SingleExpenseOrderByWithRelationInput>>;
  cursor?: Maybe<SingleExpenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SingleExpenseScalarFieldEnum>>;
};

export type QuerySingleExpensesArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
  orderBy?: Maybe<Array<SingleExpenseOrderByWithRelationInput>>;
  cursor?: Maybe<SingleExpenseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SingleExpenseScalarFieldEnum>>;
};

export type QuerySingleExpenseArgs = {
  where: SingleExpenseWhereUniqueInput;
};

export type QueryGetSingleExpenseArgs = {
  where: SingleExpenseWhereUniqueInput;
};

export type QueryGroupBySingleExpenseArgs = {
  where?: Maybe<SingleExpenseWhereInput>;
  orderBy?: Maybe<Array<SingleExpenseOrderByWithAggregationInput>>;
  by: Array<SingleExpenseScalarFieldEnum>;
  having?: Maybe<SingleExpenseScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateTaxeArgs = {
  where?: Maybe<TaxeWhereInput>;
  orderBy?: Maybe<Array<TaxeOrderByWithRelationInput>>;
  cursor?: Maybe<TaxeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstTaxeArgs = {
  where?: Maybe<TaxeWhereInput>;
  orderBy?: Maybe<Array<TaxeOrderByWithRelationInput>>;
  cursor?: Maybe<TaxeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TaxeScalarFieldEnum>>;
};

export type QueryFindFirstTaxeOrThrowArgs = {
  where?: Maybe<TaxeWhereInput>;
  orderBy?: Maybe<Array<TaxeOrderByWithRelationInput>>;
  cursor?: Maybe<TaxeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TaxeScalarFieldEnum>>;
};

export type QueryTaxesArgs = {
  where?: Maybe<TaxeWhereInput>;
  orderBy?: Maybe<Array<TaxeOrderByWithRelationInput>>;
  cursor?: Maybe<TaxeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<TaxeScalarFieldEnum>>;
};

export type QueryTaxeArgs = {
  where: TaxeWhereUniqueInput;
};

export type QueryGetTaxeArgs = {
  where: TaxeWhereUniqueInput;
};

export type QueryGroupByTaxeArgs = {
  where?: Maybe<TaxeWhereInput>;
  orderBy?: Maybe<Array<TaxeOrderByWithAggregationInput>>;
  by: Array<TaxeScalarFieldEnum>;
  having?: Maybe<TaxeScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryAggregateUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type QueryFindFirstUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryFindFirstUserOrThrowArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryGroupByUserArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithAggregationInput>>;
  by: Array<UserScalarFieldEnum>;
  having?: Maybe<UserScalarWhereWithAggregatesInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type SingleExpense = {
  __typename?: 'SingleExpense';
  id: Scalars['String'];
  dateAt: Scalars['DateTime'];
  amount: Scalars['Float'];
};

export type SingleExpenseAvgAggregate = {
  __typename?: 'SingleExpenseAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type SingleExpenseAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type SingleExpenseCountAggregate = {
  __typename?: 'SingleExpenseCountAggregate';
  id: Scalars['Int'];
  dateAt: Scalars['Int'];
  amount: Scalars['Int'];
  _all: Scalars['Int'];
};

export type SingleExpenseCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type SingleExpenseCreateInput = {
  id?: Maybe<Scalars['String']>;
  dateAt: Scalars['DateTime'];
  amount: Scalars['Float'];
};

export type SingleExpenseCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  dateAt: Scalars['DateTime'];
  amount: Scalars['Float'];
};

export type SingleExpenseGroupBy = {
  __typename?: 'SingleExpenseGroupBy';
  id: Scalars['String'];
  dateAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  _count?: Maybe<SingleExpenseCountAggregate>;
  _avg?: Maybe<SingleExpenseAvgAggregate>;
  _sum?: Maybe<SingleExpenseSumAggregate>;
  _min?: Maybe<SingleExpenseMinAggregate>;
  _max?: Maybe<SingleExpenseMaxAggregate>;
};

export type SingleExpenseMaxAggregate = {
  __typename?: 'SingleExpenseMaxAggregate';
  id?: Maybe<Scalars['String']>;
  dateAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
};

export type SingleExpenseMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type SingleExpenseMinAggregate = {
  __typename?: 'SingleExpenseMinAggregate';
  id?: Maybe<Scalars['String']>;
  dateAt?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
};

export type SingleExpenseMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export type SingleExpenseOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  _count?: Maybe<SingleExpenseCountOrderByAggregateInput>;
  _avg?: Maybe<SingleExpenseAvgOrderByAggregateInput>;
  _max?: Maybe<SingleExpenseMaxOrderByAggregateInput>;
  _min?: Maybe<SingleExpenseMinOrderByAggregateInput>;
  _sum?: Maybe<SingleExpenseSumOrderByAggregateInput>;
};

export type SingleExpenseOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  dateAt?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
};

export enum SingleExpenseScalarFieldEnum {
  Id = 'id',
  DateAt = 'dateAt',
  Amount = 'amount',
}

export type SingleExpenseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SingleExpenseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SingleExpenseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SingleExpenseScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  dateAt?: Maybe<DateTimeWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
};

export type SingleExpenseSumAggregate = {
  __typename?: 'SingleExpenseSumAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type SingleExpenseSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type SingleExpenseUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  dateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type SingleExpenseUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  dateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type SingleExpenseWhereInput = {
  AND?: Maybe<Array<SingleExpenseWhereInput>>;
  OR?: Maybe<Array<SingleExpenseWhereInput>>;
  NOT?: Maybe<Array<SingleExpenseWhereInput>>;
  id?: Maybe<StringFilter>;
  dateAt?: Maybe<DateTimeFilter>;
  amount?: Maybe<FloatFilter>;
};

export type SingleExpenseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  _count?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedStringFilter>;
  _max?: Maybe<NestedStringFilter>;
};

export type Taxe = {
  __typename?: 'Taxe';
  id: Scalars['String'];
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  _count?: Maybe<TaxeCount>;
  products: Array<Product>;
};

export type TaxeProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type TaxeAvgAggregate = {
  __typename?: 'TaxeAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type TaxeAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type TaxeCount = {
  __typename?: 'TaxeCount';
  products: Scalars['Int'];
};

export type TaxeCountAggregate = {
  __typename?: 'TaxeCountAggregate';
  id: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TaxeCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type TaxeCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  products?: Maybe<ProductCreateNestedManyWithoutTaxeInput>;
};

export type TaxeCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TaxeCreateNestedOneWithoutProductsInput = {
  create?: Maybe<TaxeCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<TaxeCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<TaxeWhereUniqueInput>;
};

export type TaxeCreateOrConnectWithoutProductsInput = {
  where: TaxeWhereUniqueInput;
  create: TaxeCreateWithoutProductsInput;
};

export type TaxeCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TaxeGroupBy = {
  __typename?: 'TaxeGroupBy';
  id: Scalars['String'];
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  _count?: Maybe<TaxeCountAggregate>;
  _avg?: Maybe<TaxeAvgAggregate>;
  _sum?: Maybe<TaxeSumAggregate>;
  _min?: Maybe<TaxeMinAggregate>;
  _max?: Maybe<TaxeMaxAggregate>;
};

export type TaxeMaxAggregate = {
  __typename?: 'TaxeMaxAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TaxeMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type TaxeMinAggregate = {
  __typename?: 'TaxeMinAggregate';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TaxeMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type TaxeOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  _count?: Maybe<TaxeCountOrderByAggregateInput>;
  _avg?: Maybe<TaxeAvgOrderByAggregateInput>;
  _max?: Maybe<TaxeMaxOrderByAggregateInput>;
  _min?: Maybe<TaxeMinOrderByAggregateInput>;
  _sum?: Maybe<TaxeSumOrderByAggregateInput>;
};

export type TaxeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
};

export type TaxeRelationFilter = {
  is?: Maybe<TaxeWhereInput>;
  isNot?: Maybe<TaxeWhereInput>;
};

export enum TaxeScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  CreatedAt = 'createdAt',
}

export type TaxeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TaxeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TaxeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TaxeScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type TaxeSumAggregate = {
  __typename?: 'TaxeSumAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type TaxeSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type TaxeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutTaxeNestedInput>;
};

export type TaxeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaxeUpdateOneWithoutProductsNestedInput = {
  create?: Maybe<TaxeCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<TaxeCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<TaxeUpsertWithoutProductsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TaxeWhereUniqueInput>;
  update?: Maybe<TaxeUpdateWithoutProductsInput>;
};

export type TaxeUpdateWithoutProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaxeUpsertWithoutProductsInput = {
  update: TaxeUpdateWithoutProductsInput;
  create: TaxeCreateWithoutProductsInput;
};

export type TaxeWhereInput = {
  AND?: Maybe<Array<TaxeWhereInput>>;
  OR?: Maybe<Array<TaxeWhereInput>>;
  NOT?: Maybe<Array<TaxeWhereInput>>;
  id?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  products?: Maybe<ProductListRelationFilter>;
};

export type TaxeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  _count?: Maybe<UserCount>;
  products: Array<Product>;
  categories: Array<Category>;
  extras: Array<Extra>;
  orders: Array<Order>;
  Attendance: Array<Attendance>;
};

export type UserProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductOrderByWithRelationInput>>;
  cursor?: Maybe<ProductWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ProductScalarFieldEnum>>;
};

export type UserCategoriesArgs = {
  where?: Maybe<CategoryWhereInput>;
  orderBy?: Maybe<Array<CategoryOrderByWithRelationInput>>;
  cursor?: Maybe<CategoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<CategoryScalarFieldEnum>>;
};

export type UserExtrasArgs = {
  where?: Maybe<ExtraWhereInput>;
  orderBy?: Maybe<Array<ExtraOrderByWithRelationInput>>;
  cursor?: Maybe<ExtraWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ExtraScalarFieldEnum>>;
};

export type UserOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderOrderByWithRelationInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<OrderScalarFieldEnum>>;
};

export type UserAttendanceArgs = {
  where?: Maybe<AttendanceWhereInput>;
  orderBy?: Maybe<Array<AttendanceOrderByWithRelationInput>>;
  cursor?: Maybe<AttendanceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<AttendanceScalarFieldEnum>>;
};

export type UserCount = {
  __typename?: 'UserCount';
  products: Scalars['Int'];
  categories: Scalars['Int'];
  extras: Scalars['Int'];
  orders: Scalars['Int'];
  Attendance: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id: Scalars['Int'];
  password: Scalars['Int'];
  tokenSalt: Scalars['Int'];
  email: Scalars['Int'];
  isAdmin: Scalars['Int'];
  _all: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutUserInput>;
  categories?: Maybe<CategoryCreateNestedManyWithoutUserInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutUserInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutUserInput>;
  Attendance?: Maybe<AttendanceCreateNestedManyWithoutUserInput>;
};

export type UserCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedOneWithoutAttendanceInput = {
  create?: Maybe<UserCreateWithoutAttendanceInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAttendanceInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCategoriesInput = {
  create?: Maybe<UserCreateWithoutCategoriesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCategoriesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutExtrasInput = {
  create?: Maybe<UserCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutExtrasInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutOrdersInput = {
  create?: Maybe<UserCreateWithoutOrdersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutProductsInput = {
  create?: Maybe<UserCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutProductsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutAttendanceInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAttendanceInput;
};

export type UserCreateOrConnectWithoutCategoriesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCategoriesInput;
};

export type UserCreateOrConnectWithoutExtrasInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutExtrasInput;
};

export type UserCreateOrConnectWithoutOrdersInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrdersInput;
};

export type UserCreateOrConnectWithoutProductsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutProductsInput;
};

export type UserCreateWithoutAttendanceInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutUserInput>;
  categories?: Maybe<CategoryCreateNestedManyWithoutUserInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutUserInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCategoriesInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutUserInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutUserInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutUserInput>;
  Attendance?: Maybe<AttendanceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutExtrasInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutUserInput>;
  categories?: Maybe<CategoryCreateNestedManyWithoutUserInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutUserInput>;
  Attendance?: Maybe<AttendanceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrdersInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductCreateNestedManyWithoutUserInput>;
  categories?: Maybe<CategoryCreateNestedManyWithoutUserInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutUserInput>;
  Attendance?: Maybe<AttendanceCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutProductsInput = {
  id?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tokenSalt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<CategoryCreateNestedManyWithoutUserInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutUserInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutUserInput>;
  Attendance?: Maybe<AttendanceCreateNestedManyWithoutUserInput>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  id: Scalars['String'];
  password: Scalars['String'];
  tokenSalt: Scalars['String'];
  email: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  _count?: Maybe<UserCountAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _max?: Maybe<UserMaxAggregate>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tokenSalt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type UserMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  tokenSalt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type UserMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
};

export type UserOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  tokenSalt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  categories?: Maybe<CategoryOrderByRelationAggregateInput>;
  extras?: Maybe<ExtraOrderByRelationAggregateInput>;
  orders?: Maybe<OrderOrderByRelationAggregateInput>;
  Attendance?: Maybe<AttendanceOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Id = 'id',
  Password = 'password',
  TokenSalt = 'tokenSalt',
  Email = 'email',
  IsAdmin = 'isAdmin',
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  tokenSalt?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  isAdmin?: Maybe<BoolWithAggregatesFilter>;
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutUserNestedInput>;
  categories?: Maybe<CategoryUpdateManyWithoutUserNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutUserNestedInput>;
  orders?: Maybe<OrderUpdateManyWithoutUserNestedInput>;
  Attendance?: Maybe<AttendanceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAttendanceNestedInput = {
  create?: Maybe<UserCreateWithoutAttendanceInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutAttendanceInput>;
  upsert?: Maybe<UserUpsertWithoutAttendanceInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutAttendanceInput>;
};

export type UserUpdateOneRequiredWithoutCategoriesNestedInput = {
  create?: Maybe<UserCreateWithoutCategoriesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCategoriesInput>;
  upsert?: Maybe<UserUpsertWithoutCategoriesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutCategoriesInput>;
};

export type UserUpdateOneRequiredWithoutExtrasNestedInput = {
  create?: Maybe<UserCreateWithoutExtrasInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutExtrasInput>;
  upsert?: Maybe<UserUpsertWithoutExtrasInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutExtrasInput>;
};

export type UserUpdateOneRequiredWithoutOrdersNestedInput = {
  create?: Maybe<UserCreateWithoutOrdersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOrdersInput>;
  upsert?: Maybe<UserUpsertWithoutOrdersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutOrdersInput>;
};

export type UserUpdateOneRequiredWithoutProductsNestedInput = {
  create?: Maybe<UserCreateWithoutProductsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutProductsInput>;
  upsert?: Maybe<UserUpsertWithoutProductsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutProductsInput>;
};

export type UserUpdateWithoutAttendanceInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutUserNestedInput>;
  categories?: Maybe<CategoryUpdateManyWithoutUserNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutUserNestedInput>;
  orders?: Maybe<OrderUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCategoriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutUserNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutUserNestedInput>;
  orders?: Maybe<OrderUpdateManyWithoutUserNestedInput>;
  Attendance?: Maybe<AttendanceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutExtrasInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutUserNestedInput>;
  categories?: Maybe<CategoryUpdateManyWithoutUserNestedInput>;
  orders?: Maybe<OrderUpdateManyWithoutUserNestedInput>;
  Attendance?: Maybe<AttendanceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutOrdersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  products?: Maybe<ProductUpdateManyWithoutUserNestedInput>;
  categories?: Maybe<CategoryUpdateManyWithoutUserNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutUserNestedInput>;
  Attendance?: Maybe<AttendanceUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutProductsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  tokenSalt?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  isAdmin?: Maybe<BoolFieldUpdateOperationsInput>;
  categories?: Maybe<CategoryUpdateManyWithoutUserNestedInput>;
  extras?: Maybe<ExtraUpdateManyWithoutUserNestedInput>;
  orders?: Maybe<OrderUpdateManyWithoutUserNestedInput>;
  Attendance?: Maybe<AttendanceUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithoutAttendanceInput = {
  update: UserUpdateWithoutAttendanceInput;
  create: UserCreateWithoutAttendanceInput;
};

export type UserUpsertWithoutCategoriesInput = {
  update: UserUpdateWithoutCategoriesInput;
  create: UserCreateWithoutCategoriesInput;
};

export type UserUpsertWithoutExtrasInput = {
  update: UserUpdateWithoutExtrasInput;
  create: UserCreateWithoutExtrasInput;
};

export type UserUpsertWithoutOrdersInput = {
  update: UserUpdateWithoutOrdersInput;
  create: UserCreateWithoutOrdersInput;
};

export type UserUpsertWithoutProductsInput = {
  update: UserUpdateWithoutProductsInput;
  create: UserCreateWithoutProductsInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  tokenSalt?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  isAdmin?: Maybe<BoolFilter>;
  products?: Maybe<ProductListRelationFilter>;
  categories?: Maybe<CategoryListRelationFilter>;
  extras?: Maybe<ExtraListRelationFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  Attendance?: Maybe<AttendanceListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};
