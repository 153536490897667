import {
  TextFieldProps,
  useListContext,
  useRecordContext,
  useUpdate,
} from 'react-admin';

import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { findIndex, maxBy } from 'lodash';
import { VFC } from 'react';

const ReOrderButtons: VFC<TextFieldProps & { resource: string }> = (props) => {
  const record = useRecordContext();
  const { data, refetch, filterValues } = useListContext();
  const [update] = useUpdate();
  const maxOrderId = maxBy(data, 'orderIndex');

  const disabledOrders = !filterValues?.categoryId;

  const handleOrder = async (moveUp = true) => {
    const currentIndex = findIndex(data, (item) => item.id === record.id) + 1;

    if (moveUp) {
      const prevRecord = data[currentIndex - 2];

      // move current product up
      await update(props.resource, {
        id: record.id,
        data: {
          orderIndex: record.orderIndex - 1,
        },
      });

      await update(props.resource, {
        id: prevRecord.id,
        data: {
          orderIndex: record.orderIndex,
        },
      });
    }
    // move down
    else {
      const nextRecord = data[currentIndex];

      // move current product down
      await update(props.resource, {
        id: record.id,
        data: {
          orderIndex: record.orderIndex + 1,
        },
      });

      await update(props.resource, {
        id: nextRecord.id,
        data: {
          orderIndex: record.orderIndex,
        },
      });
    }

    setTimeout(() => {
      refetch();
    }, 200);
  };

  return (
    <ButtonGroup>
      <Tooltip title="Move up">
        <IconButton
          onClick={() => handleOrder(true)}
          disabled={record?.orderIndex <= 0 || disabledOrders}>
          <ArrowUpwardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Move down">
        <IconButton
          onClick={() => handleOrder(false)}
          disabled={record?.id === maxOrderId.id || disabledOrders}>
          <ArrowDownwardIcon />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ReOrderButtons;
