import { Card, CardContent, TextField } from '@mui/material';
import { Title, useGetIdentity } from 'react-admin';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { getTotalPrice } from './orders';
import { sortBy } from 'lodash';
import { useReportQueryLazyQuery } from '../@generated/hooks';

const ReportPage = () => {
  const { identity } = useGetIdentity();
  const [dateAtFrom, setDateAtFrom] = useState(null);
  const [dateAtTo, setDateAtTo] = useState(null);

  const [fetchReport, { data }] = useReportQueryLazyQuery();

  useEffect(() => {
    if (dateAtFrom && dateAtTo) {
      fetchReport({
        variables: {
          dateAt: {
            gte: dayjs(dateAtFrom).startOf('day').toDate(),
            lte: dayjs(dateAtTo).endOf('day').toDate(),
          },
        },
      });
    }
  }, [dateAtFrom, dateAtTo]);

  const ordersTotal = data?.orders?.reduce(
    (result, item) => result + getTotalPrice(item?.orderItems as any),
    0,
  ) || 0;

  const ordersExpensesTotal = data?.orders?.reduce(
    (result, item) =>
      result +
      item?.orderItems?.reduce(
        (resultOrderItems, orderItem) =>
          resultOrderItems +
          orderItem.product?.productIngrediences?.reduce(
            (resultProductIngrediences, productIngrediences) => {
              return resultProductIngrediences +
                sortBy(
                  productIngrediences?.ingredience?.priceHistoryIngrediences,
                  ['createdAt'],
                )?.[0]?.price *
                (productIngrediences?.amount || 0)
            },
            0,
          ) || 0,
        0,
      ),
    0,
  );

  const singleExpenses = data?.singleExpenses?.reduce(
    (result, item) => result + item.amount,
    0,
  ) || 0;

  return (
    <Card style={{ marginTop: 25 }}>
      <Title title="Report" />
      <CardContent>
        <TextField
          label="Date at from"
          type="date"
          value={dateAtFrom}
          onChange={(event) => setDateAtFrom(event.target.value)}
        />
        <TextField
          label="Date at to"
          type="date"
          value={dateAtTo}
          onChange={(event) => setDateAtTo(event.target.value)}
        />
        <br />
        <br />
        Objednávky: {ordersTotal}
        ,-
        <br />
        Náklady (za zboží): {Math.round(ordersExpensesTotal)}
        ,-
        <br />
        Jednorázové náklady: -{singleExpenses}
        ,-
        <br />
        Tips: {0}
        ,-
        <br />
        Čistý zisk: {Math.round(ordersTotal - ordersExpensesTotal - singleExpenses)}
        ,-
        <br />
      </CardContent>
    </Card>
  );
};

export default ReportPage;
