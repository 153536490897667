import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};

export const ReportQueryDocument = gql`
  query ReportQuery($dateAt: DateTimeFilter!) {
    orders(
      where: { createdAt: $dateAt, status: { notIn: [CREATED, CANCELED] } }
    ) {
      id
      createdAt
      orderDescription
      sale
      status
      user {
        email
      }
      orderIncrement
      orderItems {
        id
        amount
        price
        createdAt
        description
        sale
        product {
          id
          name
          price
          autoIncrementAllow
          autoOpenExtras
          extras {
            id
            name
            price
            orderIndex
          }
          productIngrediences {
            id
            amount
            ingredience {
              id
              name
              priceHistoryIngrediences {
                id
                price
                createdAt
              }
            }
          }
        }
        extra {
          id
          name
          price
          orderIndex
        }
        orderItemExtras {
          id
          amount
          price
          createdAt
          extra {
            id
            name
            price
            orderIndex
          }
          sale
        }
      }
    }
    singleExpenses(where: { dateAt: $dateAt }) {
      id
      dateAt
      amount
    }
    cashboxes(where: { dateAt: $dateAt }) {
      id
      dateAt
      cashboxItems {
        id
        kind
        amount
      }
    }
  }
`;

/**
 * __useReportQueryQuery__
 *
 * To run a query within a React component, call `useReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQueryQuery({
 *   variables: {
 *      dateAt: // value for 'dateAt'
 *   },
 * });
 */
export function useReportQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ReportQueryQuery,
    Types.ReportQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ReportQueryQuery,
    Types.ReportQueryQueryVariables
  >(ReportQueryDocument, options);
}
export function useReportQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ReportQueryQuery,
    Types.ReportQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ReportQueryQuery,
    Types.ReportQueryQueryVariables
  >(ReportQueryDocument, options);
}
export type ReportQueryQueryHookResult = ReturnType<typeof useReportQueryQuery>;
export type ReportQueryLazyQueryHookResult = ReturnType<
  typeof useReportQueryLazyQuery
>;
export type ReportQueryQueryResult = Apollo.QueryResult<
  Types.ReportQueryQuery,
  Types.ReportQueryQueryVariables
>;
export const TestMutationDocument = gql`
  mutation testMutation {
    testMutation
  }
`;
export type TestMutationMutationFn = Apollo.MutationFunction<
  Types.TestMutationMutation,
  Types.TestMutationMutationVariables
>;

/**
 * __useTestMutationMutation__
 *
 * To run a mutation, you first call `useTestMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testMutationMutation, { data, loading, error }] = useTestMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.TestMutationMutation,
    Types.TestMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.TestMutationMutation,
    Types.TestMutationMutationVariables
  >(TestMutationDocument, options);
}
export type TestMutationMutationHookResult = ReturnType<
  typeof useTestMutationMutation
>;
export type TestMutationMutationResult =
  Apollo.MutationResult<Types.TestMutationMutation>;
export type TestMutationMutationOptions = Apollo.BaseMutationOptions<
  Types.TestMutationMutation,
  Types.TestMutationMutationVariables
>;
export const TestQueryDocument = gql`
  query testQuery {
    testQuery
  }
`;

/**
 * __useTestQueryQuery__
 *
 * To run a query within a React component, call `useTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TestQueryQuery,
    Types.TestQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TestQueryQuery, Types.TestQueryQueryVariables>(
    TestQueryDocument,
    options,
  );
}
export function useTestQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TestQueryQuery,
    Types.TestQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TestQueryQuery,
    Types.TestQueryQueryVariables
  >(TestQueryDocument, options);
}
export type TestQueryQueryHookResult = ReturnType<typeof useTestQueryQuery>;
export type TestQueryLazyQueryHookResult = ReturnType<
  typeof useTestQueryLazyQuery
>;
export type TestQueryQueryResult = Apollo.QueryResult<
  Types.TestQueryQuery,
  Types.TestQueryQueryVariables
>;
