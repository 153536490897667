import {
  AutocompleteArrayInput,
  AutocompleteInput,
  AutocompleteInputClasses,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { merge, pick } from 'lodash';

import CustomPagination from '../CustomPagination';
import { Grid } from '@mui/material';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';

const redirect = () => 'Inventory';

const getTransformedData = (data) => {
  console.log('pred', data);
  data['@@ra-many/Inventory/InventoryItem/inventoryItems_ids'] = data[
    '@@ra-many/Inventory/InventoryItem/inventoryItems_ids'
  ].map((inventory_id) => {
    return {
      InventoryItem: inventory_id.InventoryItem.map((inventoryItem) =>
        merge(
          pick(inventoryItem, [
            'availableAmount',
            'id',
            'inventory_id',
            'price',
            'description',
            'createdAt',
          ]),
          { inventory_id: data.id },
        ),
      ),
    };
  });

  return data;
};

const InventoryForm = () => {
  const record = useRecordContext();

  return (
    <Grid container spacing={12}>
      <Grid item md={4}>
        <TextInput source="name" fullWidth />
        <br />
        <NumberInput source="minimumAmount" fullWidth />
        <br />
        <TextInput multiline rows={5} source="description" fullWidth />
        <br />
        <ReferenceManyInput
          reference="ProductInventory"
          target="productInventories_ids">
          <SimpleFormIterator inline>
            <ReferenceInput reference="Product" source="productInventories_ids">
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput
              source="inventory_id"
              defaultValue={record?.id}
              style={{ display: 'none' }}
            />
            <TextInput
              source="createdAt"
              defaultValue={dayjs().toDate()}
              style={{ display: 'none' }}
            />
          </SimpleFormIterator>
        </ReferenceManyInput>
      </Grid>
      <Grid item md={8}>
        Inventory items
        <ReferenceManyInput
          reference="InventoryItem"
          target="inventoryItems_ids">
          <SimpleFormIterator inline>
            <NumberInput source="availableAmount" />
            <NumberInput source="price" />
            <TextInput source="description" />
            <TextInput
              source="inventory_id"
              defaultValue={record?.id}
              style={{ display: 'none' }}
            />
            <TextInput
              source="createdAt"
              defaultValue={dayjs().toDate()}
              style={{ display: 'none' }}
            />
          </SimpleFormIterator>
        </ReferenceManyInput>
      </Grid>
    </Grid>
  );
};

export const InventoryCreate = (props: any) => {
  return (
    <Create
      {...props}
      mutationMode="optimistic"
      redirect={redirect}
      transform={getTransformedData}>
      <SimpleForm defaultValue={{ orderIndex: 0 }}>
        <InventoryForm />
      </SimpleForm>
    </Create>
  );
};

export const InventoryEdit = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      redirect={redirect}
      transform={getTransformedData}>
      <SimpleForm>
        <InventoryForm />
      </SimpleForm>
    </Edit>
  );
};

export const InventoryFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
    <NumberInput source="price" />
    <ReferenceInput reference="Category" source="categoryId">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const InventoryList = (props: any) => {
  return (
    <List
      {...props}
      filters={<InventoryFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <FunctionField
          source="amount"
          render={(record) =>
            `${record.inventoryItems.reduce((result, item) => {
              return result + item.availableAmount;
            }, 0)}`
          }
        />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment InventoryFragment on Inventory {
    inventoryItems {
      id
      availableAmount
      price
      description
      createdAt
    }
    productInventories {
      id
    }
  }
`;

export const Inventory: ResourceView = {
  resource: 'Inventory',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
