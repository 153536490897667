import { MenuItemLink, MenuProps } from 'react-admin';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MoneyIcon from '@mui/icons-material/Money';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SummarizeIcon from '@mui/icons-material/Summarize';

const CustomMenu = ({ dense = false }: MenuProps) => {
  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}>
      <MenuItemLink
        to="/User"
        primaryText="Users"
        leftIcon={<AssignmentIndIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/BestSeller"
        primaryText="Best sellers"
        leftIcon={<AccountTreeIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Category"
        primaryText="Category"
        leftIcon={<AccountTreeIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Product"
        primaryText="Product"
        leftIcon={<Inventory2Icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Inventory"
        primaryText="Inventory"
        leftIcon={<Inventory2Icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Extra"
        primaryText="Extra"
        leftIcon={<AddLinkIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Ingredience"
        primaryText="Ingredience"
        leftIcon={<GroupWorkIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Order"
        primaryText="Order"
        leftIcon={<ShoppingBasketIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Cashbox"
        primaryText="Cashbox"
        leftIcon={<MoneyIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Attendance"
        primaryText="Attendance"
        leftIcon={<CalendarTodayIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/SingleExpense"
        primaryText="Expenses"
        leftIcon={<ShoppingCartCheckoutIcon />}
        dense={dense}
      />
      <MenuItemLink
        to="/Report"
        primaryText="Report"
        leftIcon={<SummarizeIcon />}
        dense={dense}
      />
    </Box>
  );
};

export default CustomMenu;
