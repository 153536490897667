import czechMessages from 'ra-language-czech';
import englishMessages from 'ra-language-english';
import { merge } from 'lodash';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';

export default polyglotI18nProvider(
  (locale) =>
    locale === 'cs' ? merge(czechMessages, {}) : merge(englishMessages, {}),
  localStorage.getItem('locale') || resolveBrowserLocale(),
  { allowMissing: true, onMissingKey: (key: any) => key },
);
