import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragment = gql`
  fragment ProductIngredienceFragment on ProductIngredience {
    ingredience {
      id
      name
      priceHistoryIngrediences {
        id
        price
        createdAt
      }
    }
  }
`;

export const ProductIngredience: ResourceView = {
    resource: 'ProductIngredience',
    fragment: {
        one: {
            type: 'document',
            mode: 'extend',
            doc: fragment,
        },
        many: {
            type: 'document',
            mode: 'extend',
            doc: fragment,
        },
    },
};
