import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SimpleForm,
  useGetIdentity,
} from 'react-admin';

import CustomPagination from '../CustomPagination';

const redirect = () => 'SingleExpense';

const SingleExpenseForm = () => {
  return (
    <div>
      <DateInput source="dateAt" />
      <br />
      <NumberInput source="amount" defaultValue={0} />
    </div>
  );
};

export const SingleExpenseCreate = (props: any) => {
  const { identity } = useGetIdentity();
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <SingleExpenseForm />
      </SimpleForm>
    </Create>
  );
};

export const SingleExpenseEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <SingleExpenseForm />
      </SimpleForm>
    </Edit>
  );
};

export const SingleExpenseFilter = (props: any) => (
  <Filter {...props}>
    <DateInput source="dateAt" alwaysOn />
  </Filter>
);

export const SingleExpenseList = (props: any) => {
  return (
    <List
      {...props}
      filters={<SingleExpenseFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <DateField source="dateAt" />
        <FunctionField source="amount" render={(row) => `${row.amount},-`} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
