import {
  BooleanInput,
  DateField,
  Edit,
  FunctionField,
  Labeled,
  Link,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Order, OrderItem, OrderStatus } from 'src/@generated/schemas';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import dayjs from 'dayjs';
import styled from 'styled-components';

const OrderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm style={{ width: '100%' }}>
      <OrderForm />
    </SimpleForm>
  </Edit>
);

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const ItemPrice = ({
  sale,
  amount,
  price,
}: {
  sale: number;
  amount: number;
  price: number;
}) => {
  return (
    <div>
      {(price * amount).toLocaleString(undefined, {
        style: 'currency',
        currency: 'CZK',
      })}
      {' => '}
      {sale &&
        ((sale ? price * ((100 - sale) / 100) : price) * amount).toLocaleString(
          undefined,
          {
            style: 'currency',
            currency: 'CZK',
          },
        )}
      {sale ? ` (${sale}% sleva)` : null}
    </div>
  );
};

const totalOrderItem = (orderItem: OrderItem) => {
  let totalPrice = 0;
  totalPrice +=
    orderItem.amount *
    (orderItem.sale
      ? orderItem.price * (orderItem.sale / 100)
      : orderItem.price);

  orderItem.orderItemExtras?.forEach((orderItemExtra) => {
    totalPrice +=
      orderItemExtra.amount *
      (orderItem.sale
        ? orderItemExtra.price * (orderItem.sale / 100)
        : orderItemExtra.price);
  });
  return totalPrice;
};

const totalOrder = (order: Order) => {
  let totalPrice = 0;
  order.orderItems?.forEach((orderItem) => {
    totalPrice += totalOrderItem(orderItem);
  });

  return totalPrice;
};

const OrderForm = () => {
  const record = useRecordContext<Order>();

  return (
    <Card style={{ width: '50%' }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Objednávka
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled source="date">
                  <FunctionField
                    source="createdAt"
                    render={(record) =>
                      dayjs(record.createdAt).format('DD.MM. YYYY HH:mm')
                    }
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Labeled source="id">
                  <TextField source="id" />
                </Labeled>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <SelectInput
                  source="status"
                  choices={Object.values(OrderStatus).map((status) => ({
                    id: status,
                    name: status,
                  }))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Spacer />

        <Typography variant="h6" gutterBottom>
          Položky
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Produkt/extra</TableCell>
              <TableCellRight style={{ textAlign: 'right' }}>
                Množství
              </TableCellRight>
              <TableCellRight style={{ textAlign: 'right' }}>
                Celkem
              </TableCellRight>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.orderItems.map((orderItem: any) => (
              <>
                <TableRow key={orderItem.id}>
                  <TableCell>
                    <Link to={`/Product/${orderItem.product?.id}`}>
                      {orderItem.product?.name}
                    </Link>
                  </TableCell>
                  <TableCellRight style={{ textAlign: 'right' }}>
                    {orderItem.amount}
                  </TableCellRight>
                  <TableCellRight style={{ textAlign: 'right' }}>
                    <ItemPrice
                      amount={orderItem.amount}
                      price={orderItem.price}
                      sale={orderItem.sale}
                    />
                  </TableCellRight>
                </TableRow>
                {orderItem.orderItemExtras.map((orderItemExtra) => (
                  <TableRow key={orderItemExtra.id}>
                    <TableCell style={{ paddingLeft: 40 }}>
                      <SubdirectoryArrowRightIcon
                        style={{ width: 16, height: 16 }}
                      />
                      <Link to={`/Product/${orderItemExtra.extra.id}`}>
                        {orderItemExtra.extra.name}
                      </Link>
                    </TableCell>
                    <TableCellRight style={{ textAlign: 'right' }}>
                      {orderItemExtra.amount}
                    </TableCellRight>
                    <TableCellRight style={{ textAlign: 'right' }}>
                      <ItemPrice
                        amount={orderItemExtra.amount}
                        price={orderItemExtra.price}
                        sale={orderItem.sale}
                      />
                    </TableCellRight>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
        <Spacer />

        <div>
          <Table sx={{ width: '100%' }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Celkem</TableCell>
                <TableCellRight sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {totalOrder(record)},-
                </TableCellRight>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <Toolbar />
    </Card>
  );
};

export default OrderEdit;

const TableCellRight = styled(TableCell)({ textAlign: 'right' });
