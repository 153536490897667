import {
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
} from 'react-admin';

import CustomPagination from '../CustomPagination';

const redirect = () => 'Extra';

const ExtraForm = () => {
  const { identity } = useGetIdentity();

  return (
    <div>
      <TextInput source="name" style={{ width: 250 }} />
      <br />
      <NumberInput source="price" style={{ width: 250 }} />
      <br />
      <TextInput source="description" style={{ width: 250 }} />
      <br />
      <NumberInput source="orderIndex" style={{ width: 250 }} />
      <br />
      <ReferenceInput
        reference="Product"
        source="product_id"
        allowEmpty
        style={{ width: 250 }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <br />
      <ReferenceInput
        reference="Category"
        source="category_id"
        allowEmpty
        style={{ width: 250 }}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <br />
      <ReferenceInput
        reference="User"
        source="user_id"
        style={{ width: 250, display: 'none' }}
        defaultValue={identity?.id}>
        <SelectInput optionText="email" defaultValue={identity?.id} />
      </ReferenceInput>
      <br />
      <NumberInput source="orderIndex" />
    </div>
  );
};

export const ExtraCreate = (props: any) => {
  return (
    <Create {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm defaultValue={{ orderIndex: 0 }}>
        <ExtraForm />
      </SimpleForm>
    </Create>
  );
};

export const ExtraEdit = (props: any) => {
  return (
    <Edit {...props} mutationMode="optimistic" redirect={redirect}>
      <SimpleForm>
        <ExtraForm />
      </SimpleForm>
    </Edit>
  );
};

export const ExtraFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
    <NumberInput source="price" />
    <ReferenceInput reference="Product" source="productId">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput reference="Category" source="categoryId">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ExtraList = (props: any) => {
  return (
    <List
      {...props}
      filters={<ExtraFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="orderIndex" />
        <FunctionField
          source="price"
          render={(record) => `${record.price},-`}
        />
        <ReferenceField source="productId" reference="Product">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="categoryId" reference="Category">
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteWithConfirmButton mutationMode='pessimistic' />
      </Datagrid>
    </List>
  );
};
