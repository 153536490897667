import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { pick, sortBy } from 'lodash';

import CustomPagination from '../CustomPagination';
import { Grid } from '@mui/material';
import type { Ingredience as IngredienceType } from '../@generated/schemas';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';

const redirect = () => 'Ingredience';

const getTransformedData = (data) => {
  data['@@ra-many/Ingredience/ProductIngredience/productId_ids'] =
    data[
      '@@ra-many/Ingredience/ProductIngredience/productId_ids'
    ]?.map((ingredienceId_ids) => {
      return {
        ProductIngredience: ingredienceId_ids.ProductIngredience.map(
          (productIngredience) =>
            pick(productIngredience, [
              'amount',
              'id',
              'ingredience_id',
              'product_id',
            ]),
        ),
      };
    }) || [];

  data['@@ra-many/Ingredience/PriceHistoryIngredience/ingredienceId_ids'] =
    data[
      '@@ra-many/Ingredience/PriceHistoryIngredience/ingredienceId_ids'
    ]?.map((ingredienceId_ids) => {
      return {
        PriceHistoryIngredience: ingredienceId_ids.PriceHistoryIngredience.map(
          (priceHistoryIngredience) =>
            pick(priceHistoryIngredience, [
              'price',
              'id',
              'createdAt',
              'ingredience_id',
            ]),
        ),
      };
    }) || [];


  return data;
};

const IngredienceForm = () => {
  const record = useRecordContext<IngredienceType>();

  return (
    <Grid container spacing={12}>
      <Grid item md={4}>
        <TextInput source="name" fullWidth />
        <br />
        <br />
        {record?.id && (
          <ReferenceManyInput
            label="Products"
            reference="ProductIngredience"
            target="ingredienceId_ids">
            <SimpleFormIterator inline fullWidth disableClear>
              <ReferenceInput reference="Product" source="product_id">
                <AutocompleteInput
                  label="Products"
                  optionText="name"
                  disableClearable
                  fullWidth
                />
              </ReferenceInput>
              <TextInput
                source="ingredience_id"
                defaultValue={record?.id}
                style={{ display: 'none' }}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        )}
      </Grid>
      <Grid item md={8}>
        {record?.id && (
          <ReferenceManyInput reference="PriceHistoryIngredience" target="ingredienceId_ids" label="Price history">
            <SimpleFormIterator disableReordering inline>
              <NumberInput source="price" label="Price" helperText={false} />
              <DateTimeInput
                source="createdAt"
                label="Created at"
                helperText={false}
                defaultValue={dayjs().toDate()}
              />
              <TextInput
                source="ingredience_id"
                defaultValue={record?.id}
                style={{ display: 'none' }}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        )}
      </Grid>
    </Grid>
  );
};

export const IngredienceCreate = (props: any) => {
  return (
    <Create
      {...props}
      mutationMode="optimistic"
      transform={getTransformedData}>
      <SimpleForm>
        <IngredienceForm />
      </SimpleForm>
    </Create>
  );
};

export const IngredienceEdit = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode="optimistic"
      transform={getTransformedData}
      redirect={redirect}>
      <SimpleForm>
        <IngredienceForm />
      </SimpleForm>
    </Edit>
  );
};

export const IngredienceFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="name" />
    <NumberInput source="price" />
    <ReferenceInput reference="Product" source="product_id">
      <SelectInput />
    </ReferenceInput>
  </Filter>
);

export const IngredienceList = (props: any) => {
  return (
    <List
      {...props}
      filters={<IngredienceFilter />}
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <ArrayField label="Product" source="productIngrediences">
          <SingleFieldList linkType={false}>
            <ChipField source="product.name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Last price"
          render={(row) =>
            sortBy(row?.priceHistoryIngrediences, 'createdAt')?.reverse()?.[0]
              ?.price
          }
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment IngredienceFragment on Ingredience {
    productIngrediences {
      id
      product {
        id
        name
      }
      ingredience {
        id
        name
      }
    }
    priceHistoryIngrediences {
      id
      price
      createdAt
    }
  }
`;

export const Ingredience: ResourceView = {
  resource: 'Ingredience',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
